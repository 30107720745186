import type {
    ApplicationAbstract,
    ApplicationVersionBranch,
    ApplicationVersionStatus,
    ApplicationWebsiteSetting,
    AppUser,
    BaseApplicationAbstract,
    DataSourceAbstract,
    IconicProtocol,
    RoleProtocols
} from '@lighthouse/core'
import type {
    AppDepartment,
    AppDepartmentWidthChild,
    AppImportUser,
    AppRole,
    DsGraphRawData,
    FindUseADTObject,
    FindUseLocation,
    GenerateRelationshipParams
} from '@lighthouse/shared'

import type { HttpResp } from '@/http'
import http from '@/http'

import { formContentType, JsonContentType } from './constant'
import type { AppsByEnvResult, CreateApplicationPayload, DataSourceRes, PageSubmissionStateEnum, UsersAndRolesResult } from './types'
import {
    type AppUserPayload,
    type ListFormBlock,
    type PageSubmissionItem
} from './types'

/**
 *
 * 创建应用
 * @param spaceId
 *  @param  {0:空白应用 1:byecode数据源 2:导入飞书 3: 导入excel 4: 默认实例模板 } createMode
 * @param {IconicProtocol} params
 * @return {*}
 */
export async function createApp(params: CreateApplicationPayload): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/app/createApp', { ...params }, { headers: JsonContentType })
    return res.content
}

/**
 *
 * 验证域名
 * @param spaceId
 * @param {string} domain
 * @return {*}
 */
export async function checkDomain(domain: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('api/v1/app/checkDomain', { params: { domain } })
    return res.content
}

/**
 *
 * 通过 AI 创建应用
 */
export async function createAppByAIPrompt(params: { prompt: string; icon: string; spaceId: string }): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/app/createByAI', { ...params }, { headers: JsonContentType })
    return res.content
}

/**
 *
 * 复制应用
 * @return {*}
 */
export async function copyApp(params: { targetSpaceId?: string, appId: string }): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/app/copyApp', { ...params })
    return res.content
}

/**
 *
 * 删除应用
 * @return {*}
 */
export async function deleteApp(appId: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<string>>('api/v1/app/deleteApp', {
        params: { appId }
    })
    return res.success
}

// /**
//  *
//  * 修改应用
//  * @return {*}
//  */
// export async function updateApp(appId: string, params: Partial<IconicProtocol>): Promise<boolean> {
//     const res = await http.post<unknown, HttpResp<string>>(
//         'api/v1/app/updateApp',
//         { appId, ...params },
//         {
//             headers: JsonContentType
//         }
//     )
//     return res.success
// }

/**
 * 获取应用数据
 * @param id
 * @returns
 */
export async function getApp(): Promise<ApplicationAbstract> {
    const resp = await http.get<
        unknown,
        HttpResp<
            Omit<ApplicationAbstract, 'version'> & {
                version: ApplicationVersionBranch & {
                    config: Pick<ApplicationWebsiteSetting, 'authentication' | 'navbar' | 'language' | 'theme'>
                }
            }
        >
    >('api/v1/app/getApp')

    const { version, config, ...rest } = resp.content
    const { config: versionConfig, ...resetVersion } = version

    return {
        ...rest,
        config: { ...config, ...versionConfig },
        version: resetVersion
    } as ApplicationAbstract
}

/**
 * 获取应用的用户列表
 * @return {*}  {Promise<AppUser[]>}
 */

export async function getAppUsers(envId: string, params: AppUserPayload): Promise<AppUser[]> {
    const res = await http.get<unknown, HttpResp<AppUser[]>>('api/v1/app/getUsers', { params: { envId, ...params } })
    return res.content
}

/**
 * 获取应用的角色
 * @return {*}  {Promise<AppRole[]>}
 */
export async function getAppRoles(envId: string): Promise<AppRole[]> {
    const res = await http.get<unknown, HttpResp<AppRole[]>>('api/v1/app/getRoles', { params: { envId } })
    return res.content
}

/**
 * 添加多个成员到应用
 * @return {*}  {Promise<boolean>}
 */
export async function addAppUsers(
    envId: string,
    users: Pick<AppUser, 'username' | 'mobile'>[]
): Promise<{ addCompleted: boolean; phones?: string[] }> {
    const res = await http.put<unknown, HttpResp<{ addCompleted: boolean; phones?: string[] }>>(
        'api/v1/app/addUsers',
        { envId, users },
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 空间中的角色批量添加成员
 * @return {*}  {Promise<boolean>}
 */
export async function addUsersFromGroup(appId: string, payload: { groupId: string; userIds: string[] }): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<{ groupId: string; userIds: string[] }>>('api/v1/app/addUsersToGroup', payload, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 空间中创建角色
 * @return {*}  {Promise<boolean>}
 */
export async function createAppRole(envId: string, name: string): Promise<boolean> {
    const res = await http.put<unknown, HttpResp>('api/v1/app/createRole', {
        envId,
        name
    })
    return res.success
}

// /**
//  * 获取应用用户信息详情
//  * @return {*}  {Promise<boolean>}
//  */
// export async function getAppUserInfo(userId: string): Promise<AppUser> {
//     const res = await http.get<unknown, HttpResp<AppUser>>('api/v1/app/getUser', {
//         params: {
//             userId
//         }
//     })
//     return res.content
// }

/**
 * 删除应用用户列表的用户
 * @return {*}  {Promise<boolean>}
 */
export async function deleteAppUser(envId: string, userIds: string[]): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<AppUser[]>>(`api/v1/app/deleteUser`, {
        data: { envId, ids: userIds },
        headers: JsonContentType
    })
    return res.success
}

/**
 * 注销应用用户列表的用户
 * @return {*}  {Promise<boolean>}
 */
export async function logOutAppUser(envId: string, ids: string[]): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<AppUser[]>>(`api/v1/app/bannedUser`, {
        data: { envId, ids },
        headers: JsonContentType
    })
    return res.success
}

/**
 * 恢复应用用户列表的注销用户
 * @return {*}  {Promise<boolean>}
 */
export async function restoreAppUser(envId: string, ids: string[]): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<{ groupId: string; userIds: string[] }>>(
        'api/v1/app/restoreUser',
        {
            envId,
            ids
        },
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 应用中删除角色
 * @return {*}  {Promise<boolean>}
 */
export async function deleteAppRole(envId: string, roleIds: string[]): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<AppUser[]>>('api/v1/app/deleteRole', {
        data: { envId, ids: roleIds },
        headers: JsonContentType
    })
    return res.success
}

/**
 * 删除应用中的角色成员
 * @return {*}  {Promise<boolean>}
 */
export async function deleteUserFromGroup(userId: string, groupId: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<AppUser[]>>('api/v1/app/deleteUserFromGroup', {
        params: {
            groupId,
            userId
        }
    })
    return res.success
}

/**
 * 更新应用用户列表的用户信息
 * @return {*}  {Promise<boolean>}
 */
export async function updateAppUser(
    envId: string,
    payload: Pick<AppUser, 'username' | 'roleId' | 'userId' | 'avatar' | 'email' | 'departmentIds'>
): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<Pick<AppUser, 'username' | 'roleId' | 'userId' | 'avatar' | 'email'>>>(
        'api/v1/app/updateUser',
        { envId, ...payload },
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 更新用户组
 * @return {*}  {Promise<boolean>}
 */
export async function updateAppRole(envId: string, payload: Pick<RoleProtocols, 'id' | 'name'>): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<Pick<RoleProtocols, 'name' | 'id'>>>('api/v1/app/updateRole', {
        envId,
        ...payload
    })
    return res.success
}

/**
 * 应用中导入用户
 * @param dsId
 * @returns
 */
export function importAppUser(file: File, envId: string) {
    const fileFormData = new FormData()
    fileFormData.append('file', file)
    fileFormData.append('envId', envId)
    return http.post<unknown, HttpResp<AppImportUser>>('api/v1/app/importUser', fileFormData, {
        headers: formContentType
    })
}

// type UpdateAppPayload = {
//     appId: string
//     versionName?: string
//     remark?: string
//     config?: Partial<ApplicationSetting>
//     version?: Partial<Omit<ApplicationVersionBranch, 'config'>>
// }
// /**
//  * 保存用户自定义设置
//  * @param {UpdateAppPayload}
//  * @return {*}  {Promise<boolean>}
//  */
// export async function setAppConfig(payLoad: UpdateAppPayload): Promise<boolean> {
//     const res = await http.post<unknown, HttpResp>('api/v1/app/updateApp', payLoad, {
//         headers: JsonContentType
//     })
//     return res.success
// }

type UpdateAppPayload = {
    id: string
    config?: Partial<Pick<ApplicationWebsiteSetting, 'advertising' | 'domain' | 'seo' | 'sms' | 'webApp' | 'webTag' | 'privateTemplate'>>
} & Partial<BaseApplicationAbstract>
/** 更新应用的公共配置 */
export async function updateAppV2(payload: UpdateAppPayload) {
    const res = await http.post<unknown, HttpResp>('api/v1/app/updateApp', payload, { headers: JsonContentType })
    return res.success
}
type UpdateAppVersionConfigPayload = Partial<ApplicationVersionBranch> & {
    id: string
    config?: Partial<Pick<ApplicationWebsiteSetting, 'authentication' | 'language' | 'navbar' | 'theme'>>
}
/** 更新应用版本配置 */
export async function updateAppVersion(payload: UpdateAppVersionConfigPayload) {
    const res = await http.post<unknown, HttpResp>('api/v1/app/updateVersion', payload, { headers: JsonContentType })

    return res.success
}
/** 切换编辑版本预览开关 */
export async function switchAppVersionPreview(payload: boolean) {
    const res = await http.post<unknown, HttpResp>('api/v1/app/switchPreview', { preview: payload })
    return res.success
}

// /*
//  * 获取应用发布信息
//  * @param {string}
//  * @return {*}  {Promise<boolean>}
//  */
// export async function getAppPublishInfo() {
//     const res = await http.get<unknown, HttpResp<ApplicationPublishInfo>>('api/v1/app/getStatus')
//     return res.content
// }

/**
 * 发布应用
 * @param {string}
 * @deprecated
 * @return {*}  {Promise<boolean>}
 */
export async function appPublish() {
    const res = await http.post<unknown, HttpResp<string>>('api/v1/app/publish')
    return res.content
}

/**
 * 下线应用
 * @deprecated 已废弃
 * @return {*}  {Promise<boolean>}
 */
export async function appOffline() {
    const res = await http.post<unknown, HttpResp>('api/v1/app/offline')
    return res.success
}
/**
 * 上下线应用
 * @return {*}  {Promise<boolean>}
 */
export async function updateAppStatus(status: Extract<ApplicationVersionStatus, 'ONLINE' | 'OFFLINE'>) {
    const res = await http.post<unknown, HttpResp>('api/v1/app/updateStatus', { status })
    return res.success
}

/**
 * 修改发布应用域名前缀
 * @return {*}  {Promise<boolean>}
 * @deprecated 已废弃
 */
export async function updateDomain(domain: string) {
    const res = await http.post<unknown, HttpResp>('api/v1/app/updateDomain', {
        domain
    })
    return res.success
}

/**
 * 通过审核
 * @return {*}  {Promise<boolean>}
 */
export async function appAuditUser(envId: string, userId: string): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>('api/v1/app/auditUser', {
        envId,
        userId
    })
    return res.success
}
/**
 * @description 绑定域名
 * @param {string} domain
 * @return {*}  {Promise<boolean>}
 * @deprecated 已废弃
 */
export async function bindCustomDomain(domain: string): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>('api/v1/app/bindCustomDomain', {
        domain
    })
    return res.success
}

/**
 * @description 获取自定义域名的绑定状态
 * @param {string} domain
 * @return {*}  {Promise<boolean>}
 */
export async function getDomainBindingStatus(params: { domain: string; abort?: AbortController }): Promise<boolean | undefined> {
    const { abort, domain } = params
    const res = await http.get<unknown, HttpResp<boolean> | undefined>('api/v1/app/getDomainBindingStatus', {
        params: {
            domain
        },
        signal: abort?.signal
    })
    return res?.content
}
/**
 * @description 解除自定义域名绑定
 * @param {string} domain
 * @return {*}  {Promise<boolean>}
 * @deprecated 已废弃
 */
export async function releaseCustomDomain(domain: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp>('api/v1/app/releaseCustomDomain', {
        params: {
            domain
        }
    })
    return res.success
}

/**
 * @description 获取当前应用下所有视图
 * @param {string} domain
 * @return {*}  {Promise<boolean>}
 */
export async function getAllViews(): Promise<{ title: string; id: string; dsId: string }[]> {
    const res = await http.get<unknown, HttpResp<{ title: string; id: string; dsId: string }[]>>('api/v1/app/getAppViews')
    return res.content
}

/**
 * @description 创建模板应用
 * @param {string} templateId
 * @return {*}  {Promise<string | undefined>}
 */
export async function createAppTemplate(params: { templateId: string; spaceId: string }): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/app/createAppByTemplate', params)
    return res.content
}

// ##################################################################### //
// ########################### 数据源关系图接口 ######################### //
// ##################################################################### //

/**
 * 数据源关系图
 */
export async function getDataSourceGraph(): Promise<DsGraphRawData> {
    const res = await http.get<unknown, HttpResp<DsGraphRawData>>('api/v1/app/getDsGraph')
    return res.content
}
/**
 * 创建数据源关系图中关联关系
 */
export async function createRelationship(params: GenerateRelationshipParams): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/app/createRelationship', params, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 更新数据源关系图中关联关系
 */
export async function updateRelationship(params: GenerateRelationshipParams): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/app/updateRelationship', params, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 删除数据源关系图中关联关系
 */
export async function deleteRelationship(id: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/app/deleteRelationship', {
        params: {
            id
        }
    })
    return res.success
}

// ##################################################################### //
// ############################### AI 模型 ############################# //
// ##################################################################### //

// 业务建模
export async function modeling(prompt: string): Promise<DsGraphRawData> {
    const res = await http.get<unknown, HttpResp<DsGraphRawData>>('api/v1/app/modeling', { params: { prompt } })
    return res.content
}

// 应用模型生成数据
export async function applyModeling(): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('api/v1/app/applyModeling')
    return res.success
}

/**
 * 获取应用下数据源列表
 * @return {*}
 */
export async function listDs(appId: string, envId: string): Promise<DataSourceAbstract[]> {
    const res = await http.get<unknown, HttpResp<DataSourceRes[]>>('/api/v1/app/listDs', { params: { appId, envId } })
    return res.content.map(item => ({
        appId,
        envId,
        ...item
    }))
}

/**
 * 获取应用下所有表单
 * @return {*}
 */
export async function listFormModules(appId: string, dsId: string): Promise<ListFormBlock[]> {
    const res = await http.get<unknown, HttpResp<ListFormBlock[]>>('/api/v1/app/listFormModules', { params: { appId, dsId } })
    return res.content
}

/**
 * 获取应用用户和角色
 * @return {*}
 */
// todo: 返回改为ids 进入应用可拿到所有的用户和角色
export async function getUsersAndRoles(): Promise<UsersAndRolesResult> {
    const res = await http.get<unknown, HttpResp<UsersAndRolesResult>>('/api/v1/app/getUsersAndRoles')
    return res.content
}

/**
 * 获取部门
 * @return {*}  {Promise<AppDepartmentWidthChild[]>}
 */
export async function getDepartments(envId: string): Promise<AppDepartmentWidthChild[]> {
    const res = await http.get<unknown, HttpResp<AppDepartmentWidthChild[]>>('api/v1/app/getDepartments', { params: { envId } })
    return res.content
}

// /**
//  * 空间中的角色批量添加成员
//  * @return {*}  {Promise<boolean>}
//  */
// export async function addUsersFromGroup(appId: string, payload: { groupId: string; userIds: string[] }): Promise<boolean> {
//     const res = await http.post<unknown, HttpResp<{ groupId: string; userIds: string[] }>>('api/v1/app/addUsersToGroup', payload, {
//         headers: JsonContentType
//     })
//     return res.success
// }

/**
 * 应用中创建部门
 * @return {*}  {Promise<boolean>}
 */
export async function createDepartment(envId: string, params: Omit<AppDepartment, 'id'> & { parentId?: string }): Promise<boolean> {
    const { parentId, name, managerUserId } = params
    const res = await http.put<unknown, HttpResp>('api/v1/app/createDepartment', {
        envId,
        parentId,
        name,
        managerUserId,
    })
    return res.success
}

/**
 * 应用中删除部门
 * @return {*}  {Promise<boolean>}
 */
export async function deleteUserDepartment(envId: string, departmentIds: string[]): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp>('api/v1/app/deleteDepartment', {
        data: { envId, departmentIds },
        headers: JsonContentType
    })
    return res.success
}

/**
 * 更新应用中的部门
 * @return {*}  {Promise<boolean>}
 */
export async function updateDepartment(envId: string, payload: Partial<AppDepartment> & { parentId?: string }): Promise<boolean> {
    const { id, name, managerUserId, parentId } = payload
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/app/updateDepartment', {
        envId,
        id,
        name,
        managerUserId,
        parentId
    })

    return res.success
}

/**
 *
 * @description 发送邮件分享应用
 */
export async function sendEmailSharing(params: { mails: string[]; buttonUrl: string }): Promise<boolean> {
    const res = await http.put<unknown, HttpResp>('api/v1/app/emailSharing', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 获取当前应用提交的所有队列
 */
export async function getSubmissionQueue(params: {
    state: PageSubmissionStateEnum | null
    pageSize: number
    currentPage: number
}): Promise<PageSubmissionItem[]> {
    const res = await http.get<unknown, HttpResp<PageSubmissionItem[]>>('api/v1/app/getSubmissionQueue', { params })
    return res.content
}
/**
 * 获取当前应用提交的所有队列各个状态的数量
 */
export async function getSubmissionQueueCount(): Promise<{ value: PageSubmissionStateEnum; count: number }[]> {
    const res = await http.get<unknown, HttpResp<{ value: PageSubmissionStateEnum; count: number }[]>>('api/v1/app/getSubmissionQueueCount')
    return res.content
}
/**
 * 获取阿里云视频播放凭证
 */
export async function getVideoPlayAuth(videoId: string) {
    const res = await http.get<unknown, HttpResp<{ playAuth: string }>>('api/v1/oss/getVideoPlayAuth', {
        params: {
            videoId
        }
    })
    return res.content?.playAuth
}

/**
 * 查找引用
 * @return {*}  {Promise<boolean>}
 */
export async function getFindUses(payload: FindUseADTObject): Promise<FindUseLocation> {
    const res = await http.post<unknown, HttpResp<FindUseLocation>>('api/v1/app/findUses', { ...payload }, { headers: JsonContentType })
    return res.content
}

/**
 * 获取应用版本列表
 */
export async function getAppVersionList() {
    const res = await http.get<unknown, HttpResp<ApplicationVersionBranch[]>>('api/v1/app/versions')
    return res.content
}
/**
 * 创建应用版本分支
 */
export async function createAppVersion(payload: Pick<ApplicationVersionBranch, 'name' | 'remark'>) {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/app/createVersion', payload)
    return res.content
}
/**
 * 切换应用版本分支
 */
export async function switchAppVersion(versionId: string) {
    const res = await http.post<unknown, HttpResp<string>>('api/v1/app/switchVersion', { versionId })
    return res.success
}
/**
 * 合并应用版本分支
 */
export async function mergeAppVersion() {
    const res = await http.post<unknown, HttpResp<number>>('api/v1/app/merge')
    return res.content
}
/**
 * 删除应用版本分支
 */
export async function deleteAppVersion(versionId: string) {
    const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/app/deleteVersion', { params: { versionId } })
    return res.success
}
/**
 * 获取版本是否有更新
 */
export async function checkVersionUpdated() {
    const res = await http.get<unknown, HttpResp<boolean>>('api/v1/app/hasUpdates')
    return res.content
}

/** 获取使用指定环境的应用
 * @return {*}
 */
export async function getAppsByEnv(envId: string): Promise<AppsByEnvResult> {
    const res = await http.get<unknown, HttpResp<AppsByEnvResult>>('api/v1/app/getAppsByEnv', {
        params: { envId }
    })
    return res.content
}

// /**
//  * 检查应用版本合并时环境冲突  待后端给接口
//  */
// export async function checkAppVersionEnv() {
//     const res = await http.post<unknown, HttpResp>('')
//     return res.content
// }

/** 获取验证码
 * @return {*}
 */
export async function getVerifyCode(params: { mobile: string; blockId: string; pageId: string }): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('api/auth/v1/sms/getFormVerifyCode', {
        params
    })
    return res.success
}
