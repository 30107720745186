import type { Option } from '@byecode/ui'
import { Anchor, Divider, Empty, Select } from '@byecode/ui'
import {
    type FlowNode,
    BasicListItem,
    getDefaultValueOptions,
    getDefaultValueOptionsByInnerType,
    getUserDatasourceOptions,
    InnerTypeMapByFieldType,
    NodeFieldPreview,
    pureTextFieldTypes,
    USER_DATASOURCE,
    VariableSelect,
    VariableSourceType
} from '@lighthouse/shared'
import { equals, find, isEmpty, uniq } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'
import useSWR from 'swr'

import { AddAliyunAccount, AliyunCodeAccountModal } from '@/components/OtherPlatFormModal'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import * as srv from '@/services'
import { useGetAliyunMessageInfo, useOtherPlatformList } from '@/shared/reusable'

import { GroupRequired } from '../../Common/GroupRequired'
import * as SC from './styles'

interface AliyunMessageActionConfigureProps {
    allParentNodes?: FlowNode[]
    prefixName?: string
    actionTypeSwitcher?: React.ReactNode
}

interface State {
    mode: 'add' | 'update'
    opened: boolean
    id?: string
}

export const AliyunMessageActionConfigure: React.FunctionComponent<AliyunMessageActionConfigureProps> = ({
    actionTypeSwitcher,
    prefixName = 'config',
    allParentNodes
}) => {
    const { control, watch, getValues } = useFormContext()

    const { fields, replace } = useFieldArray({ control, name: `${prefixName}.values` })

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const disabledWithVersion = useIsDisabledWithVersion()
    const { type } = useFlow()
    const { data: otherPlatform, update } = useOtherPlatformList()
    const configId = watch(`${prefixName}.id`)

    const [aliyunAccountId, aliyunTemplateId] = watch([`${prefixName}.id`, `${prefixName}.templateId`])
    const { data } = useGetAliyunMessageInfo(aliyunAccountId)

    const aliyunMessageSignaturesOptions = useMemo(() => {
        return data?.signatures?.map(item => ({
            value: item.name,
            label: item.name
        }))
    }, [data?.signatures])

    const aliyunMessageTemplatesOptions = useMemo(() => {
        return (
            data?.templates.map(item => ({
                value: item.id,
                label: item.name,
                content: item.content
            })) ?? []
        )
    }, [data?.templates])

    const currentAliyunMessageTemplateOption = useMemo(() => {
        return find(option => option.value === aliyunTemplateId, aliyunMessageTemplatesOptions)
    }, [aliyunMessageTemplatesOptions, aliyunTemplateId])

    const currentAliyunMessageTemplate = useMemo(() => {
        return currentAliyunMessageTemplateOption?.content ?? ''
    }, [currentAliyunMessageTemplateOption?.content])

    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })
    const dataSourceList = useDataSourceList(appId, envId)
    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => pureTextFieldTypes.has(field.type)
        })
    }, [dataSourceList])

    const phoneOptions = useMemo(
        () =>
            getDefaultValueOptionsByInnerType({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: allParentNodes || []
                    }
                ],
                validateInnerType: innerType => innerType === 'TEXT'
            }),
        [allParentNodes, dataSourceList]
    )

    const options = useMemo(
        () =>
            getDefaultValueOptions({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: allParentNodes || []
                    }
                ],
                validateFieldType: type => pureTextFieldTypes.has(type)
            }),
        [allParentNodes, dataSourceList]
    )

    const handleUpdate = useCallback(
        (value: string) => {
            setState({ opened: true, mode: 'update', id: value })
        },
        [setState]
    )

    const handleAdd = useCallback(() => {
        setState({ opened: true, mode: 'add', id: undefined })
    }, [setState])

    const handleTemplateChange = useCallback(
        (value: string, onChange: (value: string) => void) => {
            const currentOption = find(option => option.value === value, aliyunMessageTemplatesOptions)?.content ?? ''
            const variablesMatches = currentOption.match(/\${(\w+)}/g) ?? []
            const variables = variablesMatches.map(item => item.replace(/\${(\w+)}/g, '$1'))

            const uniqVariables = uniq(variables)

            const formValues = getValues()
            const values = (formValues[prefixName]?.values ?? []) as { variableName: string; value: string }[]
            const valuesVariableNames = values.map(v => v.variableName)

            onChange(value)

            if (equals(valuesVariableNames, uniqVariables)) {
                return
            }
            const newValues = uniqVariables.map(field => {
                const value = find(v => v.variableName === field, values)?.value ?? {}
                return { variableName: field, value }
            })

            replace(newValues)
        },
        [aliyunMessageTemplatesOptions, getValues, prefixName, replace]
    )

    const aliyunOptions: Option[] = useMemo(
        () =>
            (otherPlatform?.filter(item => item.type === 'ALIYUN_SMS') ?? []).map((item, index) => ({
                label: item.name,
                value: item.id,
                icon: 'aliyun',
                extra: (
                    <SC.ItemRightFill
                        onMouseDown={ev => {
                            ev.stopPropagation()
                            handleUpdate(item.id)
                        }}
                        className="itemEdit"
                    >
                        <SC.Icon color="var(--color-gray-400)" type="PencilSimple" />
                    </SC.ItemRightFill>
                )
            })),
        [handleUpdate, otherPlatform]
    )

    return (
        <>
            <GroupRequired
                label="动作配置"
                required
                styles={{
                    root: {
                        padding: 0
                    }
                }}
            >
                {actionTypeSwitcher && (
                    <BasicListItem disablePadding style={{ justifyContent: 'space-between', padding: 0 }}>
                        <SC.Text>类型</SC.Text>
                        {actionTypeSwitcher}
                    </BasicListItem>
                )}
                <BasicListItem style={{ justifyContent: 'space-between', padding: 0 }}>
                    <SC.Text>选择账号</SC.Text>
                    <Controller
                        control={control}
                        name={`${prefixName}.id`}
                        render={({ field }) => (
                            <SC.SelectContainer>
                                <Select
                                    hiddenEmpty
                                    placeholder="请选择"
                                    className="selectInput"
                                    styles={{
                                        item: {
                                            '&:hover .itemEdit': {
                                                display: 'flex'
                                            }
                                        },
                                        icon: {
                                            color: 'var(--color-orange-500)'
                                        },
                                        root: {
                                            color: 'var(--color-orange-500)'
                                        }
                                    }}
                                    options={aliyunOptions}
                                    {...field}
                                    dropdownProps={{
                                        extra: (
                                            <>
                                                {aliyunOptions.length === 0 && (
                                                    <Empty
                                                        icon="aliyun"
                                                        styles={{
                                                            root: {
                                                                minHeight: '75px'
                                                            }
                                                        }}
                                                        description="您还没添加阿里云账号"
                                                    />
                                                )}
                                                <Divider color="var(--color-gray-200)" />
                                                <AddAliyunAccount onClick={() => handleAdd()} />
                                            </>
                                        )
                                    }}
                                />
                            </SC.SelectContainer>
                        )}
                    />
                </BasicListItem>
            </GroupRequired>
            {configId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <GroupRequired
                        required
                        label="手机号列表"
                        styles={{
                            root: {
                                padding: 0
                            }
                        }}
                    >
                        <Controller
                            control={control}
                            name={`${prefixName}.mobileList`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <VariableSelect
                                        disabled={disabledWithVersion}
                                        width="100%"
                                        style={{ marginTop: 8 }}
                                        field={{
                                            id: '',
                                            dsId: '',
                                            type: 'phoneNumber',
                                            name: '',
                                            innerType: InnerTypeMapByFieldType['phoneNumber']
                                        }}
                                        options={phoneOptions}
                                        userOption={userOption}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                        <SC.Tip>可以输入多个手机号，给多个手机号发送短信，手机号之间用中/英文逗号隔开，或选择手机号字段。</SC.Tip>
                    </GroupRequired>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <GroupRequired
                        required
                        label="短信签名"
                        styles={{
                            root: {
                                padding: 0
                            }
                        }}
                    >
                        <Controller
                            control={control}
                            name={`${prefixName}.signatureId`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Select
                                        style={{ marginTop: 8 }}
                                        placeholder="请选择"
                                        value={value}
                                        onChange={onChange}
                                        options={aliyunMessageSignaturesOptions}
                                    />
                                )
                            }}
                        />
                        <SC.Tip>
                            需先在阿里云添加短信签名
                            <Anchor to="https://help.aliyun.com/zh/sms/user-guide/create-signatures" target="_blank">
                                详见 &gt;
                            </Anchor>
                        </SC.Tip>
                    </GroupRequired>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <GroupRequired
                        required
                        label="短信模板"
                        styles={{
                            root: {
                                padding: 0
                            }
                        }}
                    >
                        <Controller
                            control={control}
                            name={`${prefixName}.templateId`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <Select
                                        style={{ marginTop: 8 }}
                                        placeholder="请选择"
                                        value={value}
                                        onChange={value => handleTemplateChange(value, onChange)}
                                        options={aliyunMessageTemplatesOptions}
                                    />
                                )
                            }}
                        />
                        <SC.Tip>
                            需先在阿里云添加短信模板
                            <Anchor
                                to="https://help.aliyun.com/zh/sms/user-guide/create-message-templates-1?spm=a2c4g.11186623.0.i5"
                                target="_blank"
                            >
                                详见 &gt;
                            </Anchor>
                        </SC.Tip>
                        <SC.MessageTemplatePreviewer>
                            <SC.MessageTemplatePreviewerHeader>短信模板内容预览：</SC.MessageTemplatePreviewerHeader>
                            <SC.MessageTemplatePreviewerContent>{currentAliyunMessageTemplate}</SC.MessageTemplatePreviewerContent>
                        </SC.MessageTemplatePreviewer>
                    </GroupRequired>
                    {!!fields.length && (
                        <>
                            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                            <GroupRequired
                                required
                                label="短信模板变量对应的实际值"
                                styles={{
                                    root: {
                                        padding: 0,
                                        marginBottom: 20
                                    }
                                }}
                            >
                                {fields.map((field, index) => (
                                    <SC.VariableItem style={{ marginTop: 8 }} key={index}>
                                        {/* @ts-expect-error field variableName */}
                                        <SC.VariableTitle>{`\${${field.variableName}}`}</SC.VariableTitle>
                                        <Controller
                                            control={control}
                                            name={`${prefixName}.values.${index}.value`}
                                            render={({ field: { value, onChange } }) => {
                                                const richTextValue = isEmpty(value) ? '' : value
                                                return (
                                                    <NodeFieldPreview
                                                        placeholder="请输入"
                                                        value={richTextValue}
                                                        onChange={onChange}
                                                        autoHeight
                                                        userOption={userOption}
                                                        options={options}
                                                    />
                                                )
                                            }}
                                        />
                                    </SC.VariableItem>
                                ))}
                            </GroupRequired>
                        </>
                    )}
                </>
            )}

            <AliyunCodeAccountModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
        </>
    )
}
