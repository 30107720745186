import { Divider, Group, hideScrollBar, IconFont, Toast } from '@byecode/ui'
import type { PageAbstract } from '@lighthouse/core'
import { PageOpenType, PageStruct, PageType } from '@lighthouse/core'
import { getMainTableRecordId, useAtomAction, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { filter, map, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLatest, useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { AppSystemPageAtom, languageAtom } from '@/atoms/application/state'
import {
    clonePageAtom,
    createPageAtom,
    deleteRootPageStackAtom,
    movePageAtom,
    openPageStackAtom,
    removePageAtom,
    updateHomePageAtom,
    updatePageAtom
} from '@/atoms/page/action'
import { defaultPageListAtom, lastPageOfStackAtom } from '@/atoms/page/state'
import { type CreatePagePayload } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { createModeToPageTypeMap } from '@/constants/common'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageList } from '@/hooks/usePage'
import * as srv from '@/services'
import { useDataSourceRoles } from '@/shared/reusable'

import { PAGE_FILTER_OPTIONS } from '../constant'
import { CreatePage } from '../CreatePage'
import { getPageListGroup } from '../helper'
import type { FilterParams } from '../PageListHeader'
import PageListHeader from '../PageListHeader'
import type { PageKindType } from '../types'
import { CustomRouteModal } from './CustomRouteModal'
import { PageEmpty } from './Empty'
import { PageTreeList } from './List'
import { DragList } from './List/DragList'
import { SystemPage } from './SystemPage'

const PageListContainer = styled.div`
    width: 100%;
    flex: 1;
    padding: 0px 12px 180px 12px;
    max-height: 100%;
    position: relative;
    overflow: hidden auto;
    ${hideScrollBar}
`

const PageListWrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxKindTitle = styled.div`
    color: var(--color-black);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 166.667% */
`

const SCxKindHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
`
interface PageListProps {
    onBack?: () => void
}

const LOGIN_PAGE: PageAbstract = { ...PageStruct(), id: 'LOGIN', name: '登陆页面' }

export const PageList: React.FC<PageListProps> = ({ onBack }) => {
    const [filterParams, setFilterParams] = useImmer<FilterParams>({
        searchWord: '',
        groupId: 'all'
    })
    const [focusPage, setFocusPage] = useState('')
    const [openMap, setOpenMap] = useImmer<Record<PageKindType, boolean>>({ other: true, default: true, system: true })
    const pageListContainerRef = useRef<HTMLDivElement>(null)

    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: deleteRootPageStack } = useAtomAction(deleteRootPageStackAtom)
    const { run: updatePage } = useAtomAction(updatePageAtom)
    const { run: removePage } = useAtomAction(removePageAtom)
    const { run: movePage } = useAtomAction(movePageAtom)
    const { run: updateHomePage } = useAtomAction(updateHomePageAtom)
    const { run: createPage } = useAtomAction(createPageAtom)
    const { run: clonePage } = useAtomAction(clonePageAtom)
    const { run: setAppSystemPage } = useAtomAction(AppSystemPageAtom)

    const [pageId, rootPageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId, s?.rootPageId], [])
    )
    const defaultPageList = useAtomData(defaultPageListAtom)
    const appSystemPage = useAtomData(AppSystemPageAtom)
    const allPageList = usePageList()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const language = useAtomData(languageAtom)
    const appGroups = useDataSourceRoles()
    const disabledWithVersion = useIsDisabledWithVersion()

    const roleOptions = useMemo(
        () => [
            ...PAGE_FILTER_OPTIONS,
            ...appGroups.map(({ id, name }) => ({ label: `${name}可见`, value: id, group: 'user' }))
        ],
        [appGroups]
    )

    const currentGroupPageList = useMemo(() => {
        const langPageList = allPageList.filter(page => page.language === language)
        switch (filterParams.groupId) {
            case 'all': {
                return langPageList
            }
            case 'public': {
                return filter(({ open }) => open === PageOpenType.all, langPageList)
            }
            case 'login': {
                return filter(({ open }) => open === PageOpenType.login, langPageList)
            }
            default: {
                return filter(({ roleIds = [] }) => roleIds.includes(filterParams.groupId), langPageList)
            }
        }
    }, [allPageList, filterParams.groupId, language])

    const filterPageList = useMemo(
        () => filter(({ name, type }) => name.includes(filterParams.searchWord), currentGroupPageList),
        [currentGroupPageList, filterParams.searchWord]
    )

    const kindPageMap = useMemo(
        () =>
            reduce<PageAbstract, Record<PageKindType, PageAbstract[]>>(
                (preVal, p, i) => {
                    if (p.type === 'default') {
                        preVal.default.push(p)
                    } else {
                        preVal.other.push(p)
                    }
                    return preVal
                },
                { default: [], other: [], system: [] },
                filterPageList
            ),
        [filterPageList]
    )

    const kindPageList = useMemo(() => {
        return {
            default: { id: 'default', name: '', data: kindPageMap.default },
            other: getPageListGroup(dataSourceList, kindPageMap.other)
        }
    }, [dataSourceList, kindPageMap.default, kindPageMap.other])

    const activeId = useMemo(() => appSystemPage ?? pageId, [appSystemPage, pageId])

    const handlePageCopy = useCallback(
        async (id: string, lang: string) => {
            const pageId = await clonePage({ id, lang })
            Toast.success('复制成功')
            lang === language && openPageStack(stackFactory({ appId, pageId }))
            setAppSystemPage(null)
            return pageId
        },
        [appId, clonePage, language, openPageStack, setAppSystemPage]
    )

    const handleRemovePage = useCallback(
        async (id: string) => {
            deleteRootPageStack(id)
            await removePage(id)
        },
        [deleteRootPageStack, removePage]
    )

    const fetchDataSourceFirstRecord = useCallback(
        async ({ dsId }: { dsId: string }) => {
            const res = await srv.getDs({
                appId,
                envId,
                dsId,
                pagination: { currentPage: 1, pageSize: 100 }
            })

            return res?.records?.[0]
        },
        [appId, envId]
    )

    const getPageStackExtraInfo = useCallback(
        async (pageType: PageType, dsId: string) => {
            const firstRecord = await fetchDataSourceFirstRecord({ dsId })
            const firstRecordId = getMainTableRecordId(firstRecord?.id)
            return pageType === PageType.default ? {} : { stackId: nanoid(), dsId, recordId: firstRecordId }
        },
        [fetchDataSourceFirstRecord]
    )

    const handlePageAdd = useCallback(
        async (v: CreatePagePayload) => {
            const { dsId, createMode } = v
            const pageId = await createPage(v)
            const type = createMode ? createModeToPageTypeMap[createMode] : 'default'
            const pageStackExtraInfo = dsId ? await getPageStackExtraInfo(type, dsId) : {}
            openPageStack(stackFactory({ appId, pageId, dsId: createMode ? dsId : undefined, ...pageStackExtraInfo }))
            setAppSystemPage(null)
            setFocusPage(pageId)
        },
        [appId, createPage, getPageStackExtraInfo, openPageStack, setAppSystemPage]
    )

    const handleToLink = useCallback(
        async (page: PageAbstract) => {
            const { id: pageId, dsId, appId, type } = page
            const pageStackExtraInfo = dsId ? await getPageStackExtraInfo(type, dsId) : {}
            setAppSystemPage(null)
            openPageStack(stackFactory({ pageId, appId, ...pageStackExtraInfo }))
        },
        [getPageStackExtraInfo, openPageStack, setAppSystemPage]
    )

    const filterPageListRef = useLatest(filterPageList)
    const pageIdRef = useLatest(pageId)

    useUpdateEffect(() => {
        const focusEle = document.querySelector<HTMLDivElement>(`[data-page-id='${pageIdRef.current}']`)
        if (focusEle) {
            requestAnimationFrame(() => {
                focusEle.scrollIntoView({ behavior: 'smooth', block: 'center' })
            })
        }
    }, [filterPageListRef, pageIdRef, filterPageList.length])

    useEffect(() => {
        setOpenMap(draft => {
            if (defaultPageList.some(item => item?.id === pageId)) {
                draft.default = true
            } else {
                draft.other = true
            }
        })
    }, [defaultPageList, pageId, setOpenMap])

    const handleChangeEdit = useCallback((id: string) => {
        setFocusPage(v => {
            return v === id ? '' : id
        })
    }, [])

    const [customRouteEditPage, setCustomRouteEditPage] = useState<PageAbstract | null>(null)
    const handleCustomRoute = useCallback(
        (id: string) => {
            const page = kindPageMap.default.find(item => item.id === id)
            if (!page) {
                return
            }
            setCustomRouteEditPage(page)
        },
        [kindPageMap.default]
    )
    const handleUpdatePageRoute = useCallback(
        (data: { id: string; route: string | undefined }) => {
            return updatePage(data)
        },
        [updatePage]
    )

    return (
        <>
            <PageListHeader value={filterParams} options={roleOptions} onChange={setFilterParams} onBack={onBack} />
            <PageListContainer ref={pageListContainerRef}>
                <PageListWrapper>
                    <Group
                        opened={openMap.default}
                        label={
                            <SCxKindHeader>
                                <IconFont
                                    type="ArrowDownSmall"
                                    size={16}
                                    color="var(--color-gray-500)"
                                    style={{
                                        transform: openMap.default ? undefined : 'rotate(-90deg)',
                                        transition: 'transform 0.2s'
                                    }}
                                />
                                <SCxKindTitle>普通页面</SCxKindTitle>
                            </SCxKindHeader>
                        }
                        styles={{
                            root: {
                                padding: '0!important'
                            },
                            wrapper: {
                                padding: '0!important',
                                height: '36px!important'
                            },
                            collapse: {
                                padding: '0!important'
                            }
                        }}
                        onCollapseChange={v =>
                            setOpenMap(draft => {
                                draft.default = v
                            })
                        }
                        renderRightIcon={() => (disabledWithVersion ? null : <CreatePage createType="default" onAdd={handlePageAdd} />)}
                    >
                        {kindPageList.default.data.length === 0 ? (
                            <PageEmpty type="default" />
                        ) : (
                            <DragList
                                list={kindPageList.default.data ?? []}
                                level={0}
                                focusPage={focusPage}
                                activeId={activeId}
                                style={{ paddingLeft: 36 }}
                                onSetHomePage={updateHomePage}
                                onToLink={handleToLink}
                                onUpdatePage={updatePage}
                                onCopyPage={handlePageCopy}
                                onRemovePage={handleRemovePage}
                                onMovePage={movePage}
                                onChangeEdit={handleChangeEdit}
                                onCustomRoute={handleCustomRoute}
                            />
                        )}
                    </Group>
                    <Divider />
                    <Group
                        opened={openMap.other}
                        label={
                            <SCxKindHeader>
                                <IconFont
                                    type="ArrowDownSmall"
                                    size={16}
                                    color="var(--color-gray-500)"
                                    style={{
                                        transform: openMap.other ? undefined : 'rotate(-90deg)',
                                        transition: 'transform 0.2s'
                                    }}
                                />
                                <SCxKindTitle>数据页面</SCxKindTitle>
                            </SCxKindHeader>
                        }
                        styles={{
                            root: {
                                padding: '0!important'
                            },
                            wrapper: {
                                padding: '0!important',
                                height: '36px!important'
                            },
                            collapse: {
                                padding: '0!important'
                            }
                        }}
                        renderRightIcon={() => (disabledWithVersion ? null : <CreatePage createType="other" onAdd={handlePageAdd} />)}
                        onCollapseChange={v =>
                            setOpenMap(draft => {
                                draft.other = v
                            })
                        }
                    >
                        {kindPageMap.other.length === 0 ? (
                            <PageEmpty type="data" />
                        ) : (
                            <PageTreeList
                                list={kindPageList.other}
                                level={1}
                                focusPage={focusPage}
                                activeId={activeId}
                                onSetHomePage={updateHomePage}
                                onToLink={handleToLink}
                                onUpdatePage={updatePage}
                                onCopyPage={handlePageCopy}
                                onRemovePage={handleRemovePage}
                                onMovePage={movePage}
                                onChangeEdit={handleChangeEdit}
                                onAdd={handlePageAdd}
                            />
                        )}
                    </Group>
                    <Divider />
                    <Group
                        opened={openMap.system}
                        label={
                            <SCxKindHeader>
                                <IconFont
                                    type="ArrowDownSmall"
                                    size={16}
                                    color="var(--color-gray-500)"
                                    style={{
                                        transform: openMap.system ? undefined : 'rotate(-90deg)',
                                        transition: 'transform 0.2s'
                                    }}
                                />
                                <SCxKindTitle>系统页面</SCxKindTitle>
                            </SCxKindHeader>
                        }
                        styles={{
                            root: {
                                padding: '0!important'
                            },
                            wrapper: {
                                padding: '0!important',
                                height: '36px!important'
                            },
                            collapse: {
                                padding: '0!important'
                            }
                        }}
                        renderRightIcon={() => null}
                        onCollapseChange={v =>
                            setOpenMap(draft => {
                                draft.system = v
                            })
                        }
                    >
                        <SystemPage data={LOGIN_PAGE} id={activeId} />
                    </Group>
                </PageListWrapper>
            </PageListContainer>

            {customRouteEditPage && (
                <CustomRouteModal
                    open
                    onClose={() => {
                        setCustomRouteEditPage(null)
                    }}
                    page={customRouteEditPage}
                    pages={kindPageMap.default}
                    onClear={() => handleUpdatePageRoute({ id: customRouteEditPage.id, route: '' })}
                    onConfirm={(route: string) => handleUpdatePageRoute({ id: customRouteEditPage.id, route })}
                />
            )}
        </>
    )
}
