import type { FieldType } from '@lighthouse/core'
import { CollapseBox, getFieldIcon, IGNORE_WATCH_NODE_FIELD_TYPES, useAtomAction } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { getDataSourceMetaAtom } from '@/atoms/dataSource/action'
import { useApplication, useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import * as SC from '../../styles'

interface AttentionLabelProps {
    num?: number
    max: number
    onToggleAllCheck?: () => void
}

interface CheckBoxLabelProps {
    label: string
    icon: string
}

interface fieldList {
    label: string
    value: string
    type: FieldType
    icon: string
}

interface AttentionFieldProps {
    dataSourceId: string
}

const AttentionLabel: React.FC<AttentionLabelProps> = ({ num = 0, max, onToggleAllCheck }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    return (
        <SC.FlexBetween>
            <SC.Title>已关注 {num}</SC.Title>
            <SC.Check
                checked={num > 0}
                disabled={disabledWithVersion}
                indeterminate={num > 0 && max > num}
                label="全选"
                onChange={onToggleAllCheck}
            />
        </SC.FlexBetween>
    )
}

const CheckBoxLabel: React.FC<CheckBoxLabelProps> = ({ label, icon }) => {
    return (
        <SC.FlexStart>
            <SC.Icon size={16} type={icon} />
            <SC.Title>{label}</SC.Title>
        </SC.FlexStart>
    )
}

export const AttentionField: React.FC<AttentionFieldProps> = ({ dataSourceId }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dataSourceId)
    const { run: getDataSourceMeta } = useAtomAction(getDataSourceMetaAtom)
    const disabledWithVersion = useIsDisabledWithVersion()
    const { control, setValue } = useFormContext()
    const watchedFields: string[] = useWatch({
        control,
        name: 'config.watchedFields'
    })

    useEffect(() => {
        getDataSourceMeta({ envId, dsId: dataSourceId })
        setValue('config.watchedFields', [])
    }, [dataSourceId, envId, getDataSourceMeta, setValue])

    const fieldsList = useMemo(() => {
        if (!dataSource) {
            return []
        }
        const {
            schema,
            viewOptions: { tableProps }
        } = dataSource
        const newTableProps = tableProps.reduce<fieldList[]>((list, cur) => {
            const field = schema[cur.id]
            if (field && !IGNORE_WATCH_NODE_FIELD_TYPES.has(field.type)) {
                list.push({
                    label: field.name,
                    value: field.id,
                    type: field.type,
                    icon: getFieldIcon(field.id, field.type, field.innerType)
                })
            }
            return list
        }, [])

        return Object.values(schema).reduce((prev, cur) => {
            const field = find(item => item.value === cur.id, prev)
            if (!field && !IGNORE_WATCH_NODE_FIELD_TYPES.has(cur.type)) {
                prev.push({
                    label: cur.name,
                    value: cur.id,
                    type: cur.type,
                    icon: getFieldIcon(cur.id, cur.type, cur.innerType)
                })
            }
            return prev
        }, newTableProps)
    }, [dataSource])

    const handleToggleAllCheck = useCallback(() => {
        const checked = watchedFields?.length === fieldsList.length
        setValue('config.watchedFields', checked ? [] : fieldsList.map(item => item.value))
    }, [fieldsList, setValue, watchedFields])

    return (
        <SC.Container>
            <CollapseBox label="关注以下任一字段的变更" required>
                <SC.Content>
                    <AttentionLabel num={watchedFields?.length} max={fieldsList.length} onToggleAllCheck={handleToggleAllCheck} />
                    <Controller
                        name="config.watchedFields"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <SC.CheckboxGroup {...field} spacing="xs" orientation="vertical">
                                {fieldsList.map(item => (
                                    <SC.CheckItem
                                        disabled={disabledWithVersion}
                                        key={item.value}
                                        value={item.value}
                                        label={<CheckBoxLabel label={item.label} icon={item.icon} />}
                                    />
                                ))}
                            </SC.CheckboxGroup>
                        )}
                    />
                    <SC.Description>当以上指定的其中一个字段更新时将触发流程</SC.Description>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
