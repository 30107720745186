import { type AppUser, AppUserStatus } from '@lighthouse/core'
import type { MenuItem } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

import type { UserDepartmentTitle, UserGroupAction, UserGroupTitle, UserTitle } from './type'
import type { InviteUser } from './UserInvite'

export const defaultUser: AppUser = {
    userId: '',
    uniqueUserId: '',
    username: '',
    avatar: '',
    state: AppUserStatus.ACTIVATED,
    mobile: '',
    roleId: '',
    createdTime: 0,
    wechatOpenId: '',
    // wechatId: '',
}

export const usersTitle: UserTitle[] = [
    { id: 'checkbox', name: '', xs: 0.5, textAlign: 'center', justifyContent: 'center', width: 45 },
    { id: 'avatar', name: '头像', xs: 0.5, textAlign: 'left', justifyContent: 'left', width: 60 },
    { id: 'name', name: '用户名', xs: 1, textAlign: 'left', justifyContent: 'left', width: 180 },
    // { id: 'state', name: '账号状态', xs: 2, textAlign: 'left', justifyContent: 'left' },
    { id: 'mobile', name: '手机号', xs: 1, textAlign: 'left', justifyContent: 'left', width: 180 },
    { id: 'email', name: '邮箱', xs: 2, textAlign: 'left', justifyContent: 'left', width: 180 },
    { id: 'role', name: '角色', xs: 1, textAlign: 'left', justifyContent: 'left', width: 180 },
    { id: 'departments', name: '部门', xs: 1.5, textAlign: 'left', justifyContent: 'left', width: 240 },
    { id: 'state', name: '账号状态', xs: 1, textAlign: 'left', justifyContent: 'left', width: 180 },
    { id: 'creatTime', name: '创建时间', xs: 1.5, textAlign: 'left', justifyContent: 'left', width: 220 },
    // { id: 'lastLoginTime', name: '最后登录时间', xs: 1, textAlign: 'left', justifyContent: 'left', width: 220 },
    { id: 'wechatOpenid', name: '微信网页授权_OpenID', xs: 2, textAlign: 'left', justifyContent: 'left', width: 180 },
    // { id: 'wechatPcOpenid', name: 'PC微信扫码_OpenID', xs: 2, textAlign: 'left', justifyContent: 'left', width: 180 },
    // { id: 'wechatUnionId', name: '微信_UnionID', xs: 2, textAlign: 'left', justifyContent: 'left', width: 180 }
    // { id: 'wechatId', name: '"微信_微信号', xs: 2, textAlign: 'left', justifyContent: 'left', width: 180 }
]

export const rolesTitle: UserGroupTitle[] = [
    { id: 'checkbox', name: '', xs: 1, textAlign: 'center', justifyContent: 'center' },
    { id: 'name', name: '名称', xs: 2, textAlign: 'left', justifyContent: 'left' },
    { id: 'visiblePage', name: '可见页面数', xs: 2, textAlign: 'left', justifyContent: 'left' },
    { id: 'usersNum', name: '用户数量', xs: 7, textAlign: 'left', justifyContent: 'left' }
]

export const departmentGroupsTitle: UserDepartmentTitle[] = [
    { id: 'checkbox', name: '', xs: 1, textAlign: 'center', justifyContent: 'center' },
    { id: 'name', name: '部门', xs: 5, textAlign: 'left', justifyContent: 'left' },
    { id: 'personNum', name: '部门人数', xs: 2, textAlign: 'left', justifyContent: 'left' },
    { id: 'departmentManager', name: '部门主管', xs: 2, textAlign: 'left', justifyContent: 'left' },
    { id: 'extra', name: '操作', xs: 2, textAlign: 'center', justifyContent: 'right' }
]

export const groupMenu: MenuItem<UserGroupAction>[] = [
    {
        section: '',
        items: [
            {
                name: '删除',
                icon: 'Trash',
                hotkey: '',
                action: 'DELETE'
            }
        ]
    }
]

export const inviteUser: InviteUser[] = [
    {
        username: '',
        mobile: '',
        userId: nanoid(32),
        email: ''
    }
]

export const ALL_DEPARTMENT = 'allDepartment'

export const DEFAULT_VALUES = {
    managerUserId: '',
    name: '',
    parentId: ''
}

export const dateFormat = 'yyyy-MM-dd HH:mm:ss'
