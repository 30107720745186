import { Button, Modal, Text, Toast } from '@byecode/ui'
import { type AppRole, useAtomAction } from '@lighthouse/shared'
import { useDebounce } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { unLinkApplicationAtom } from '@/atoms/application/action'
import {
    fetchDataSourceDepartmentAtom,
    fetchDataSourceRoleAtom,
    fetchDataSourceUserAtom,
    fetchEnvAtom,
    setDataSourceRoleAtom
} from '@/atoms/dataSource/action'
import { dataSourceEnvAtom } from '@/atoms/dataSource/state'
import { useApplicationList } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'
import { createAppRole, deleteAppRole, updateAppRole } from '@/services'
import { useDataSourceRoles } from '@/shared/reusable'

import { LinkExtra } from '../LinkExtra'
import PanelHeader from '../PanelHeader'
import RoleList from '../RoleList'
import * as CM from '../styles'
import TollTop from '../ToolTop'

interface IRolePanelProps {}

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px 24px 24px;
    background-color: var(--color-white);
    /* display: flex; */
`

const SCxToolsContainer = styled.div`
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`

interface RolePanelState {
    keyword: string
    list: AppRole[]
    searchList: AppRole[]
    currentUserId: string
    updateGroupName: string
    selectRoleIds: string[]
}

export const RolePanel: React.FC<IRolePanelProps> = () => {
    const applicationList = useApplicationList()
    const [state, setState] = useImmer<RolePanelState>({
        keyword: '',
        list: [],
        searchList: [],
        currentUserId: '',
        updateGroupName: '',
        selectRoleIds: []
    })
    // const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceEnv = useAtomValue(dataSourceEnvAtom)
    const roles = useDataSourceRoles()
    const { run: setDataSourceUserGroup } = useAtomAction(setDataSourceRoleAtom)
    const { run: fetchDataSourceRole } = useAtomAction(fetchDataSourceRoleAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { run: unLinkApplication } = useAtomAction(unLinkApplicationAtom)
    // const { update: updateUsers } = useDataSourceUsers()

    const debouncedTitle = useDebounce(state.keyword, 200)

    useEffect(() => {
        const search = debouncedTitle.trim()
        const searchList = roles.filter(item => item.name?.includes(search))
        if (roles.length === 0) {
            return setState(draft => {
                draft.list = roles
                draft.searchList = []
                draft.selectRoleIds = []
            })
        }

        setState(draft => {
            draft.list = roles
            draft.searchList = searchList
            draft.selectRoleIds = []
        })
    }, [debouncedTitle, roles, setState])

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.keyword = ev.target.value
            })
        },
        [setState]
    )

    const handleAddGroup = useCallback(async () => {
        const isSuccess = await createAppRole(envId, `未命名角色${roles.length + 1}`)
        if (isSuccess) {
            fetchDataSourceRole()
        }
    }, [envId, fetchDataSourceRole, roles.length])

    const handleChangeName = useCallback(
        (roleName: string, roleId: string) => {
            const newGroup = roles.map(role => {
                if (role.id === roleId) {
                    return { ...role, name: roleName }
                }
                return role
            })
            setDataSourceUserGroup(newGroup)
            updateAppRole(envId, { name: roleName, id: roleId })
        },
        [envId, roles, setDataSourceUserGroup]
    )

    const handleDelete = useCallback(
        async (roleIds: string[]) => {
            const isSuccess = await deleteAppRole(envId, roleIds)
            if (isSuccess) {
                fetchDataSourceRole()
                fetchDataSourceUser()
                Toast.success('删除成功')
            }
        },
        [envId, fetchDataSourceRole, fetchDataSourceUser]
    )

    const handleSelectGroups = useCallback(
        (ids: string[]) => {
            setState(draft => {
                draft.selectRoleIds = ids
            })
        },
        [setState]
    )

    const handleCancelLink = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认取消使用？',
            okStatus: 'error',
            okText: '确认'
        })
        if (isConfirm) {
            await unLinkApplication(envId)
            fetchEnv()
            await Promise.all([fetchDataSourceDepartment(), fetchDataSourceRole()])
            fetchDataSourceUser()
        }
    }, [envId, fetchDataSourceDepartment, fetchDataSourceUser, fetchDataSourceRole, fetchEnv, unLinkApplication])

    return (
        <Container>
            <TollTop
                leftFill={
                    <CM.LeftToolFill>
                        <Text color="var(--color-black)" size={16} weight={600}>
                            角色
                        </Text>
                        <LinkExtra applicationList={applicationList} dataSourceEnv={dataSourceEnv} onUnLinkApplication={handleCancelLink} />
                    </CM.LeftToolFill>
                }
                rightFill={
                    <SCxToolsContainer>
                        {state.selectRoleIds.length > 0 ? (
                            <Button
                                onClick={() =>
                                    handleSelectGroups(state.selectRoleIds.length > 0 ? [] : state.searchList.map(draft => draft.id))
                                }
                                // style={{ color: 'var(--color-main)' }}
                                type="default"
                                // icon={<CM.Icon type="Add" />}
                            >
                                取消选择
                            </Button>
                        ) : (
                            <PanelHeader keyword={state.keyword} onSearchChange={handleSearchChange} />
                        )}

                        {state.selectRoleIds.length > 0 && (
                            <Button
                                onClick={() => handleDelete(state.selectRoleIds)}
                                style={{ color: 'var(--color-red-500)', borderColor: 'var(--color-red-500)' }}
                                type="text"
                                icon={<CM.Icon type="Trash" color="var(--color-red-500)" />}
                            >
                                删除
                            </Button>
                        )}
                        {state.selectRoleIds.length === 0 && (
                            <Button onClick={handleAddGroup} type="primary" icon={<CM.Icon type="Add" color="var(--color-white)" />}>
                                创建角色
                            </Button>
                        )}
                    </SCxToolsContainer>
                }
            />
            <RoleList
                roles={state.searchList}
                selectRoleIds={state.selectRoleIds}
                onSelectGroup={handleSelectGroups}
                onChangeName={handleChangeName}
            />
        </Container>
    )
}
