import { Button, IconFont, Popover } from '@byecode/ui'
import { AppUserStatus } from '@lighthouse/core'
import { find } from 'rambda'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo, useState } from 'react'

import { systemOperatorOptions } from '@/constants/flow'

import * as SC from './styles'
import type { PersonSelectProps, PersonSelectValueType } from './types'
import { UserTagItem } from './UserTagItem'

const PersonSelect = React.forwardRef<HTMLDivElement, PersonSelectProps>(
    (
        {
            defaultValue = '',
            value = [],
            userOptions = [],
            roleOptions = [],
            onChange,
            onFocus,
            onClear,
            style,
            overlayStyle,
            className,
            downIcon = 'ArrowDownSmall',
            disabled
        },
        ref
    ) => {
        const [open, setOpen] = useState(false)
        const [currentValue, setCurrentValue] = useState(value ?? defaultValue)
        const [searchVal, setSearchVal] = useState('')
        const handleItemClick = useCallback(
            (value: PersonSelectValueType) => {
                const isExit = find(item => item.identifierId === value.identifierId, currentValue)
                const filterValue = currentValue.filter(item => item.identifierId !== value.identifierId)
                const newValue = isExit ? filterValue : [...filterValue, value]
                setCurrentValue(newValue)
                onChange?.(newValue)
            },
            [currentValue, onChange]
        )

        const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
            setSearchVal(e.target.value)
        }, [])

        const handleRemove = useCallback(
            (id: string) => {
                const filterValue = currentValue.filter(item => item.identifierId !== id)
                setCurrentValue(filterValue)
                onChange?.(filterValue)
            },
            [currentValue, onChange]
        )

        const getTag = useCallback(
            (data: PersonSelectValueType) => {
                switch (data.identifierType) {
                    case 'USER': {
                        const user = find(item => item.uniqueUserId === data.identifierId, userOptions)
                        if (user) {
                            const userData = user.state === AppUserStatus.DEPART ? { ...user, username: `${user.username}(已注销)` } : user
                            return (
                                <SC.PersonTagItem key={data.identifierId}>
                                    <UserTagItem data={userData} />
                                    <Button type="text" icon={<IconFont type="Close" />} onClick={() => handleRemove(data.identifierId)} />
                                </SC.PersonTagItem>
                            )
                        }
                        return null
                    }
                    case 'ROLE': {
                        const role = find(item => item.id === data.identifierId, roleOptions)
                        if (role) {
                            return (
                                <SC.PersonTagItem key={data.identifierId}>
                                    <SC.GroupAvatar>
                                        <IconFont type="SpaceTeam" />
                                    </SC.GroupAvatar>
                                    <SC.PersonSelectLabel>{role.name}</SC.PersonSelectLabel>
                                    <Button type="text" icon={<IconFont type="Close" />} onClick={() => handleRemove(data.identifierId)} />
                                </SC.PersonTagItem>
                            )
                        }
                        return null
                    }
                    case 'SYSTEM_OPERATOR': {
                        const systemOperator = find(item => item.value === data.identifierId, systemOperatorOptions)
                        if (systemOperator) {
                            return (
                                <SC.PersonTagItem key={data.identifierId}>
                                    <SC.GroupAvatar>
                                        <IconFont type="SpaceTeam" />
                                    </SC.GroupAvatar>
                                    <SC.PersonSelectLabel>{systemOperator.label}</SC.PersonSelectLabel>
                                    <Button type="text" icon={<IconFont type="Close" />} onClick={() => handleRemove(data.identifierId)} />
                                </SC.PersonTagItem>
                            )
                        }
                        return null
                    }
                    default: {
                        return null
                    }
                }
            },
            [handleRemove, roleOptions, userOptions]
        )

        const renderList = useMemo(() => {
            const users = userOptions.filter(item => item.username.includes(searchVal) && item.state !== AppUserStatus.DEPART)
            const roles = roleOptions.filter(item => item.name.includes(searchVal))
            const systemOperators = systemOperatorOptions.filter(item => item.label?.includes(searchVal))
            return (
                <SC.PersonSelectList>
                    {users.length > 0 && (
                        <SC.Group>
                            <SC.GroupLabel>成员</SC.GroupLabel>
                            {users.map(user => {
                                return (
                                    <SC.PersonSelectItem
                                        key={user.uniqueUserId}
                                        onClick={() => handleItemClick({ identifierId: user.uniqueUserId, identifierType: 'USER' })}
                                    >
                                        <UserTagItem display="flex" data={user} />
                                    </SC.PersonSelectItem>
                                )
                            })}
                        </SC.Group>
                    )}
                    <SC.Group>
                        <SC.GroupLabel>角色</SC.GroupLabel>
                        {roles.length > 0 &&
                            roles.map(({ id, name }) => {
                                return (
                                    <SC.PersonSelectItem
                                        key={id}
                                        onClick={() => handleItemClick({ identifierId: id, identifierType: 'ROLE' })}
                                    >
                                        <SC.GroupAvatar>
                                            <IconFont type="SpaceTeam" />
                                        </SC.GroupAvatar>
                                        <SC.PersonSelectLabel>{name}</SC.PersonSelectLabel>
                                    </SC.PersonSelectItem>
                                )
                            })}
                    </SC.Group>
                    <SC.Group>
                        <SC.GroupLabel>系统</SC.GroupLabel>
                        {systemOperators.length > 0 &&
                            systemOperators.map(({ value, label }) => {
                                return (
                                    <SC.PersonSelectItem
                                        key={value}
                                        onClick={() => handleItemClick({ identifierId: value, identifierType: 'SYSTEM_OPERATOR' })}
                                    >
                                        <SC.GroupAvatar>
                                            <IconFont type="SpaceTeam" />
                                        </SC.GroupAvatar>
                                        <SC.PersonSelectLabel>{label}</SC.PersonSelectLabel>
                                    </SC.PersonSelectItem>
                                )
                            })}
                    </SC.Group>
                </SC.PersonSelectList>
            )
        }, [handleItemClick, roleOptions, searchVal, userOptions])

        return (
            <Popover disabled={disabled} opened={open} onChange={setOpen}>
                <Popover.Target>
                    <SC.PersonSelectWrapper ref={ref} className={className} style={style} onFocus={onFocus}>
                        <SC.PersonSelectValueContent>
                            <SC.PersonSelectedValue>
                                {currentValue.map(item => getTag(item))}
                                {!disabled && (
                                    <SC.PersonAdder onClick={() => setOpen(v => !v)}>
                                        <SC.IconAdder type="Add" />
                                    </SC.PersonAdder>
                                )}
                            </SC.PersonSelectedValue>
                            {/* {clearable && <SC.ClearIcon onClick={handleClear} type="Close" />} */}
                        </SC.PersonSelectValueContent>
                    </SC.PersonSelectWrapper>
                </Popover.Target>
                <Popover.Dropdown style={overlayStyle}>
                    <SC.SearchWrapper>
                        <SC.Search>
                            <SC.SearchExtra>
                                <IconFont type="Search" />
                            </SC.SearchExtra>
                            <SC.SearchInput placeholder="搜索" value={searchVal} onChange={handleSearchChange} />
                        </SC.Search>
                    </SC.SearchWrapper>
                    {renderList}
                </Popover.Dropdown>
            </Popover>
        )
    }
)

export default PersonSelect
