import type { NavigationLogoType } from '@lighthouse/core'
import { type ApplicationPublishInfo,useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import chroma from 'chroma-js'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { appAtom } from '@/atoms/application/state'
import { type RoundAvatarProps, RoundAvatar } from '@/components/RoundAvatar'
import { TagIcon } from '@/components/TagIcon'
import { usePageURI } from '@/hooks/usePageURI'

import * as CM from '../../styles'
import { urlToBase64 } from '../help'
import { SharedContainer } from '../Share'

interface ShareQrCodeProps {
    data?: ApplicationPublishInfo
    isPreview?: boolean
    onBack?: () => void
    onClose?: () => void
}

const SCxQrCode = styled.div`
    border-radius: 16px;
    border: rgba(18, 175, 148, 0.2) solid 3px;
    font-size: var(--font-size-normal);
    background-color: var(--color-white);
`

const SCxQrCodeWrapper = styled.div`
    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #12af94;
    border-radius: 12px;
`

const SCxContent = styled.div`
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`
const SCxSuffix = styled.div`
    /* justify-self: flex-end; */
    margin-left: auto;
`

const SCxRoundAvatar = styled(RoundAvatar)`
    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: unset;
    }
`

export const ShareQrCode: React.FunctionComponent<ShareQrCodeProps> = ({ data, isPreview, onClose, onBack }) => {
    const ref = useRef<HTMLDivElement>(null)
    const { shareUrl } = usePageURI({ isCurrentPage: false, isProdHost: !isPreview })
    const {
        logo,
        name: appName,
        icon: appIcon,
        theme
    } = useAtomData(
        appAtom,
        useCallback(({ name, config, icon }) => ({ name, logo: config.navbar.logo, icon, theme: config.theme }), [])
    )
    const [loading, setLoading] = useState(false)

    const res = useAsyncRetry(() => {
        if (logo.logoType === 1) {
            return urlToBase64(logo.imgUrl.square)
        }
        return Promise.resolve('')
    }, [logo.logoType])

    const logoParams = useMemo(() => {
        const logoType = logo.logoType === 1 && logo.imgUrl.square ? logo.logoType : 0

        const propsMap: Record<NavigationLogoType, Pick<RoundAvatarProps, 'icon' | 'type' | 'background' | 'objectFit'>> = {
            0: { type: 'icon', icon: appIcon, background: 'var(--color-app-main)' },
            1: { type: 'img', icon: res.value, background: 'var(--color-white)', objectFit: 'unset' }
        }
        return propsMap[logoType]
    }, [appIcon, logo.imgUrl.square, logo.logoType, res.value])

    const handleDownload = useCallback(async () => {
        if (!ref.current || loading) {
            return
        }
        const canvas = await html2canvas(ref.current, {
            scale: 3,
            useCORS: true
        })
        // Convert the canvas to a data URL
        const dataURL = canvas.toDataURL('image/png')
        setLoading(true)
        const downloadLink = document.createElement('a')
        downloadLink.href = dataURL
        downloadLink.download = 'shareQrCode.png'
        document.body.append(downloadLink)
        downloadLink.click()
        downloadLink.remove()
    }, [loading])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
                setLoading(false)
            }
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [loading])

    const primaryColor = useMemo(() => {
        return theme.palettes.find(item => item.id === theme.primaryPointer)?.value
    }, [theme.palettes, theme.primaryPointer])

    const alpha20PrimaryColor = useMemo(() => {
        if (!primaryColor) {
            return
        }

        const chromaColor = chroma(primaryColor)
        const alpha = chromaColor.alpha()

        return chromaColor.alpha(alpha * 0.2).hex()
    }, [primaryColor])

    return (
        <SharedContainer title="分享二维码" subTitle="预览" onClose={onClose} onBack={() => onBack?.()}>
            <SCxContent>
                <SCxQrCode ref={ref} style={{ borderColor: alpha20PrimaryColor }}>
                    <SCxQrCodeWrapper style={{ borderColor: primaryColor }}>
                        <QRCode size={156} level="H" value={shareUrl} renderAs="svg" />
                        <div
                            style={{
                                position: 'absolute',
                                transform: ' translate(-50%, -50%)',
                                left: '50%',
                                top: '50%',
                                padding: 4,
                                borderRadius: 12,
                                background: '#fff'
                            }}
                        >
                            <SCxRoundAvatar
                                size={36}
                                iconSize={20}
                                radius="8px"
                                iconColor="var(--color-white)"
                                bordered={false}
                                {...logoParams}
                            />
                        </div>
                    </SCxQrCodeWrapper>
                </SCxQrCode>
                <Text weight={500} size={16} style={{ lineHeight: '24px' }}>
                    {appName}
                </Text>
                <Text weight={500} color="rgba(0, 0, 0, 0.34)" size={12} style={{ lineHeight: '20px', marginTop: 4 }}>
                    扫描上方二维码访问应用
                </Text>
            </SCxContent>

            <CM.ToolContainer style={{ marginTop: 12 }} className="hoverStyle" onClick={handleDownload}>
                <TagIcon
                    icon="DownloadMinimalisticIconLibrary"
                    size={36}
                    iconSize={20}
                    radius={8}
                    iconColor="var(--color-black)"
                    background="rgba(38, 65, 90, 0.06)"
                    style={{ flexShrink: 0 }}
                />
                <Text size={14} lineClamp={1}>
                    下载
                </Text>
                <SCxSuffix>{loading ? <CM.Icon type="Tick" color="#12B76A" /> : <Text>.PNG</Text>}</SCxSuffix>
            </CM.ToolContainer>
        </SharedContainer>
    )
}
