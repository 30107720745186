import { use } from 'echarts/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdate, useUpdateEffect } from 'react-use'

// 计算两点之间的距离
function distance(x1: number, y1: number, x2: number, y2: number): number {
    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
}

// 计算三角形的面积
function triangleArea(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number): number {
    return Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2)
}

// 检查点是否在三角形内
function isPointInsideTriangle(x: number, y: number, x1: number, y1: number, x2: number, y2: number, x3: number, y3: number): boolean {
    // 计算三个子三角形的面积
    const totalArea: number = triangleArea(x1, y1, x2, y2, x3, y3)
    const area1: number = triangleArea(x, y, x1, y1, x2, y2)
    const area2: number = triangleArea(x, y, x2, y2, x3, y3)
    const area3: number = triangleArea(x, y, x3, y3, x1, y1)

    // 如果子三角形的面积之和等于整个三角形的面积，则点在三角形内
    return totalArea === area1 + area2 + area3
}

export function useAreaHover(id: string) {
    const blockKindRef = useRef<HTMLDivElement>(null)
    const [showId, setShowId] = useState('')
    // 从showId的Dom移出时的坐标
    const [axios, setAxios] = useState([0, 0])
    // 左侧栏鼠标的坐标
    const [pointerAxios, setPointerAxios] = useState([0, 0])
    // 从id的dom移动出去开始计算的时间
    const [startTime, setStartTime] = useState<null | number>(null)
    // 是否超时
    const [isTimeOut, setIsTimeOut] = useState(false)
    // 鼠标是否移出左侧栏
    const [isPointerOut, setIsPointerOut] = useState(true)

    // 是否在三角区域内
    const isInArea = useMemo(() => {
        if (!blockKindRef.current) {
            return false
        }
        if (isPointerOut) {
            return true
        }
        if (!axios.some(Boolean)) {
            return true
        }

        const rect = blockKindRef.current.getBoundingClientRect()
        const boundaryRightTopAxios = [rect.x + rect.width, rect.y]
        const boundaryRightBottomAxios = [rect.x + rect.width, rect.y + rect.height]
        return isPointInsideTriangle(
            pointerAxios[0],
            pointerAxios[1],
            axios[0],
            axios[1],
            boundaryRightTopAxios[0],
            boundaryRightTopAxios[1],
            boundaryRightBottomAxios[0],
            boundaryRightBottomAxios[1]
        )
    }, [axios, isPointerOut, pointerAxios])

    // 计算时间是否超时
    useEffect(() => {
        let timer: null | NodeJS.Timer = null
        if (isPointerOut) {
            setIsTimeOut(false)
            setStartTime(null)
            timer && clearInterval(timer)
            return () => {
                timer && clearInterval(timer)
            }
        }
        if (!startTime) {
            timer && clearInterval(timer)
            return () => {
                timer && clearInterval(timer)
            }
        }
        timer = setInterval(() => {
            setIsTimeOut(startTime ? Date.now() - startTime > 800 : false)
        }, 100)

        return () => {
            timer && clearInterval(timer)
        }
    }, [isPointerOut, startTime])

    // 设置展示的showId
    useEffect(() => {
        if (isPointerOut || !axios.some(Boolean) || !id) {
            return
        }

        if (!isInArea) {
            setShowId(id)
            return
        }

        if (isTimeOut) {
            setShowId(id)
        }
    }, [axios, id, isInArea, isPointerOut, isTimeOut])

    // 切换showId时清空部分设置
    useUpdateEffect(() => {
        setStartTime(null)
        setAxios([0, 0])
    }, [showId])

    const handlePointerMove = useCallback((e: PointerEvent) => {
        requestAnimationFrame(() => {
            setPointerAxios([e.pageX, e.pageY])
        })
    }, [])

    const handlePointerLeave = useCallback((e: PointerEvent) => {
        requestAnimationFrame(() => {
            setAxios([0, 0])
            setIsPointerOut(true)
        })
    }, [])

    // 监听左侧栏的鼠标
    useEffect(() => {
        if (!blockKindRef.current || isPointerOut) {
            return
        }
        blockKindRef.current.addEventListener('pointermove', handlePointerMove)
        blockKindRef.current.addEventListener('pointerleave', handlePointerLeave)
        return () => {
            blockKindRef.current?.removeEventListener('pointermove', handlePointerMove)
            blockKindRef.current?.removeEventListener('pointerleave', handlePointerLeave)
        }
    }, [handlePointerLeave, handlePointerMove, isPointerOut])

    useEffect(() => {
        if (isPointerOut) {
            blockKindRef.current?.removeEventListener('pointermove', handlePointerMove)
            blockKindRef.current?.removeEventListener('pointerleave', handlePointerLeave)
        }
    }, [handlePointerLeave, handlePointerMove, isPointerOut])

    useEffect(() => {
        setIsPointerOut(false)
        setShowId(v => v || id)
    }, [id])

    // 监听showId出去时的点位
    useEffect(() => {
        if (!showId) {
            return
        }
        function handlePointerLeave(e: PointerEvent) {
            requestAnimationFrame(() => {
                setAxios([e.pageX, e.pageY])
                setIsPointerOut(false)
                setStartTime(Date.now())
            })
        }

        document.querySelector<HTMLDivElement>(`[data-kind-id=${showId}]`)?.addEventListener('pointerleave', handlePointerLeave)

        return () => {
            document.querySelector<HTMLDivElement>(`[data-kind-id=${showId}]`)?.removeEventListener('pointerleave', handlePointerLeave)
        }
    }, [showId])

    // 监听activeId的dom进入时的点位
    useEffect(() => {
        if (!id) {
            return
        }
        function handlePointerEnter(e: PointerEvent) {
            requestAnimationFrame(() => {
                setAxios([e.pageX, e.pageY])
            })
        }
        document.querySelector<HTMLDivElement>(`[data-kind-id=${id}]`)?.addEventListener('pointerenter', handlePointerEnter)
        return () => {
            document.querySelector<HTMLDivElement>(`[data-kind-id=${id}]`)?.addEventListener('pointerenter', handlePointerEnter)
        }
    }, [id])

    return { blockKindRef, showId, isInArea, setShowId }
}
