import { Checkbox } from '@byecode/ui/components/Checkbox'
import type { AppRole } from '@lighthouse/shared'
import { Flex } from '@mantine/core'
import React from 'react'

import * as SC from './styles'

interface GroupItemProps {
    disabled?: boolean
    checked: boolean
    data: AppRole
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const GroupItem: React.FunctionComponent<GroupItemProps> = ({ data, disabled, checked, onClick }) => {
    return (
        <SC.DropDownItem>
            <Flex onClick={onClick}>
                <Checkbox disabled={disabled} checked={checked} label={data.name} />
            </Flex>
        </SC.DropDownItem>
    )
}

export default GroupItem
