import {
    Box,
    Button,
    Divider,
    Flex,
    Group,
    IconFont,
    Input,
    Popover,
    SegmentedControl,
    singleTextEllipsis,
    Text,
    Toast,
    Tooltip
} from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { TabConfig, TabsBlockConfig, TabsType, VariableADTvalue } from '@lighthouse/core'
import { ALIGNMENT, BACKGROUND_TYPE } from '@lighthouse/core'
import {
    DEFAULT_ACTION,
    FilledInput,
    FillPickerPopoverDropdown,
    getTextColorStyle,
    ListItem4ByecodeUi,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import produce from 'immer'
import type { CSSProperties, FC } from 'react'
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { applicationSettingAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { TAB_TYPE_COLOR_MAP } from '@/constants/Block/generate/tabs'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

import { applicationAtom } from '../atoms'
import { IconControl } from '../Common/IconControl'
import { ConditionFilter } from '../Common/VisibilityFilter/ConditionFilter'

export type TabBarOnChangeAction = 'label' | 'delete' | 'create' | 'move'

const TabBarSettingsWrapper = styled.div``

const TabGroupItem = styled(Group)`
    padding: 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    overflow: hidden;
    & + & {
        margin-top: 12px;
    }
    & > .byecode-group-wrapper {
        padding: 0 8px;
        background-color: var(--color-gray-100);
    }
    & > .byecode-group-collapse {
        background-color: #fff;
    }
`
const TabGroupItemLabel = styled.span`
    font-weight: normal;
    ${singleTextEllipsis()}
`

const CollapseButton = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    background-color: rgba(38, 65, 90, 0.06);
    padding: 6px;
    border-radius: 50%;
    &:hover {
        background-color: var(--color-gray-200);
    }
`

interface TabBarSettingsProps {
    isSegmentedType: boolean
    variant: TabsType
}

/** 标签栏标签设置 */
const TabBarSettings = forwardRef<HTMLDivElement, TabBarSettingsProps>(({ variant, isSegmentedType }, ref) => {
    const { control, setValue, getValues } = useFormContext<TabsBlockConfig>()

    const disabledWithVersion = useIsDisabledWithVersion()
    const { fields, append, move, remove } = useFieldArray({ control, name: `baseList`, keyName: 'key' })

    const [activeId, setActiveId] = useState<string>()

    const countRef = useRef(fields.length)

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )
    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)

            if (openedIndex !== -1) {
                setOpenedIndex(-1)
            }
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                move(activeIndex, overIndex)
            }
        }
        setActiveId(undefined)
    }

    const onCreate = () => {
        const id = nanoid()
        const key = (++countRef.current).toString().padStart(2, '0')
        append({
            id,
            name: `标签 ${key}`,
            icon: '',
            iconPosition: ALIGNMENT.left,
            action: DEFAULT_ACTION /* associatedView: '' */ /* sizeFit: SIZE_FIT.contain */
        })

        // 同步添加style list配置
        const variantMap = getValues(`variantMap`)
        const newVarianMap = produce(variantMap, draft => {
            Object.entries(draft).forEach(([k, v]) => {
                v.styleList.push({ id, ...TAB_TYPE_COLOR_MAP[k as TabsType] })
            })
        })
        setValue(`variantMap`, newVarianMap)
    }

    const onDelete = (index: number) => {
        if (fields.length === 1) {
            Toast.warning('不可删除，至少需要1个tab')
            return
        }
        remove(index)

        // 同步删除style list配置
        const { id } = fields[index]
        const variantMap = getValues(`variantMap`)
        const newVarianMap = produce(variantMap, draft => {
            Object.values(draft).forEach(v => {
                const fromStyleIndex = v.styleList.findIndex(item => item.id === id)
                if (fromStyleIndex === -1) {
                    return
                }
                v.styleList.splice(fromStyleIndex, 1)
            })
        })

        setValue(`variantMap`, newVarianMap)
    }

    const onCopy = (index: number) => {
        const id = nanoid()

        const fromData = fields[index]

        // 同步复制style list的配置
        const variantMap = getValues(`variantMap`)
        const newVarianMap = produce(variantMap, draft => {
            Object.values(draft).forEach(v => {
                const fromStyle = v.styleList.find(item => item.id === fromData.id)
                v.styleList.push({ id, ...fromStyle })
            })
        })

        append({ ...fromData, id, name: `${fromData.name} 副本` })
        setValue(`variantMap`, newVarianMap)
    }

    const [openedIndex, setOpenedIndex] = useState(-1)

    const items = useMemo(() => (isSegmentedType ? fields.slice(0, 5) : fields), [isSegmentedType, fields])

    return (
        <TabBarSettingsWrapper ref={ref}>
            <div style={{ overflow: 'auto' }}>
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(undefined)}
                >
                    <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
                        {items.map((item, index) => (
                            <SortableItem
                                disabled={disabledWithVersion}
                                key={item.id}
                                index={index}
                                data={item}
                                onDelete={onDelete}
                                onCopy={onCopy}
                                variant={variant}
                                opened={openedIndex === index}
                                onOpenedChange={v => {
                                    setOpenedIndex(v ? index : -1)
                                }}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </div>
            <Flex alignItems="center" gap="10px" style={{ margin: '12px 0' }}>
                <Button
                    disabled={(isSegmentedType && items.length === 5) || disabledWithVersion}
                    radius={100}
                    icon={<IconFont type="Add" />}
                    onClick={onCreate}
                >
                    添加
                </Button>
                {isSegmentedType && (
                    <Tooltip title="胶囊类型标签最多显示5个选项">
                        <IconFont type="Question" size={16} fill="var(--color-gray-400)" />
                    </Tooltip>
                )}
            </Flex>
        </TabBarSettingsWrapper>
    )
})

/** ************************************************ */
/** **************** Dnd Sort Item ***************** */
/** ************************************************ */
const ICON_POSITION_OPTIONS = [
    {
        label: '左',
        value: ALIGNMENT.left
    },
    {
        label: '右',
        value: ALIGNMENT.right
    }
]

interface SortableItemProps {
    data: TabConfig
    index: number
    disabled?: boolean
    onDelete: (i: number) => void
    onCopy: (i: number) => void
    variant: TabsType

    opened: boolean
    onOpenedChange: (v: boolean) => void
}

const SortableItem: FC<SortableItemProps> = ({ variant, data, index, disabled, onDelete, onCopy, opened, onOpenedChange }) => {
    const { setNodeRef, setActivatorNodeRef, listeners, attributes, transform, transition } = useSortable({
        id: data.id,
        attributes: { role: 'li' },
        disabled
    })

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(prev.recordId, curr.recordId)
    const getImageVariableUrl = useCallback((value: VariableADTvalue) => renderLabel(value, { useFileUrl: true }), [renderLabel])

    const { control, register, watch } = useFormContext<TabsBlockConfig>()

    const [action, name] = watch([`baseList.${index}.action`, `baseList.${index}.name`])

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition
    }

    const palettes = useAtomData(
        applicationSettingAtom,
        useCallback(s => {
            return s?.theme.palettes ?? []
        }, [])
    )
    const colorSystemMethods = useColorSystemAction()

    const pageList = useAtomData(
        applicationAtom,
        useCallback(s => s.pageList, [])
    )

    return (
        <TabGroupItem
            ref={setNodeRef}
            style={style}
            opened={opened}
            onCollapseChange={onOpenedChange}
            label={
                <Flex gap="6px">
                    {!disabled && (
                        <IconFont type="DotsSix" color="var(--color-gray-400)" ref={setActivatorNodeRef} {...listeners} {...attributes} />
                    )}
                    {data.icon && <IconFont type={data.icon} style={{ ...getTextColorStyle(data.iconColor, palettes) }} />}
                    <TabGroupItemLabel>{data.name}</TabGroupItemLabel>
                </Flex>
            }
            extra={
                <Flex gap={6} alignItems="center">
                    <Button
                        type="text"
                        icon={<IconFont type="Duplicate" size={16} fill="var(--color-gray-400)" />}
                        onClick={() => {
                            onCopy(index)
                        }}
                        radius={5}
                        style={{ padding: 2, width: 20, height: 20 }}
                    />
                    <Button
                        type="text"
                        icon={<IconFont type="Trash" size={16} fill="var(--color-gray-400)" />}
                        onClick={() => {
                            onDelete(index)
                        }}
                        radius={5}
                        style={{ padding: 2, width: 20, height: 20 }}
                    />
                </Flex>
            }
        >
            <Group collapsible={false} label="基础" mode="none" mx={-16}>
                {/* <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>绑定至</Text>
                    <Controller
                        control={control}
                        name={`baseList.${index}.associatedView`}
                        render={({ field }) => <Select options={viewListOptions} {...field} styles={{ root: { width: 180 } }} />}
                    />
                </ListItem4ByecodeUi> */}

                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>标题</Text>
                    <Input style={{ width: 180 }} maxLength={20} {...register(`baseList.${index}.name`)} />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>图标</Text>
                    <Controller control={control} name={`baseList.${index}.icon`} render={({ field }) => <IconControl {...field} />} />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>图标位置</Text>
                    <Controller
                        control={control}
                        name={`baseList.${index}.iconPosition`}
                        render={({ field }) => (
                            <SegmentedControl data={ICON_POSITION_OPTIONS} fullWidth style={{ width: 180 }} {...field} />
                        )}
                    />
                </ListItem4ByecodeUi>
                {/* <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>宽度规则</Text>
                    <Controller
                        control={control}
                        name={`baseList.${index}.sizeFit`}
                        render={({ field }) => (
                            <SegmentedControl {...field} data={SIZE_FIT_TYPE_CONTROL} fullWidth style={{ width: 180 }} />
                        )}
                    />
                </ListItem4ByecodeUi> */}

                <ConditionFilter
                    name={`baseList.${index}.visibilityFilter`}
                    label="显示条件"
                    enableHover={false}
                    enablePadding={false}
                    style={{ cursor: 'pointer' }}
                />
            </Group>

            <Divider />

            <Box mx={-8} my={16}>
                <ActionAdder
                    prefixName=""
                    index={`${index}`}
                    listKeyName="baseList"
                    title={name}
                    allPages={pageList}
                    action={action || DEFAULT_ACTION}
                />
            </Box>

            <Divider />

            <Group collapsible={false} label="颜色" mode="none" mx={-16}>
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>图标</Text>
                    <Controller
                        control={control}
                        name={`variantMap.${variant}.styleList.${index}.iconColor`}
                        render={({ field }) => (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                <Popover.Target>
                                    <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                </Popover.Target>
                                <FillPickerPopoverDropdown
                                    title="填充"
                                    value={field.value}
                                    onChange={field.onChange}
                                    {...colorSystemMethods}
                                />
                                {/* <PopoverDropdownWithClose title="填充">
                                    <FillPicker value={field.value} onChange={field.onChange} />
                                </PopoverDropdownWithClose> */}
                            </Popover>
                        )}
                    />
                </ListItem4ByecodeUi>

                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>文本</Text>
                    <Controller
                        control={control}
                        name={`variantMap.${variant}.styleList.${index}.color`}
                        render={({ field }) => (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                <Popover.Target>
                                    <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                </Popover.Target>
                                {/* <PopoverDropdownWithClose title="填充">
                                    <FillPicker
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                    />
                                </PopoverDropdownWithClose> */}
                                <FillPickerPopoverDropdown
                                    title="填充"
                                    value={field.value}
                                    onChange={field.onChange}
                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                    {...colorSystemMethods}
                                />
                            </Popover>
                        )}
                    />
                </ListItem4ByecodeUi>

                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>背景</Text>
                    <Controller
                        control={control}
                        name={`variantMap.${variant}.styleList.${index}.background`}
                        render={({ field }) => (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                <Popover.Target>
                                    <FilledInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        getImageVariableUrl={getImageVariableUrl}
                                        style={{ width: 180 }}
                                    />
                                </Popover.Target>
                                {/* <PopoverDropdownWithClose title="填充">
                                    <FillPicker
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                                    />
                                </PopoverDropdownWithClose> */}
                                <FillPickerPopoverDropdown
                                    title="填充"
                                    value={field.value}
                                    onChange={field.onChange}
                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                                    {...colorSystemMethods}
                                />
                            </Popover>
                        )}
                    />
                </ListItem4ByecodeUi>

                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>描边</Text>
                    <Controller
                        control={control}
                        name={`variantMap.${variant}.styleList.${index}.borderColor`}
                        render={({ field }) => (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                <Popover.Target>
                                    <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                </Popover.Target>
                                {/* <PopoverDropdownWithClose title="填充">
                                    <FillPicker value={field.value} onChange={field.onChange} />
                                </PopoverDropdownWithClose> */}
                                <FillPickerPopoverDropdown
                                    title="填充"
                                    value={field.value}
                                    onChange={field.onChange}
                                    {...colorSystemMethods}
                                />
                            </Popover>
                        )}
                    />
                </ListItem4ByecodeUi>

                <Group
                    label="悬浮态"
                    defaultOpen={false}
                    renderRightIcon={(open, onChange) => {
                        return (
                            <CollapseButton onClick={() => onChange(!open)}>
                                <IconFont
                                    type="ArrowDownSmall"
                                    size={16}
                                    color="var(--color-gray-400)"
                                    style={{ transform: open ? 'rotate(-180deg)' : undefined, transition: 'transform 0.2s' }}
                                />
                            </CollapseButton>
                        )
                    }}
                    styles={{
                        root: { margin: '0 -16px', padding: 0 },
                        label: { marginLeft: 'auto', fontWeight: 400 },
                        rightIcon: { marginLeft: 'unset' }
                    }}
                >
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>图标</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.hover.iconColor`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker value={field.value} onChange={field.onChange} />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>

                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>文本</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.hover.color`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker
                                            value={field.value}
                                            onChange={field.onChange}
                                            enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                        />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>

                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>背景</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.hover.background`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput
                                            value={field.value}
                                            onChange={field.onChange}
                                            getImageVariableUrl={getImageVariableUrl}
                                            style={{ width: 180 }}
                                        />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker
                                            value={field.value}
                                            onChange={field.onChange}
                                            enabledBackgroundTypes={[
                                                BACKGROUND_TYPE.color,
                                                BACKGROUND_TYPE.gradient,
                                                BACKGROUND_TYPE.image
                                            ]}
                                        />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>

                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>描边</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.hover.borderColor`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker value={field.value} onChange={field.onChange} />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>
                </Group>

                <Group
                    label="选中态"
                    defaultOpen={false}
                    renderRightIcon={(open, onChange) => {
                        return (
                            <CollapseButton onClick={() => onChange(!open)}>
                                <IconFont
                                    type="ArrowDownSmall"
                                    size={16}
                                    color="var(--color-gray-400)"
                                    style={{ transform: open ? 'rotate(-180deg)' : undefined, transition: 'transform 0.2s' }}
                                />
                            </CollapseButton>
                        )
                    }}
                    styles={{
                        root: { margin: '0 -16px', padding: 0 },
                        label: { marginLeft: 'auto', fontWeight: 400 },
                        rightIcon: { marginLeft: 'unset' }
                    }}
                >
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>图标</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.active.iconColor`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker value={field.value} onChange={field.onChange} />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>

                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>文本</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.active.color`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker
                                            value={field.value}
                                            onChange={field.onChange}
                                            enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                        />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>

                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>背景</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.active.background`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput
                                            value={field.value}
                                            onChange={field.onChange}
                                            getImageVariableUrl={getImageVariableUrl}
                                            style={{ width: 180 }}
                                        />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker
                                            value={field.value}
                                            onChange={field.onChange}
                                            enabledBackgroundTypes={[
                                                BACKGROUND_TYPE.color,
                                                BACKGROUND_TYPE.gradient,
                                                BACKGROUND_TYPE.image
                                            ]}
                                        />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>

                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>描边</Text>
                        <Controller
                            control={control}
                            name={`variantMap.${variant}.styleList.${index}.active.borderColor`}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                    </Popover.Target>
                                    {/* <PopoverDropdownWithClose title="填充">
                                        <FillPicker value={field.value} onChange={field.onChange} />
                                    </PopoverDropdownWithClose> */}
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>
                </Group>
            </Group>
        </TabGroupItem>
    )
}

export default TabBarSettings
