import { Button, Empty, IconFont, Input, Menu, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import { mergeRefs, useUncontrolled } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { find, reduce } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import type { AppDepartment } from '../../types'
import { TooltipList, useWidth } from '../TooltipList'
import { TooltipText } from '../TooltipText'
import { DepartmentSelectItem } from './DepartmentItem'
import { DepartmentTreeDropDown } from './DepartmentTreeDropDown'
import { getDepartmentTree } from './help'
import * as SC from './styles'

interface DepartmentMultipleSelectProps {
    options: AppDepartment[]
    value?: string[]
    targetComponent?: React.ReactNode
    onChange?: (value: string[]) => void
}

const SCxTag = styled.div<{ isOmit?: boolean }>`
    line-height: 16px;
    padding: 4px 6px;
    height: 24px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

const Tag: React.FunctionComponent<{ data: AppDepartment; isLast: boolean }> = ({ data, isLast }) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(data.id)
    return (
        <TooltipText
            title={data.name}
            render={ref => (
                <SCxTag isOmit={isLast} ref={mergeRefs([ref, widthRef])} key={data.id}>
                    {data.name}
                </SCxTag>
            )}
        />
    )
}

export const DepartmentMultipleSelect: React.FunctionComponent<DepartmentMultipleSelectProps> = ({
    options,
    value: data,
    targetComponent,
    onChange
}) => {
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState('')
    const { ref, width } = useElementSize()
    const [value, handleChangeValue] = useUncontrolled<string[]>({ defaultValue: data })

    const optionsTree = useMemo(
        () => (search ? options?.filter(v => v.name.toLowerCase().includes(search.toLowerCase())) : getDepartmentTree(options)),
        [options, search]
    )

    const dataTagList = useMemo(() => {
        return reduce<string, AppDepartment[]>(
            (preVal, departmentId) => {
                const option = find(item => item.id === departmentId, options)
                return option ? [...preVal, option] : preVal
            },
            [],
            data ?? []
        )
    }, [options, data])

    const handleSearchChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(ev.target.value)
    }, [])

    const handleChange = useCallback(() => {
        onChange?.(value)
        setOpened(false)
    }, [onChange, value])

    const handleOpened = useCallback(
        (v: boolean) => {
            setOpened(v)
            setSearch('')
            if (v) {
                handleChangeValue(data ?? [])
            }
        },
        [data, handleChangeValue]
    )

    useUpdateEffect(() => {
        handleChangeValue(data ?? [])
    }, [data])

    return (
        <Menu opened={opened} position="bottom-start" width={400} onChange={handleOpened} withinPortal>
            <Menu.Target>
                {targetComponent ?? (
                    <div style={{ width: '100%' }}>
                        <SC.Trigger ref={ref} style={{ backgroundColor: 'transparent' }}>
                            <SC.TagContainer>
                                <TooltipList
                                    key={dataTagList.map(item => item.id).join('-')}
                                    data={dataTagList.map((item, index) => ({ label: item.name, value: String(index) }))}
                                    width={width - 22}
                                    render={visibleNum => {
                                        const showTagList = dataTagList.slice(0, visibleNum)
                                        return (
                                            <SC.TagWrapper isOmit={visibleNum !== dataTagList.length} style={{ flexWrap: 'nowrap' }}>
                                                {showTagList.map((tag, index) => (
                                                    <Tag isLast={showTagList.length - 1 === index} key={tag.id} data={tag} />
                                                ))}
                                            </SC.TagWrapper>
                                        )
                                    }}
                                />
                                {dataTagList.length === 0 && <Text color="var(--color-gray-400)">未加入部门</Text>}
                            </SC.TagContainer>
                            <SC.IconWrapper>
                                <IconFont type="ArrowDownSmall" size={16} color="var(--color-gray-500)" />
                            </SC.IconWrapper>
                        </SC.Trigger>
                    </div>
                )}
            </Menu.Target>
            <Menu.Dropdown compact>
                <DepartmentTreeDropDown options={optionsTree} flatOptions={options}  value={value} search={search} onChange={handleChangeValue} onFinish={handleChange} onSearchChange={handleSearchChange} />
            </Menu.Dropdown>
        </Menu>
    )
}
