import { Checkbox } from '@byecode/ui'
import type { AppRole } from '@lighthouse/shared'
import React, { useCallback } from 'react'

import { useDataSourceRoles, useDataSourceUsers } from '@/shared/reusable'

import { rolesTitle } from '../constant'
import EditInput from '../EditInput'
import * as CM from '../styles'
import type { UserGroupTitleType } from '../type'
import * as SC from './styles'

interface RoleItemProps {
    role: AppRole
    checked: boolean
    disabled?: boolean

    onChangeName?: (name: string, id: string) => void
    onSelectGroup?: (groupId: string) => void
}

const RoleItem: React.FC<RoleItemProps> = ({
    checked,
    role: { id, name, visiblePageCount, userCount },
    onChangeName,
    onSelectGroup,
    disabled
}) => {
    const { data: users = [] } = useDataSourceUsers()
    const roles = useDataSourceRoles()

    const renderColumn = useCallback(
        (type: UserGroupTitleType) => {
            switch (type) {
                case 'name': {
                    return (
                        <EditInput
                            readonly={disabled}
                            value={name}
                            onBlur={(value: string) => {
                                onChangeName?.(value, id)
                            }}
                            onChange={(value: string) => onChangeName?.(value, id)}
                            onReset={(val: string) => {
                                if (!val || roles.some(draft => draft.name === val)) {
                                    return name
                                }
                            }}
                        />
                    )
                }
                case 'visiblePage': {
                    return <>{visiblePageCount}</>
                }

                case 'usersNum': {
                    return <>{userCount}</>
                }
                case 'checkbox': {
                    return (
                        <Checkbox
                            disabled={disabled}
                            size="xs"
                            checked={checked}
                            onChange={e => onSelectGroup?.(e.target.checked ? id : '')}
                        />
                    )
                }

                default: {
                    return null
                }
            }
        },
        [checked, disabled, id, name, onChangeName, onSelectGroup, roles, userCount, visiblePageCount]
    )

    return (
        <SC.RecordItem checked={checked}>
            {rolesTitle.map(({ id, ...rest }) => (
                <CM.Item key={id} {...rest}>
                    {renderColumn(id)}
                </CM.Item>
            ))}
        </SC.RecordItem>
    )
}

export default RoleItem
