import { IconFont, Tooltip } from '@byecode/ui'
import type { Field, FilterableProtocol, FilterFormType, HttpResp, Sorter, TableColumns } from '@lighthouse/core'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import type { AppendParams, AppImportUser, ImportParams, InsertDirection } from '@lighthouse/shared'
import { ApplicationPreviewEnum, dataSourceNotSettingFieldTypes, useDataSourceContext } from '@lighthouse/shared'
import React, { useLayoutEffect, useRef, useState } from 'react'

import DataSourceAppender from '../DataSourceAppender'
import { DataSourceSetting } from '../DataSourceSetting'
import * as SC from './styles'

interface DataSourceSettingToolBarProps {
    searchVal: string
    envId: string
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    filter?: FilterFormType
    sorts?: Sorter[]
    onChangeSorter: (data?: Sorter[]) => void
    onChangeDisplay: (data?: TableColumns) => Promise<boolean>
    onChangeFilter: (data: FilterableProtocol['filter']) => void
    onChangeSearch: (val: string) => void
    onUpdateField: (fieldId: string, data: Field) => Promise<boolean>
    onDeleteField: (field: Field) => Promise<boolean>
    onCreateField: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onDataAppended?: (params: AppendParams) => Promise<void>
    onDataCreate?: (params: ImportParams) => Promise<boolean>
    onEnableApprovalState?: (enabled: boolean) => Promise<void>
    onVisibleJoinConfig: (val: boolean) => void
    onEditAggregator: () => void
    onImportAppUser: (file: File) => Promise<HttpResp<AppImportUser>>
}

export const DataSourceSettingToolBar: React.FC<DataSourceSettingToolBarProps> = ({
    searchVal,
    envId,
    dataSource,
    dataSourceList,
    filter,
    sorts,
    onChangeSorter,
    onChangeDisplay,
    onChangeFilter,
    onChangeSearch,
    onUpdateField,
    onDeleteField,
    onCreateField,
    onDataAppended,
    onDataCreate,
    onEnableApprovalState,
    onVisibleJoinConfig,
    onEditAggregator,
    onImportAppUser
}) => {
    const { appId, id, type, name, sync, enableApprovalState } = dataSource
    const [previewType, setPreviewType] = useState(ApplicationPreviewEnum.desktop)
    const headerRef = useRef<HTMLDivElement>(null)
    const { visibleJoinConfigure, onVisibleJoinConfigureChange } = useDataSourceContext()
    // const isUserDataSource = type === DataSourceType.userDataSource
    const isJoinDataSource = type === DataSourceType.joinDataSource
    const isCreateJoinDataSource = type !== DataSourceType.joinDataSource && type !== DataSourceType.aggregateDataSource
    const isAggregateDataSource = type === DataSourceType.aggregateDataSource

    useLayoutEffect(() => {
        const resizeHandler: ResizeObserverCallback = ([e]) => {
            const { width } = e.target.getBoundingClientRect()
            if (width >= 724) {
                setPreviewType(ApplicationPreviewEnum.desktop)
                return
            }
            setPreviewType(ApplicationPreviewEnum.mobile)
        }
        const observer = new ResizeObserver(resizeHandler)
        if (headerRef.current) {
            observer.observe(headerRef.current)
            return () => {
                observer.disconnect()
            }
        }
    }, [])

    return (
        <SC.DataSourceHeader ref={headerRef}>
            <SC.DataSourceTitle>{name}</SC.DataSourceTitle>
            <SC.Handle>
                <DataSourceSetting
                    searchVal={searchVal}
                    dataSourceInfo={dataSource}
                    dataSourceList={dataSourceList}
                    filter={filter}
                    sorts={sorts}
                    noSettingFields={dataSourceNotSettingFieldTypes}
                    previewType={previewType}
                    onChangeSorter={onChangeSorter}
                    onChangeDisplay={onChangeDisplay}
                    onChangeFilter={onChangeFilter}
                    onChangeSearch={onChangeSearch}
                    onUpdateField={onUpdateField}
                    onDeleteField={onDeleteField}
                    onCreateField={onCreateField}
                />
                {/* <AIQuerySetting
                        id={id}
                        key={id}
                        viewOptions={viewOptions}
                        onAiQuery={handleAiQuery}
                        onInitDataSource={handleInitDataSource}
                        prompt={prompt}
                    /> */}

                <DataSourceAppender
                    name={name}
                    appId={appId}
                    envId={envId}
                    dataSource={dataSource}
                    searchVal={searchVal}
                    enableApprovalState={enableApprovalState}
                    // previewType={previewType}
                    onImportAppUser={onImportAppUser}
                    onAppend={onDataAppended}
                    onCreate={onDataCreate}
                    onEnableApprovalState={onEnableApprovalState}
                />
                {isCreateJoinDataSource && (
                    <SC.JoinCreateButton
                        size="sm"
                        icon={<IconFont type="RelationQuery" fill="var(--color-green-500)" />}
                        onClick={() => onVisibleJoinConfig(true)}
                    >
                        {previewType === ApplicationPreviewEnum.desktop && '创建连接'}
                    </SC.JoinCreateButton>
                )}
                {isAggregateDataSource && (
                    <SC.EditButton
                        size="sm"
                        disabled={sync}
                        icon={<IconFont type="SettingsStylesA" fill="var(--color-gray-400)" />}
                        onClick={onEditAggregator}
                    >
                        {previewType === ApplicationPreviewEnum.desktop && '聚合配置'}
                    </SC.EditButton>
                )}
                {isJoinDataSource && (
                    <Tooltip disabled={!sync} title="同步的关联表不可编辑">
                        <SC.EditButton
                            size="sm"
                            disabled={sync}
                            type="default"
                            icon={<IconFont type="LeftDrawer" />}
                            onClick={() => onVisibleJoinConfigureChange?.(!visibleJoinConfigure)}
                        >
                            {previewType === ApplicationPreviewEnum.desktop && '连接配置'}
                        </SC.EditButton>
                    </Tooltip>
                )}
            </SC.Handle>
        </SC.DataSourceHeader>
    )
}
