import type { Field } from '@lighthouse/core'
import { findParentCustomViewBlock, getViewOptions, useAtomData } from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageBlocksAtom, pageNodesAtom, selectedBlockAtomFamily } from '@/atoms/page/state'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'

export function useVariableCustomDataSource() {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const [rootPageId = '', pageId = '', stackId = ''] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.rootPageId, s?.pageId, s?.stackId], [])
    )
    const blockId = useAtomData(selectedBlockAtomFamily({ rootPageId, pageId, stackId }))?.id ?? ''
    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] || [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] || [], [pageId])
    )
    const customViewDsId = useMemo(() => findParentCustomViewBlock(blockId, blocks)(nodes)?.config.pointer || '', [blockId, blocks, nodes])

    const customViewDataSource = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: customViewDsId }))

    return { customViewDataSource }
}

export function useVariableCustomViewOption(filterField?: (field: Field) => boolean) {
    const { customViewDataSource } = useVariableCustomDataSource()
    const customViewOption = useMemo(
        () =>
            customViewDataSource &&
            getViewOptions({ dataSource: customViewDataSource, viewType: 'custom', validateFieldType: filterField }),
        [customViewDataSource, filterField]
    )

    return { customViewOption, customViewDataSource }
}
