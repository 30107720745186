import type { DataSourceBase, Environment } from '@lighthouse/core'
import { getBrand, spaceGradeCodeMap, useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { useCallback, useMemo, useState } from 'react'
import useSWR from 'swr'

import { CURRENT_PAGE, HOME_PAGE } from '@/atoms/application/constants'
import { currentAppOfSpaceIdAtom, sharePageAtom } from '@/atoms/application/state'
import { dataSourceDepartmentListAtom, dataSourceRoleListAtom, dataSourceUserListAtom } from '@/atoms/dataSource/state'
import { homePageIdAtom, lastPageOfStackAtom } from '@/atoms/page/state'
import type { TemplateList } from '@/containers/Template/types'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import * as srv from '@/services'
import type { GetDsMetaPayload, GetWorkflowRunLogsPayload } from '@/services/types'

/**
 * 多个组件内共享同一个局部数据请求方法
 * 获取空间成员列表
 * @export
 * @param {string} spaceId
 * @return {*}
 */
export function useSpaceUserList(spaceId: string, keyword?: string) {
    const { data, error, isLoading, mutate } = useSWR(`/spaceGetUsers/get-${spaceId}`, () => srv.getSpaceUsers(spaceId, keyword), {
        revalidateOnFocus: false
    })

    return { data, error, isLoading, update: mutate }
}

// /**
//  * 获取空间邀请链接
//  * @export
//  * @param {string} spaceId
//  * @return {*}
//  */
// export function useInviteLink(spaceId: string) {
//     const { data, error, isLoading, mutate } = useSWR(`/createShareLink/get-${spaceId}`, () => srv.createShareLink(spaceId), {
//         revalidateOnFocus: false
//     })

//     return { data, error, isLoading, update: mutate }
// }

/**
 * 获取团队列表
 * @export
 * @param {string} spaceId
 * @return {*}
 */
export function useTeams(spaceId: string, keyword?: string) {
    const { data, error, isLoading, mutate } = useSWR(
        `/getTeams/get-${spaceId}`,
        () => (keyword ? srv.getTeams(spaceId, keyword) : srv.getTreeTeams(spaceId)),
        {
            revalidateOnFocus: false
        }
    )
    return { data, error, isLoading, update: mutate }
}

/**
 * 获取团队基础信息
 * @export
 * @param {string} spaceId
 * @return {*}
 */
export function useBaseTeam(teamId: string) {
    const { data, error, isLoading, mutate } = useSWR(`/getBaseTeam/get-${teamId}`, () => srv.getBaseTeam(teamId), {
        revalidateOnFocus: false
    })
    return { data, error, isLoading, update: mutate }
}

/**
 * 获取团队下成员列表
 * @export
 * @param {string} teamId
 * @return {*}
 */
export function useTeamUsers(teamId: string) {
    const { data, error, isLoading, mutate } = useSWR(`/getTeamUsers/get-${teamId}`, () => srv.getTeamUsers(teamId), {
        revalidateOnFocus: false,
        keepPreviousData: true
    })
    return { data, error, isLoading, update: mutate }
}

/**
 * 获取团队下成员列表
 * @export
 * @param {string} teamId
 * @return {*}
 */
export function useChildrenTeam(teamId: string) {
    const { data, error, isLoading, mutate } = useSWR(`/getChildrenTeam/get-${teamId}`, () => srv.getChildrenTeam(teamId), {
        revalidateOnFocus: false
    })
    return { data, error, isLoading, update: mutate }
}

/**
 * 获取团队下成员列表
 * @export
 * @param {string} teamId
 * @return {*}
 */
export function useParentTeam(teamId: string) {
    const { data, error, isLoading, mutate } = useSWR(`/getParentTeam/get-${teamId}`, () => srv.getParentTeam(teamId), {
        revalidateOnFocus: false
    })
    return { data, error, isLoading, update: mutate }
}

/**
 * @description 获取模板列表
 * @export
 * @return {*}
 */
export function useTemplateList() {
    const { data, mutate } = useSWR<TemplateList>(
        `/app/getTemplateList/`,
        async () => {
            const data = await srv.getTemplateList()
            return data.filter(kind => kind.list.length)
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}

/**
 * @description 获取数据源列表
 * @export
 * @return {*}
 */
export function useEnvList(appId: string) {
    const { data, mutate } = useSWR<Environment[]>(`${appId}-listDs`, () => (appId ? srv.getAppListEnv(appId) : Promise.resolve([])), {
        revalidateOnFocus: false,
        keepPreviousData: true
    })
    return { data, update: mutate }
}

/**
 * @description 获取数据源列表
 * @export
 * @return {*}
 */
export function useDataSourceList(appId: string, envId: string) {
    const { data, mutate } = useSWR<DataSourceBase[]>(
        `${appId}-${envId}-listDs`,
        () => (appId && envId ? srv.listDs(appId, envId) : Promise.resolve([])),
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}
/**
 * @description 获取数据源配置
 * @export
 * @return {*}
 */
export function useSWRDataSourceMeta(params: GetDsMetaPayload) {
    const { envId, dsId } = params
    const { data, mutate } = useSWR(
        `/app/getDsMeta/${envId} - ${dsId}`,
        () => {
            if (!dsId || !envId) {
                return
            }
            return srv.getDsMeta({ envId, dsId })
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}

/**
 * @description 获取数据源用户列表
 * @export
 * @return {*}
 */
export function useDataSourceUsers() {
    const data = useAtomData(dataSourceUserListAtom)
    // const { run: update } = useAtomAction(fetchDataSourceUserAtom)
    return { data }
}

/**
 * @description 获取数据源角色列表
 * @export
 * @return {*}
 */
export function useDataSourceRoles() {
    return useAtomData(dataSourceRoleListAtom)
}

/**
 * @description 获取数据源部门列表
 * @export
 * @return {*}
 */

export function useDataSourceDepartments() {
    return useAtomValue(dataSourceDepartmentListAtom)
}

/**
 * @description 获取第三方平台所有账号
 * @export
 * @return {*}
 */
export function useOtherPlatformList() {
    const { data, mutate } = useSWR(`/app/otherPlatforms`, () => srv.getOtherPlatforms(), {
        revalidateOnFocus: false,
        keepPreviousData: true
    })
    return { data, update: mutate }
}

/**
 * @description 获取空间配额及空间信息
 * @export
 * @return {*}
 */
export function useSpaceQuota() {
    const appOfSpaceId = useAtomData(currentAppOfSpaceIdAtom)
    const spaceId = useCurrentSpaceID()
    const currentSpaceId = spaceId || appOfSpaceId
    const { data, mutate } = useSWR(
        `/space/getUsage-${currentSpaceId}`,
        async () => {
            if (!currentSpaceId) {
                return
            }
            const res = await srv.getUsage(currentSpaceId)
            return { ...res, spaceId: currentSpaceId, currentVersionCode: spaceGradeCodeMap[res.currentVersion] }
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}

/**
 * @description 获取所有版本的空间
 * @returns  space version list
 */
export function useSpaceAllVersion() {
    const spaceId = useCurrentSpaceID()
    const { data, mutate } = useSWR(`/space/getVersionInfo-${spaceId}`, () => srv.getVersionInfo(spaceId, getBrand()), {
        revalidateOnFocus: false,
        keepPreviousData: true
    })
    return { data, update: mutate }
}

/**
 * @description 获取应用分析数据
 * @returns
 */
export function useGetAnalyzeConfigure() {
    return useSWR('fetchAnalysisConfigure', () => srv.fetchAnalysisConfigure(), {
        revalidateOnFocus: false,
        keepPreviousData: true
    })
}

export function useHomePageShare() {
    const [pageId = '', stackId = '', dsId, recordId, rootPageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(p => [p?.pageId, p?.stackId, p?.dsId, p?.recordId, p?.rootPageId], [])
    )
    const sharePage = useAtomData(sharePageAtom)
    const homePage = useAtomData(homePageIdAtom)
    const { id: homePageId = '' } = homePage ?? {}
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId, rootPageId })
    const [usedPageId, key] = useMemo(() => {
        if (sharePage === HOME_PAGE) {
            return [homePageId, homePageId]
        }
        if (sharePage === CURRENT_PAGE) {
            return [pageId, `${pageId}-${dsId}-${recordId}`]
        }
        return [sharePage, sharePage]
    }, [dsId, homePageId, pageId, recordId, sharePage])

    const { data, mutate } = useSWR(
        `/app/getWechatShareData-${key}`,
        () => {
            return usedPageId
                ? srv.getWechatShareData({ pageId: usedPageId, dsId, currentRecordId: curr.recordId, parentRecordId: prev.recordId })
                : undefined
        },
        {
            revalidateOnFocus: false,
            keepPreviousData: true
        }
    )
    return { data, update: mutate }
}

export function useFlowLogsWithPagination(params: Pick<GetWorkflowRunLogsPayload, 'workflowId' | 'state'>) {
    const { workflowId, state } = params
    const workflowState = state === 'all' ? undefined : state
    const [currentPage, setCurrentPage] = useState(1)

    const pageSize = 50

    const { data } = useSWR(`flowRunLogs/${workflowId}/${workflowState}/${currentPage}`, async () => {
        const res = await srv.fetchFlowRunLog({ workflowId, state: workflowState, currentPage, pageSize })
        return res.content
    })
    const { list = [], total = 0 } = data || {}

    const totalPage = Math.ceil(total / pageSize)

    return {
        workflows: list,
        total,
        totalPage,
        currentPage,
        setCurrentPage
    }
}

/**
 * 获取阿里云短信配置信息
 *
 * @export
 * @param {string} aliyunAccountId
 * @returns {*}
 */
export function useGetAliyunMessageInfo(aliyunAccountId: string | undefined) {
    return useSWR(
        aliyunAccountId,
        (id: string | undefined) => {
            if (!id) {
                return
            }

            return srv.getAliyunMessageInfo({ id })
        },
        {
            revalidateOnFocus: false
        }
    )
}

/**
 * 获取微信消息模版信息
 *
 * @export
 * @param {string} aliyunAccountId
 * @returns {*}
 */
export function useGetWxMessageInfo(appId: string) {
    return useSWR(
        appId,
        () => {
            return srv.getWxMessageInfo()
        },
        {
            // revalidateOnFocus: false,
        }
    )
}
