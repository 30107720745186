import * as React from 'react'
import styled, { css } from 'styled-components'

import { blankTemplate } from '../constant'
import TemplateImage from '../TemplateImage'
import TemplateTitle from '../TemplateTitle'
import type { Template } from '../types'

interface TemplateItemProps {
    data: Template
    width?: string | number
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const SCXContainer = styled.div<{ isNoDispark?: boolean; width?: string | number }>`
    position: relative;
    padding: 0;
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
    overflow: hidden;

    ${({ isNoDispark }) =>
        isNoDispark &&
        css`
            ::before {
                width: 67px;
                height: 21px;
                position: absolute;
                right: 9px;
                top: 9px;
                line-height: 21px;
                text-align: center;
                content: '敬请等待';
                border-radius: 4px;
                font-size: var(--font-size-sm);
                font-style: normal;
                font-weight: 300;
                color: var(--color-white);
                background-color: var(--color-gray-400);
                z-index: 2;
            }
        `}
`

const TemplateItem: React.FunctionComponent<TemplateItemProps> = ({ data, width, onClick }) => {
    const isEnableEvent = data.isNoDispark
    return (
        // xs={12} sm={6} md={4} lg={3} xl={2.4}
        <SCXContainer width={width} onClick={e => !isEnableEvent && onClick?.(e)} isNoDispark={data.isNoDispark}>
            <TemplateImage
                isBlankTemplate={data.isBlankTemplate}
                id={data.id}
                ratio={
                    blankTemplate.some(item =>
                        item.list
                            .map(item => item.id)
                            .join(',')
                            .includes(data.id)
                    )
                        ? 9 / 16
                        : 4 / 3
                }
                backgroundColor={data.isBlankTemplate ? data.iconBackgroundColor : `${data.iconBackgroundColor}20`}
                value={data.primaryImage}
            />
            <TemplateTitle
                data={data}
                logoSize={36}
                iconSize={20}
                describeSize={12}
                gap={6}
                styles={{
                    root: {
                        marginTop: 16
                        // padding: '0 8px'
                    }
                }}
            />
        </SCXContainer>
    )
}

export default TemplateItem
