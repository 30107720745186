import { Input, tinyButtons } from '@byecode/ui'
import React from 'react'
import styled, { css } from 'styled-components'

import * as CM from '../styles'
import type { TemplateList } from '../types'

interface TemplateLeftPanelProps {
    searchWord: string
    pointIndex: number
    list: TemplateList

    onChangeSearch: (value: string) => void
    onChangePoint: (index: number) => void
}

const SCXContainer = styled.div`
    width: 220px;
    height: 100%;
    padding: 16px 8px;
    background-color: var(--color-gray-50);
`

const SCXKindItem = styled.div<{ active?: boolean }>`
    padding: 0 12px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    border-radius: 8px;
    color: var(--color-black);

    ${({ active }) =>
        active &&
        css`
            background-color: var(--color-theme-2);
            color: var(--color-theme-6);
        `}
`

const SCXKindList = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 16px;
    overflow-y: auto;
    ${tinyButtons}
`

const SCXKindTitle = styled.div`
    padding: 6px 12px;
    margin-bottom: 8px;
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    font-weight: 400;
`

const TemplateLeftPanel: React.FunctionComponent<TemplateLeftPanelProps> = ({
    searchWord,
    pointIndex,
    list,
    onChangeSearch,
    onChangePoint
}) => {
    return (
        <SCXContainer>
            <Input
                placeholder="搜索"
                value={searchWord}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch(e.target.value)}
                suffix={searchWord ? <CM.Icon type="Close" isAction onClick={() => onChangeSearch('')} /> : <CM.Icon type="SearchLine" />}
            />
            <SCXKindList>

                {list.map(({ categoryId, name }, index) => (
                    <SCXKindItem key={categoryId} active={index === pointIndex} onClick={() => onChangePoint(index)}>
                        <CM.Text color="inherit">{name}</CM.Text>
                    </SCXKindItem>
                ))}
            </SCXKindList>
        </SCXContainer>
    )
}

export default TemplateLeftPanel
