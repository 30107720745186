import { Button, Empty, IconFont } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import { DataSourceContentProvider, findParentFormBlock, useAtomAction, useAtomData } from '@lighthouse/shared'
import { animated, useSpring } from '@react-spring/web'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { dataDrawerStateAtom } from '@/atoms/application/state'
import { pageBlocksAtom, pageNodesAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { ChartDataSource } from './ChartDataSource'
import { FormDataSource } from './FormDataSource'
import { RecordDataSource } from './RecordDataSource'
import { ViewDataSource } from './ViewDataSource'

const SCxContainer = styled(animated.div)`
    position: absolute;
    bottom: 12px;
    left: 16px;
    right: 16px;
    z-index: 200;

    height: 355px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);

    border-radius: 17px;
    box-shadow: 0px 4px 12px 0px #1018281a;
`

const SCxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    height: 100%;
    position: relative;
`

const SCxHeader = styled.div`
    position: absolute;
    top: -16px;
    width: 134px;
    height: 18px;
    left: 50%;
    z-index: 200;
    margin-left: -67px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const SCxArrowIcon = styled(IconFont)`
    position: absolute;
    top: 5px;
`

const SCxFooter = styled.div`
    height: 36px;
    margin: 20px 0 12px 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxLabel = styled.div`
    font-size: 14px;
    color: var(--color-gray-400);
`

const SCxLink = styled(Button)``

interface PageDataDrawerProps {
    blockData: BlockAbstract | null
    pageId: string
    pageDsId?: string
    isRecordPage?: boolean
    pageRecordId?: string
    parentRecordId?: string
}

export const PageDataDrawer: React.FC<PageDataDrawerProps> = ({
    blockData,
    pageId,
    pageDsId,
    isRecordPage,
    pageRecordId,
    parentRecordId
}) => {
    const dataDrawerVisible = useAtomData(dataDrawerStateAtom)
    const { run: onVisibleDataDrawer } = useAtomAction(dataDrawerStateAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const containerRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )

    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )

    const parentForm = useMemo(() => findParentFormBlock(blockData?.id || '', blocks)(nodes), [blocks, nodes, blockData?.id])

    const [springs, api] = useSpring(() => ({
        from: { y: 355 },
        to: { y: 0 },
        config: {
            duration: 300
        }
    }))

    const handleHidden = useCallback(async () => {
        const isEnd = api.start({
            from: {
                y: 0
            },
            to: {
                y: 379
            },
            config: {
                duration: 300
            }
        })
        const resultList = await Promise.all(isEnd)
        const result = resultList.every(item => item.finished)
        if (result) {
            onVisibleDataDrawer?.(false)
        }
    }, [api, onVisibleDataDrawer])

    const dsId = useMemo(() => {
        if (!blockData) {
            return pageDsId
        }
        if (blockData.type === BlockType.chart || blockData.type === BlockType.view || blockData.type === BlockType.formContainer) {
            const { pointer } = blockData.config
            return pointer
        }
        return parentForm?.config?.pointer || pageDsId
    }, [blockData, pageDsId, parentForm?.config?.pointer])

    const dataSource = useDataSource(appId, envId, dsId || '')

    const location = useLocation()
    // const navigate = useNavigate()

    // const handleLinkDataSource = useCallback(
    //     (dsId: string) => {
    //         onVisibleDataDrawer?.(false)
    //         navigate({ pathname: `/${currentAppId}/dataSource/${dsId}` })
    //     },
    //     [currentAppId, navigate, onVisibleDataDrawer]
    // )

    useEffect(() => {
        if (!location.pathname.includes('/page') && dataDrawerVisible) {
            onVisibleDataDrawer?.(false)
        }
    }, [location.pathname, onVisibleDataDrawer, dataDrawerVisible])

    const content = useMemo(() => {
        if (!dataSource) {
            return (
                <Empty
                    styles={{ title: { color: 'var(--color-gray-400)!important', fontWeight: 'normal!important' } }}
                    icon={<IconFont type="SearchData" fill="var(--color-gray-400)" size={48} />}
                    title="该对象未绑定任何数据"
                />
            )
        }
        if (blockData?.type === 'view') {
            return <ViewDataSource pageId={pageId} pageRecordId={pageRecordId} blockData={blockData} dataSource={dataSource} />
        }
        if (blockData?.type === 'chart') {
            return (
                <ChartDataSource
                    pageId={pageId}
                    blockData={blockData}
                    dataSource={dataSource}
                    pageRecordId={pageRecordId}
                    parentRecordId={parentRecordId}
                />
            )
        }
        // const dsId = parentForm?.config?.pointer || pageDsId
        if (isRecordPage) {
            return <RecordDataSource dataSource={dataSource} pageRecordId={pageRecordId} />
        }

        return <FormDataSource dataSource={dataSource} />
    }, [blockData, dataSource, isRecordPage, pageId, pageRecordId, parentRecordId])

    return (
        <SCxContainer data-ignore-click-away ref={containerRef} style={{ ...springs }}>
            <SCxWrapper ref={wrapperRef}>
                <SCxHeader onClick={handleHidden}>
                    <svg width="136" height="20" viewBox="0 0 136 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="16" width="134.5" height="4" fill="white" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.18359 16.4722C11.4814 13.1075 17.5025 10.5007 27.4175 3.66904C29.437 2.27752 31.8147 1.48215 34.267 1.44884L66.3156 1.01358V1H67.3156H101.064C102.985 1 104.879 1.45914 106.579 2.35288C109.951 4.12508 113.2 5.87795 116.259 7.52839C124.475 11.9614 131.322 15.6555 135.5 17L66.3156 16.4722H1.18359Z"
                            fill="white"
                        />
                        <path
                            d="M1 16.4722C11.2763 13.1146 17.5629 10.0996 27.4908 3.22375C29.5527 1.79569 31.9921 1 34.5002 1H102.129C104.637 1 107.077 1.79569 109.139 3.22375C119.067 10.0996 125.353 13.1146 135.629 16.4722"
                            stroke="#E4E7EC"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <SCxArrowIcon size={16} fill="var(--color-gray-400" type="ArrowDownDouble" />
                </SCxHeader>
                <DataSourceContentProvider value={{ el: wrapperRef.current }}>{content}</DataSourceContentProvider>
            </SCxWrapper>
        </SCxContainer>
    )
}
