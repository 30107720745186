// 此文用于定于前后端数据转换过程中需要的一些类型
import type {
    ActivityProtocol,
    ApplicationSettingTheme,
    ApplicationSettingThemeNavBarMode,
    AppUser,
    AppUserStatus,
    BlockAbstract,
    BlockID,
    BlockType,
    ChartLikeProtocol,
    DataSourceAbstract,
    DataSourceIdentity,
    Field,
    FieldInputConfigProtocol,
    FieldInputValue,
    FieldValue,
    FilterCommonCondition,
    FilterFormType,
    FormRule,
    JoinSetting,
    NavigationShowMode,
    PageAbstract,
    PageNode,
    PageOpenType,
    PaginationProtocol,
    QuotaProtocols,
    RecordLikeProtocol,
    SelectedMode,
    Sorter,
    SpaceVersion,
    TypeInstanceMap,
    UserProtocol,
    ViewOptions
} from '@lighthouse/core'
import type {
    AliyunSmsAccountItem,
    AppRole,
    DingTalkRobotItem,
    EmailAccountItem,
    EnvDiffs,
    FlowEdge,
    FlowNode,
    IClickAccountItem,
    RunStatus as FlowRunStatus,
    StripeAccountItem,
    UsedWorkflow, WeChatPayAccountItem,
    WeChatPublicAccountItem,
    WeChatWebsiteAccountItem,
    WeComInternalApp,
    Workflow,
    WorkflowType,
    WxRootItem
} from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import type { MakeADT } from 'ts-adt/MakeADT'

/**
 * 开始   接口类型定义（新）
 *
 */

// --------------------------------------------------------------------
// payload
// --------------------------------------------------------------------
export type GetRecordPayload = {
    appId: string
    envId: string
    dsId: string
    recordId: string
}

export type CreateRecordPayload = DataSourceIdentity & {
    id: string
    content?: RecordLikeProtocol['content']
    beforeId?: string
}

export type GetRecordByFilterPayload = {
    appId: string
    envId: string
    dsId: string
    filter?: FilterFormType
    sorts?: Sorter[]
    currentRecordId?: string
    parentRecordId?: string
}

export type UpdateRecordPayload = DataSourceIdentity & {
    recordId: string
    fields: { fieldId: string; value: FieldInputValue | undefined }[]
}

export type GetNodePreviewDataPayload = DataSourceIdentity & { nodeId: string }

export type SubmitFormPayload = {
    appId: string
    envId: string
    dsId: string
    pageId: string
    blockId: string
    currentRecordId?: string
    parentRecordId?: string
    code?: string
    validator?: {
        rules?: FormRule[]
        codeValidator?: Record<string, string>
    }
    fields: {
        fieldId: string
        value: FieldInputValue
        isCheckRepeat?: boolean
    }[]
    subFormRecord?: { subFormId: string; subFormContent: { recordId: string; columns: { columnId: string; value: FieldInputValue }[] }[] }[]
}

export type getListSyncEnvDsPayload = {
    envId: string
    dsId: string
}

export type SwitchSyncDsPayload = {
    envId: string
    dsId: string
    switchEnvId: string
}

export type GetBeUsedSubProcessPayload = {
    workflowId: string
}

// --------------------------------------------------------------------
// res
// --------------------------------------------------------------------
export type DataSourceRes = Omit<DataSourceAbstract, 'appId' | 'envId'>

export type RecordRes = Omit<RecordLikeProtocol, 'appId' | 'envId' | 'dsId'>

export type DataSourceAndRecordsRes = {
    datasource: DataSourceRes
    records: RecordRes[]
}

/** 获取指定行数据 */
export type PageRecordRes = {
    datasource: DataSourceRes
    record: RecordRes
}

export type ViewDataRes = {
    datasource: DataSourceRes
    records: RecordRes[]
    config: {
        view: ViewOptions
        relatedView: ViewOptions
    }
}

export type GetListSyncEnvDsRes = {
    envName: string
    envId: string
    exist: boolean
}[]

// --------------------------------------------------------------------
// result
// --------------------------------------------------------------------
/** 获取指定行数据 */
export type PageRecordResult = {
    datasource: DataSourceAbstract
    record: RecordLikeProtocol
}

export type DataSourceAndRecordsResult = {
    datasource: DataSourceAbstract
    records: RecordLikeProtocol[]
}

export type ViewDataResult = {
    datasource: DataSourceAbstract
    records: RecordLikeProtocol[]
    config: {
        view: ViewOptions
        relatedView: ViewOptions
    }
}


export type AppsByEnvResult = {
    id: string // 版本id
    serial: string // 版本序号
    name: string // 版本名称
}[]

export type LatestActivityResult = {
    content: string
    icon: string
    notificationId: string
    url: string
}

export type ActivityListResult = ActivityProtocol[]

export type PromoInviteResult = {
    users: UserProtocol[]
    invitationCount: number
}

export type SpacePlansResult = {
    originalPrice: number
    realPrice: number
    spacePlan: SpaceVersion
}[]

export type UsersAndRolesResult = {
    users: AppUser[]
    roles: AppRole[]
}

export type UsedWorkflowResult = UsedWorkflow[]

/**
 * 结束   接口类型定义（新）
 *
 */
export interface TokenResult {
    headerName: string
    parameterName: string
    token: string
}

export interface Version {
    versionId: string
}

export interface WechatResult {
    mark: string
    url: string
}

export interface ApplicationIdentity {
    appId: string
}

export interface EnvironmentIdentity {
    envId: string
}

export type GetDsMetaPayload = Omit<DataSourceIdentity, 'appId'>

export interface GetDsListMetaPayload extends EnvironmentIdentity {
    dsIds: string[]
}

export interface CreateApplicationPayload {
    spaceId: string
    name: string
    icon: string
    domainPrefix: string
    theme: ApplicationSettingTheme
    themeMode: ApplicationSettingThemeNavBarMode
    navigationShowMode: NavigationShowMode
}

export type CreateDataSourcePayload = {
    envId: string
    type: number
}

export type DuplicateAndInitDsPayload = Omit<DataSourceIdentity, 'appId'>

export type CreateSyncDsPayload = {
    envId: string
    syncAppId: string
    syncEnvId: string
    syncDsId: string
}

export type CreateJoinDsPayload = {
    envId: string
    primaryDsId: string
    config: JoinSetting
}

export type CreateDataSourceResult = {
    name: string
    dsId: string
}

export type DeleteDataSourcePayload = Omit<DataSourceIdentity, 'appId'>

export type GetDataSourcePayload = {
    appId: string
    envId: string
    dsId: string
    pagination: PaginationProtocol['pagination']
    search?: string
    filter?: FilterFormType
    linkFilter?: FilterFormType
    sorts?: Sorter[]
    currentRecordId?: string
    parentRecordId?: string
    disabledFilter?: FilterFormType
    recordIds?: string[]
    abort?: AbortController
}

export type getDsCountPayload = {
    envId: string
    dsId: string
    search?: string
    filter?: FilterFormType
    linkFilter?: FilterFormType
    currentRecordId?: string
    parentRecordId?: string
    recordIds?: string[]
    abort?: AbortController
}

export type MoveDsPayload = {
    envId: string
    id: string
    before: string
    after: string
}

export type GroupRecordCountPayload = {
    appId: string
    viewId: string
    fieldId: string
    quickFilters?: FilterCommonCondition[]
    linkFilter?: FilterFormType
    search?: string
    currentRecordId?: string
    parentRecordId?: string
    abort?: AbortController
}

export type ChartData = {
    datasource: DataSourceAbstract
    chart: ChartLikeProtocol
}

export type ViewSettingParams = {
    quickFilters?: FilterCommonCondition[]
    sorts?: Sorter[]
    linkFilter?: FilterFormType
}

export type RelationRecords = Record<BlockID, Record<BlockID, RecordLikeProtocol>>

export type RelationDataSources = Record<string, DataSourceAbstract>

export type UpdateCellPayload = Omit<DataSourceIdentity, 'appId'> & {
    id: string
    fieldId: string
    content: FieldValue
}

export type CreateFieldResult = {
    innerType: TypeInstanceMap
    fieldId: string
}

export type UpdateFieldPayload = Omit<DataSourceIdentity, 'appId'> & {
    id: string
    name?: string
    config?: AnyObject
    type?: Field['type']
}

export type DeleteFieldPayload = Omit<DataSourceIdentity, 'appId'> & {
    id: string
}

export type CreateFieldPayload = Omit<DataSourceIdentity, 'appId'> & {
    type: string
    name?: string
    config?: AnyObject
    beforeId?: string
}

export type UpdateDataSourcePayload = Partial<DataSourceAbstract> &
    EnvironmentIdentity & {
        id: string
    }

export type DeleteRecordPayload = Omit<DataSourceIdentity, 'appId'> & {
    search?: string
    mode?: SelectedMode
    recordIds: string[]
    filter?: FilterFormType
}

export type EnableApprovalStatePayload = {
    envId: string
    dsId: string
}

export type GetDsGraphViewPayload = {
    blockId: string
    currentRecordId?: string
    parentRecordId?: string
    filter?: FilterFormType
}

export interface UpdatePagePayload {
    id: string
    name?: string
    icon?: string
    open?: PageOpenType
    content?: PageNode[]
    sorts?: Sorter[]
    filter?: FilterFormType
    isHome?: boolean
    roleIds?: string[]
}

export type CreateBlockPayload = { pageId: string; blocks: BlockAbstract[] }
/** 普通页面内容返回 */
export type PageContentRes = Omit<PageAbstract, 'filter' | 'sorts'> & {
    pageConfig?: {
        filter: FilterFormType
        sorts: Sorter[]
    }
    blockVos: (Omit<BlockAbstract, 'config'> & {
        config: Record<BlockType, AnyObject>
    })[]
    nodes: PageNode[]
    /** 除了普通页面，其他页面都会有该字段，存放的是筛选的数据源信息 */
    recordPage?: {
        datasource: DataSourceAbstract
        records: RecordLikeProtocol[]
    }
}

/** 表单容器内容返回 */
export type FormContentRes = {
    id: string
    title: string
    type: 'field'
    config: {
        field: FieldInputConfigProtocol
    }
}

/**
 * 工作流
 * ------------------------------ start
 * */

export type WorkflowCreatePayload = Pick<Workflow, 'type' | 'name' | 'description'> & {
    nodes?: FlowNode[]
    edges?: FlowEdge[]
}

export type WorkflowResult = Workflow[]

export type RunStatus = 'all' | FlowRunStatus

export type WorkflowRunLog = {
    id: string
    versionId: string
    workflowType: WorkflowType
    status: RunStatus
    name: string
    operator: string
    executionTime: number
    workflowInstanceId: string
}

export type GetWorkflowRunLogsPayload = {
    workflowId?: string
    state?: RunStatus
    pageSize?: number
    currentPage?: number
}

export type WorkflowRunLogs = WorkflowRunLog[]

export type WorkflowRunLogRes = {
    list: WorkflowRunLogs
    currentPage: number
    pageSize: number
    total: number
}

export type UpdateWorkflowPayload = {
    id: string
    name: string
    description: string
    unConfigured: boolean
    nodes: FlowNode[]
    edges: FlowEdge[]
}

/**
 * 工作流
 * ------------------------------ end
 */



export type ListFormBlock = {
    id: string
    title: string
    pageName: string
    pageId: string
}

export type getBlockParams = {
    pageId: string
    moduleId: string
}

export type BaseOtherPlatformItem = {
    id: string
    name: string
    createdTime: number
}

export type OtherPlatformItem = MakeADT<
    'type',
    {
        WE_COM_INTERNAL_APP: { config: WeComInternalApp; type: 'WE_COM_INTERNAL_APP' }
        ALIYUN_SMS: { config: AliyunSmsAccountItem; type: 'ALIYUN_SMS' }
        DING_TALK_ROBOT: { config: DingTalkRobotItem; type: 'DING_TALK_ROBOT' }
        ICLICK: { config: IClickAccountItem; type: 'ICLICK' }
        NET_EASE_126_MAIL: { config: EmailAccountItem; type: 'NET_EASE_126_MAIL' }
        WECHAT_OFFICIAL_ACCOUNT: { config: WeChatPublicAccountItem; type: 'WECHAT_OFFICIAL_ACCOUNT' }
        WECHAT_PAY: { config: WeChatPayAccountItem; type: 'WECHAT_PAY' }
        WECHAT_WEBSITE: { config: WeChatWebsiteAccountItem; type: 'WECHAT_WEBSITE' }
        WE_COM_MAIL: { config: EmailAccountItem; type: 'WE_COM_MAIL' }
        WE_COM_ROBOT: { config: WxRootItem; type: 'WE_COM_ROBOT' }
        STRIPE: { config: StripeAccountItem; type: 'STRIPE' }
    }
>

export type AllOtherPlatform = (OtherPlatformItem & BaseOtherPlatformItem)[]

export type AppAccountType =
    | 'WE_COM_ROBOT'
    | 'DING_TALK_ROBOT'
    | 'WE_COM_MAIL'
    | 'ICLICK'
    | 'ALIYUN_SMS'
    | 'NET_EASE_126_MAIL'
    | 'WECHAT_OFFICIAL_ACCOUNT'
    | 'WECHAT_WEBSITE'
    | 'WECHAT_PAY'
    | 'WE_COM_INTERNAL_APP'
    | 'STRIPE'

export type SpaceQuota = {
    spaceName: string
    currentVersion: SpaceVersion
    currentVersionCode?: number
    expiredAt: number
    // numberOfApps: QuotaProtocols
    // numberOfWorkflowExecutions: QuotaProtocols
    // numberOfAiInvocation: QuotaProtocols
    fileStorage: QuotaProtocols
    numberOfRecords: QuotaProtocols
    numberOfUsers: QuotaProtocols
    numberOfDomain: QuotaProtocols
    numberOfUv: QuotaProtocols
    // 更新次数
    numberOfUpdates: QuotaProtocols
    // 数据表查询算力
    numberOfCu: QuotaProtocols
    // 可发布应用数量
    numberOfPublishedApps: QuotaProtocols
    // 用户阿里云视频用量
    traffic: QuotaProtocols
    // api执行次数
    apiInvoke: QuotaProtocols
    sms: QuotaProtocols
}

export type SpaceVersionInfo = {
    /**
     * 空间中可发布的应用数量
     */
    publishedAppNumLimit?: number
    /**
     * 绑定自有域名的应用数量
     */
    domainNumLimit?: number
    /**
     * 费用
     */
    price?: number
    /**
     * 空间中所有应用的数据表中记录的数据统计，包括空白记录
     */
    recordsLimit?: number
    /**
     * 是否包含团队训练配额
     */
    teamTraining?: boolean
    /**
     * uv配额
     */
    uvLimit?: number
    /**
     * 空间内所有应用内上传附件的大小统计(单位:MB)
     */
    storageLimit?: number
    /**
     * 空间中所有应用的用户数量
     */
    userNumLimit?: number
    /**
     * 当前付费的版本, 有以下枚举值 ULTIMATE：旗舰版 PROFESSIONAL:专业版 ENTERPRISE : 企业版  STANDARD：创业版 FREE：免费版
     */
    version: SpaceVersion
    /**
     * 是否显示byecode广告位
     * */
    byecodeBranding?: boolean
    /**
     * 是否包含客户经理服务
     */
    customerManagerServices: boolean
    /**
     * 更新记录次数
     */
    updatesLimit?: number
    /**
     * 数据表查询算力
     */
    cuLimit?: number
    /**
     * 视频流量
     */
    traffic?: number
}

export type AppUserPayload = {
    pageSize?: number
    currentPage?: number
    search?: string
    state?: AppUserStatus
}

export type WechatShareResult = {
    title: string
    subtitle: string
    icon: string
}

export type AddIntegrationPayload = (
    | {
          type: 'WE_COM_MAIL'
          params: EmailAccountItem
      }
    | {
          type: 'ICLICK'
          params: IClickAccountItem
      }
    | {
          type: 'ALIYUN_SMS'
          params: AliyunSmsAccountItem
      }
    | {
          type: 'NET_EASE_126_MAIL'
          params: EmailAccountItem
      }
    | {
          type: 'WE_COM_ROBOT'
          params: WxRootItem
      }
    | {
          type: 'DING_TALK_ROBOT'
          params: DingTalkRobotItem
      }
    | {
          type: 'WECHAT_OFFICIAL_ACCOUNT'
          params: WeChatPublicAccountItem
      }
    | {
          type: 'WECHAT_WEBSITE'
          params: WeChatWebsiteAccountItem
      }
    | {
          type: 'WECHAT_PAY'
          params: WeChatPayAccountItem
      }
    | {
          type: 'STRIPE'
          params: StripeAccountItem
      }
) & {
    name: string
}

export type UpdateIntegrationPayload = (
    | {
          type: 'WE_COM_MAIL'
          params?: Partial<EmailAccountItem>
      }
    | {
          type: 'ICLICK'
          params?: Partial<IClickAccountItem>
      }
    | {
          type: 'ALIYUN_SMS'
          params?: Partial<AliyunSmsAccountItem>
      }
    | {
          type: 'NET_EASE_126_MAIL'
          params?: Partial<EmailAccountItem>
      }
    | {
          type: 'WE_COM_ROBOT'
          params?: Partial<WxRootItem>
      }
    | {
          type: 'DING_TALK_ROBOT'
          params?: Partial<DingTalkRobotItem>
      }
    | {
          type: 'WECHAT_OFFICIAL_ACCOUNT'
          params?: Partial<WeChatPublicAccountItem>
      }
    | {
          type: 'WECHAT_WEBSITE'
          params?: Partial<WeChatWebsiteAccountItem>
      }
    | {
          type: 'WECHAT_PAY'
          params?: Partial<WeChatPayAccountItem>
      }
    | {
          type: 'STRIPE'
          params: Partial<StripeAccountItem>
      }
) & {
    name?: string
    id: string
}

export type SearchEngineType = 'baidu' | 'google' | 'bing'

export enum PageSubmissionStateEnum {
    'FAIL' = 'FAIL',
    // 已提交
    SUCCESS = 'SUCCESS',
    // 排队中
    QUEUE = 'QUEUE'
}

export enum PageSubmissionSearchEngineEnum {
    BAIDU,
    CHROME,
    BING
}
export type PageSubmissionItem = {
    id: string

    createdTime: number

    url: string

    state: PageSubmissionStateEnum

    searchEngine: PageSubmissionSearchEngineEnum

    failureReason: string
}

export type updatePasswordPayload = {
    mobile: string
    smsCode: string
    password: string
}

export type SpaceOrder = {
    outTradeNo: string // 订单号,
    paidContent: string // 付费项目,
    amount: number // 金额，单位元,
    payType: string // 支付方式,
    successTime: string // 订单支付时间
    spaceName: string // 空间名称
    spaceId: string // 空间id
}

export type CascadeOptionResult = {
    children: CascadeOptionResult[]
    id: string
    label: string
    parentId: string
}

export type EnvDiffsResult = EnvDiffs
