import type { ApplicationAbstract, ApplicationVersionBranch, ApplicationWebsiteSetting, AppUser, Environment } from '@lighthouse/core'
import { ApplicationStruct, ApplicationType } from '@lighthouse/core'
import type { FindUseData } from '@lighthouse/shared'
import { ApplicationPreviewEnum, DEFAULT_LANG } from '@lighthouse/shared'
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'

import { userDataAtom } from '../auth/state'
import type { ApplicationModePageHistory } from './constants'
import { APPLICATION_MODE_PAGE_HISTORY, CURRENT_PAGE, developerRole } from './constants'
import type { BreadcrumbsHistory } from './type'

export const appListAtom = atomWithImmer<ApplicationAbstract[]>([])

export const appAtom = atomWithImmer<ApplicationAbstract>({ ...ApplicationStruct() })

export const currentAppIdAtom = atom(get => get(appAtom).id)

export const applicationSettingAtom = atom(get => get(appAtom)?.config)

export const websiteApplicationSettingAtom = atom<ApplicationWebsiteSetting | null>(get => {
    const app = get(appAtom)
    return app?.type === ApplicationType.website ? app?.config : null
})

export const applicationPrivateTemplateAtom = atom(get => get(websiteApplicationSettingAtom)?.privateTemplate)

/** 应用主题色 */
export const applicationThemeColorAtom = atom(get => {
    const { theme } = get(appAtom).config ?? {}
    return theme?.palettes.find(item => item.id === theme.primaryPointer)?.value || ''
})

export const applicationVersionAtom = atom(
    get => get(appAtom).version,
    (get, set, params: Partial<ApplicationVersionBranch>) => {
        const app = get(appAtom)
        set(appAtom, {
            ...app,
            version: {
                ...app.version,
                ...params
            }
        })
        return params
    }
)

export const currentAppVersionIdAtom = atom(get => get(applicationVersionAtom).id)

export const currentEnvIdAtom = atom(get => get(applicationVersionAtom).envId)

export const currentAppOfSpaceIdAtom = atom(get => get(appAtom).spaceId)

export const previewTypeAtom = atomWithStorage('preview', ApplicationPreviewEnum.desktop)

export const sharePageAtom = atomWithStorage('sharePage', CURRENT_PAGE)

export const dataDrawerStateAtom = atomWithImmer<boolean>(false)

export const sharePosterAtom = atomWithStorage('sharePoster', 'poster1')

export const appEnvListAtom = atomWithImmer<Environment[]>([])

export const appCollapseMapAtom = atom(
    get => {
        const list = get(appAtom)?.config.navbar.linkList.list || {}
        return Object.fromEntries(Object.keys(list ?? {}).map(id => [id, false]))
    },
    (get, set, params: Record<string, boolean>) => {
        set(appCollapseMapAtom, params)
        return params
    }
)

export const applicationModePageHistoryAtom = atomWithImmer<ApplicationModePageHistory>(APPLICATION_MODE_PAGE_HISTORY)

export const appRoleAtom = atom(developerRole.userId)

// 右侧面包屑相关状态
export const breadcrumbsHistoryAtom = atomWithImmer<BreadcrumbsHistory[]>([])
/** 是否是要恢复上次面包屑状态 */
export const IsBreadcrumbsHistoryBackAtom = atom(false)
/** 创建block时的logo动画 */
export const logoAnimationEndAtom = atom(false)
/** 应用新手指导 */
export const appGuideAtom = atom(get => get(userDataAtom).userCompletedTask ?? [])
/** 应用语言 */
export const languageAtom = atom(DEFAULT_LANG)
/** 查找使用 */
export const findUseAtom = atomWithImmer<FindUseData | undefined>(undefined)
/** 应用发布/更新时状态 */
export const AppPublishStateAtom = atomWithImmer({ isPublish: false, isSuccess: false })
/** 系统页面 */
export const AppSystemPageAtom = atom<string | null>(null)

