import { Button, IconFont, Modal } from '@byecode/ui'
import { getBrand, useAtomAction, UserImport } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { fetchDataSourceUserAtom } from '@/atoms/dataSource/action'
import { openSaleSpaceGrade, openSpaceGrade } from '@/components/SpaceGrade'
import { useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

const SCxContainer = styled.div`
    width: 100%;
`

export interface UserImportModalProps {

}

export const UserImportModal: React.FC<UserImportModalProps> = () => {
    const [open, setOpen] = useState(false)
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const envId = useCurrentEnvId()
    const brand = getBrand()
    const handleOpenImport = useCallback(() => {
        setOpen(true)
    }, [])

    const handleCloseImport = useCallback(() => {
        setOpen(false)
    }, [])

    const handleImportAppUser = useCallback(async (arg: File) => {
        const res = await srv.importAppUser(arg, envId)
        if(res.code === '0000000'){
            fetchDataSourceUser()
        }
        return res
    }, [envId, fetchDataSourceUser])

    return (
        <SCxContainer>
            <Button onClick={handleOpenImport} icon={<IconFont type="UploadSimple" size={16} fill="var(--color-gray-400)" />}>
                导入
            </Button>
            <Modal
                open={open}
                withCloseIcon={false}
                width={745}
                styles={{
                    desktop: {
                        body: {
                            padding: '0!important',
                            overflow: 'hidden',
                            borderRadius: 12
                        }
                    }
                }}
                onClose={handleCloseImport}
            >
                <UserImport
                    onImportAppUser={handleImportAppUser}
                    onOpenSpaceGrade={openSpaceGrade}
                    onOpenSaleSpaceGrade={openSaleSpaceGrade}
                    onClose={handleCloseImport}
                />
            </Modal>
        </SCxContainer>
    )
}
