import { Button, Empty, Flex, IconFont, Modal, Popover, Toast } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { ApplicationType } from '@lighthouse/core'
import type { StatusFile } from '@lighthouse/shared'
import { getBrandInfo, timeDistance, useAtomAction, useAtomData } from '@lighthouse/shared'
import { getRandomIcon, nanoid } from '@lighthouse/tools'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState, useWindowSize } from 'react-use'

import { copyApplicationAtom, createApplicationAtom, fetchApplicationListAtom, removeApplicationAtom } from '@/atoms/application/action'
import { isSkipWorkSpaceGuideAtom } from '@/atoms/workSpace/state'
import { AppAICreator } from '@/components/AppAiCreator'
// import type { AppCreatorState } from '@/components/AppCreator'
import { AppCreator } from '@/components/AppCreator'
import { ImportLoading } from '@/components/ImportLoading'
import { ModalTitle } from '@/components/ModalTitle'
import { emitter } from '@/components/SpaceGuide/events'
import { GuideStep } from '@/components/SpaceGuide/GuideStep'
import { useApplicationList } from '@/hooks/useApplication'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import { createAppByAIPrompt } from '@/services'
import type { CreateApplicationPayload } from '@/services/types'

import TemplateModal from '../Template/TemplateModal'
import { HandleSelect } from './HandleSelect'
import { PromotionNotice } from './PromotionNotice'
import * as SC from './styles'

const guideStepImg7 = getAssetUrl('guide', 'guide_step7.jpg')
const HelpBuild = getAssetUrl('application', 'HelpBuild.png')
const Cart = getAssetUrl('application', 'Cart.png')
const Chart = getAssetUrl('application', 'Chart.png')
const LeftBlank = getAssetUrl('application', 'LeftBlank.png')
const Mouse = getAssetUrl('application', 'Mouse.png')
const PaperPlane = getAssetUrl('application', 'PaperPlane.png')
const ResolveCase = getAssetUrl('application', 'ResolveCase.png')
const ResolveCaseBackground = getAssetUrl('application', 'ResolveCaseBackground.png')
const ResolveCaseTiny = getAssetUrl('application', 'ResolveCaseTiny.png')
const RightBlank = getAssetUrl('application', 'RightBlank.png')
const TemplateDesktopWeb = getAssetUrl('application', 'TemplateDesktopWeb.png')
const TemplateMobileWeb = getAssetUrl('application', 'TemplateMobileWeb.png')
const Users = getAssetUrl('application', 'Users.png')

const emptyApplicationImg = getAssetUrl('empty', 'empty_application.png')

export const ApplicationList: React.FC = () => {
    const listRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const { width } = useWindowSize()
    const spaceId = useCurrentSpaceID()
    const applicationList = useApplicationList()

    // const [state, setImg] = useImmer({
    //     templateImg: TemplateCard,
    //     helpBuildImg: HelpBuild
    // })
    // const { templateImg, helpBuildImg } = state
    const [{ fileStatus, open, isBlankTemplate, openLoading, openTemplate, createAppType, createWay }, setState] = useSetState<{
        fileStatus: StatusFile | null
        open: boolean
        isBlankTemplate: boolean
        openLoading: boolean
        openTemplate: boolean
        createAppType: ApplicationType
        createWay: 'common' | 'ai'
    }>({
        fileStatus: null,
        open: false,
        isBlankTemplate: false,
        openLoading: false,
        openTemplate: false,
        createAppType: ApplicationType.website,
        createWay: 'common'
    })
    const isSkipWorkSpaceGuide = useAtomData(isSkipWorkSpaceGuideAtom)
    const { run: createApplication } = useAtomAction(createApplicationAtom)
    const { run: removeApplication } = useAtomAction(removeApplicationAtom)
    const { run: copyApplication } = useAtomAction(copyApplicationAtom)
    const { run: fetchApplicationList } = useAtomAction(fetchApplicationListAtom)
    const { run: setIsSkipWorkSpaceGuide } = useAtomAction(isSkipWorkSpaceGuideAtom)

    const handleRemove = useCallback(
        async (id: string, name: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `删除应用「${name}」？`,
                okStatus: 'error'
            })

            if (isConfirm) {
                const modalId = nanoid()
                try {
                    const isDelete = await removeApplication(id)
                    // closePageOverlay(modalId)
                    if (isDelete) {
                        await fetchApplicationList(spaceId)
                    }
                } catch {
                    Toast.error('删除失败')
                    fetchApplicationList(spaceId)
                }
            }
        },
        [fetchApplicationList, removeApplication, spaceId]
    )

    const handleCopy = useCallback(
        async (id: string, name: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认复制',
                content: `复制应用「${name}」？`,
                okStatus: 'primary',
                okText: '确认'
            })

            if (isConfirm) {
                const modalId = nanoid()
                try {
                    const copyId = await copyApplication(id)
                    // closePageOverlay(modalId)
                    if (copyId) {
                        await fetchApplicationList(spaceId)
                    }
                } catch {
                    Toast.error('复制失败')
                    fetchApplicationList(spaceId)
                }
            }
        },
        [copyApplication, fetchApplicationList, spaceId]
    )

    const handlers = useMemo(
        () => ({
            handleSelectTemplate: (templateId: string) => {
                setState({
                    openTemplate: true,
                    open: true,
                    // createAppType: templateId === blankTemplateWxAppletId ? ApplicationType.wxApplet : ApplicationType.website
                    createAppType: ApplicationType.website,
                    createWay: templateId === 'ai' ? 'ai' : 'common'
                })
            }
        }),
        [setState]
    )

    const handleChangeFileStatus = useCallback(
        (status: StatusFile | null, appId?: string) => {
            setState({ fileStatus: status })
            if (status?.status === 'error' || status?.percent === 100) {
                setState({ openLoading: false })
                appId && navigate({ pathname: `/${appId}` })
                return
            }
            setState({ openLoading: true, openTemplate: false, open: false })
        },
        [navigate, setState]
    )

    const handleOpenHelpBuild = useCallback(() => {
        window.open(getBrandInfo().space.helpBuildUrl, '_blank')
    }, [])

    const handleOpenResolved = useCallback(() => {
        window.open(getBrandInfo().space.resolvedUrl, '_blank')
    }, [])

    const handleOpenCreateApp = useCallback(() => {
        setState({ openTemplate: true, isBlankTemplate: true })
        setIsSkipWorkSpaceGuide(false)
    }, [setIsSkipWorkSpaceGuide, setState])

    const handleOpenTemplate = useCallback(() => {
        setState({ openTemplate: true, isBlankTemplate: false })
    }, [setState])

    const handleCreateApp = useCallback(
        (params: Omit<CreateApplicationPayload, 'spaceId'>) => {
            // setState({ open: false, openLoading: true })
            // const appId = await createApplication({ spaceId, ...params })
            // setState({ openLoading: false, openTemplate: false })
            // if (appId) {
            //     navigate({ pathname: `/${appId}` })
            // }
            return createApplication({ spaceId, ...params })
        },
        [createApplication, spaceId]
    )

    const handleCreateAppByAI = useCallback(
        async (prompt: string) => {
            try {
                const appId = await createAppByAIPrompt({ spaceId, prompt, icon: getRandomIcon() })
                setState({ open: false })
                if (appId) {
                    navigate({ pathname: `/${appId}` })
                }
            } catch {
                Toast.error('创建失败，请重试')
                setState({ open: false })
            }
        },
        [navigate, setState, spaceId]
    )

    const handleLinkToApplication = useCallback(
        (appId: string) => {
            navigate({ pathname: `/${appId}/page` })
        },
        [navigate]
    )

    const handleLinkToHelp = useCallback(() => {
        window.open('https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich?#', '_blank')
    }, [])

    useEffect(() => {
        const listElement = listRef.current
        if (listElement) {
            const { width } = listElement.getBoundingClientRect()
            const count = Math.floor(width / 224) - 1
            listElement.style.gridTemplateColumns = `repeat(${count}, 1fr)`
        }
    }, [width])

    useEffect(() => {
        emitter.on('createApp', handleOpenCreateApp)
        return () => {
            emitter.off('createApp', handleOpenCreateApp)
        }
    }, [handleOpenCreateApp, open])

    return (
        <SC.Container>
            <SC.Header>
                {/* <SC.Title size={16}>你可能想尝试...</SC.Title> */}
                <PromotionNotice />
            </SC.Header>
            <SC.Utils>
                <Flex style={{ flex: 1 }} gap={16}>
                    <SC.TemplateItem onClick={handleOpenTemplate}>
                        <SC.UtilsHead>
                            <SC.UtilsTitle>模板库</SC.UtilsTitle>
                            {/* <SC.Arrow type="ArrowCircle" /> */}
                        </SC.UtilsHead>
                        <SC.UtilsContent>
                            <SC.TemplateDesktopWebImg src={TemplateDesktopWeb} alt="TemplateWeb" />
                            <SC.TemplateMobileWebImg src={TemplateMobileWeb} alt="TemplateWeb" />
                            <SC.LeftBlankImg src={LeftBlank} alt="TemplateWeb" />
                            <SC.RightBlankImg src={RightBlank} alt="TemplateWeb" />

                            <SC.CartImg src={Cart} alt="Cart" />
                            <SC.ChartImg src={Chart} alt="Chart" />
                            <SC.PaperPlaneImg src={PaperPlane} alt="PaperPlane" />
                            <SC.UsersImg src={Users} alt="Users" />
                        </SC.UtilsContent>
                    </SC.TemplateItem>
                    <Flex style={{ flex: 1 }} gap={16}>
                        <SC.AiItem onClick={handleOpenHelpBuild}>
                            <SC.UtilsHead>
                                <SC.UtilsTitle>帮你搭建</SC.UtilsTitle>
                                {/* <SC.Arrow type="ArrowCircle" /> */}
                            </SC.UtilsHead>
                            <SC.UtilsContent>
                                <SC.BuildImg src={HelpBuild} alt="HelpBuild" />
                                <SC.MouseImg src={Mouse} alt="Mouse" />
                            </SC.UtilsContent>
                            <SC.PurpleBackground style={{ position: 'absolute', top: '61px', left: '-180px' }} />
                            <SC.CyanBackground style={{ position: 'absolute', top: '-85px', right: '-102px' }} />
                        </SC.AiItem>
                        <SC.ResolvedItem
                            onClick={handleOpenResolved}
                            // onMouseEnter={() => handleSetGif('helpBuildImg', HelpBuildGif)}
                            // onMouseLeave={() => {
                            //     handleSetGif('helpBuildImg', HelpBuild)
                            // }}
                        >
                            <SC.UtilsHead>
                                <SC.UtilsTitle>解决方案</SC.UtilsTitle>
                                {/* <SC.Arrow type="ArrowCircle" /> */}
                            </SC.UtilsHead>
                            <SC.UtilsContent>
                                <SC.ResolveCaseImg src={ResolveCase} alt="AiInput" />
                                <SC.ResolveCaseTinyImg src={ResolveCaseTiny} alt="AiInput" />
                                <SC.ResolveCaseBackgroundImg src={ResolveCaseBackground} alt="AiInput" />
                            </SC.UtilsContent>
                            <SC.CyanBackground style={{ position: 'absolute', top: '-85px', left: '-98px' }} />
                            <SC.PurpleBackground style={{ position: 'absolute', top: '8px', right: '-112px' }} />
                        </SC.ResolvedItem>
                    </Flex>
                </Flex>
                <SC.HelpItem width={142} onClick={handleLinkToHelp}>
                    <SC.UtilsHead>
                        <SC.UtilsTitle>帮助中心</SC.UtilsTitle>
                        <SC.Arrow type="ArrowCircle" />
                    </SC.UtilsHead>
                    <SC.UtilsContent>
                        <SC.HelpImg src={getAssetUrl('common', 'Help.svg')} alt="help" />
                    </SC.UtilsContent>
                </SC.HelpItem>
            </SC.Utils>
            <ModalTitle title="所有应用" style={{ marginTop: 20 }} />
            {applicationList.length === 0 && (
                <Empty
                    styles={{
                        root: {
                            width: '100%'
                        },
                        wrapper: {
                            gap: 20
                        }
                    }}
                    description={
                        <div style={{ width: '100%', height: '100%' }}>
                            <Popover opened={isSkipWorkSpaceGuide} withArrow position="right-start" arrowColor="var(--color-main)">
                                <Popover.Target>
                                    <Button type="primary" style={{ height: 40 }} onClick={handleOpenCreateApp}>
                                        创建你的第一个应用
                                    </Button>
                                </Popover.Target>
                                <Popover.Dropdown compact>
                                    <GuideStep describe="现在开始搭建你的第一个应用吧！👏" title="创建应用" images={[guideStepImg7]} />
                                </Popover.Dropdown>
                            </Popover>
                        </div>
                    }
                    icon={<img width={195} src={emptyApplicationImg} alt="" />}
                />
            )}
            <SC.Applications ref={listRef}>
                {applicationList.length > 0 && (
                    <SC.ApplicationCard>
                        <SC.ApplicationCardImage onClick={handleOpenCreateApp}>
                            <SC.ApplicationCardLogo background="var(--color-gray-900)" radius="50%">
                                <IconFont fill="var(--color-white)" type="Add" size={24} />
                            </SC.ApplicationCardLogo>
                            <SC.ApplicationCardTitle>创建应用</SC.ApplicationCardTitle>
                        </SC.ApplicationCardImage>
                    </SC.ApplicationCard>
                )}
                {applicationList.map(item => (
                    <SC.ApplicationCard key={item.id}>
                        <SC.ApplicationCardTool>
                            <HandleSelect id={item.id} name={item.name} onRemove={handleRemove} onCopy={handleCopy} />
                        </SC.ApplicationCardTool>
                        <SC.ApplicationCardImage onClick={() => handleLinkToApplication(item.id)}>
                            <SC.ApplicationCardLogo background={item.primaryColor}>
                                <IconFont fill="var(--color-white)" type={item.icon} size={24} />
                            </SC.ApplicationCardLogo>
                            <SC.ApplicationCardTitle lineClamp={1}>{item.name}</SC.ApplicationCardTitle>
                            <SC.ApplicationCardDescribeText lineClamp={1}>{timeDistance(item.updatedTime)}</SC.ApplicationCardDescribeText>
                        </SC.ApplicationCardImage>
                    </SC.ApplicationCard>
                ))}
            </SC.Applications>
            <TemplateModal
                opened={openTemplate}
                isBlankTemplate={isBlankTemplate}
                onClose={() => {
                    setState({ openTemplate: false })
                }}
                onCreateBlankApp={handlers.handleSelectTemplate}
                onChangeFileStatus={handleChangeFileStatus}
            />

            <ImportLoading open={openLoading} hidePercent fileStatus={fileStatus} onClose={() => setState({ openLoading: false })} />

            <AppCreator
                open={createWay === 'common' && open}
                // open
                appType={createAppType}
                onClose={() => {
                    setState({ open: false })
                }}
                onConfirm={handleCreateApp}
            />

            <AppAICreator
                opened={createWay === 'ai' && open}
                onClose={() => {
                    setState({ open: false })
                }}
                onConfirm={value => {
                    handleCreateAppByAI(value)
                }}
            />
        </SC.Container>
    )
}
