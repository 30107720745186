import { Button, Flex, IconFont, Tooltip } from '@byecode/ui'
import { type DataSourceAbstract, type Field, type SchemaProtocol, type ViewOptions, DataSourceType } from '@lighthouse/core'
import { DEFAULT_ACTION, getViewColumns, Group4ByecodeUi, useAtomData } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { defaultPageListAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { ActionAdderProvider } from '@/contexts/ActionAdderContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { ButtonSettingList } from '../../ButtonGroup/ButtonSettingList'
import { QuickFilterSetting } from '../../Common/QuickFilterSetting'
import { AllowImportSetting, CreateRecordV2, RecordViewable, SwitchItem } from '../../Common/UserOperate'

export interface CalendarOperateProps {
    id: string
    dataSource: DataSourceAbstract
    isUserDataSource?: boolean
}

export const CalendarOperate: React.FC<CalendarOperateProps> = ({ id, dataSource, isUserDataSource }) => {
    const { watch, control, setValue } = useFormContext<ViewOptions>()
    const [
        viewType,
        viewFieldSettings,
        canCreateRecord,
        creatingConfig,
        editingOpenType,
        dsId,
        canImport,
        userImportSetting,
        triggerWorkflow,
        toolbarCustomized,
        recordClickedCustomized,
        recordClickedAction
    ] = watch([
        'viewType',
        'viewFieldSettings',
        'canCreateRecord',
        'creatingConfig',
        'editingConfig.openType',
        'pointer',
        'canImport',
        'userImportSetting',
        'triggerWorkflow',
        'actions.toolbar.customized',
        'actions.recordClicked.customized',
        'actions.recordClicked.action'
    ])
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const allPages = useAtomData(defaultPageListAtom)
    const dataSourceList = useDataSourceList(appId, envId)
    const { schema, viewOptions, sync, type } = dataSource
    const { tableProps } = viewOptions

    const isJoinDataSource = false /* useMemo(() => type === DataSourceType.joinDataSource, [type]) */

    const columns = useMemo(
        () =>
            getViewColumns({
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [schema, tableProps, viewFieldSettings]
    )

    // 合并数据源与视图上的字段
    const mergedSchema = useMemo(() => {
        return columns.reduce<SchemaProtocol['schema']>((acc, item) => {
            acc[item.fieldId] = { ...item, id: item.fieldId, name: item.title } as Field
            return acc
        }, {})
    }, [columns])

    return (
        <>
            {!isUserDataSource && (
                <Group4ByecodeUi
                    label={
                        <PopoverQuestion
                            type="toolOperation"
                            mainAxis={38}
                            arrowOffset={20}
                            renderTarget={node => (
                                <Flex gap={4} alignItems="center">
                                    工具栏操作{node}
                                </Flex>
                            )}
                        />
                    }
                    extra={
                        <Tooltip placement="top" title={toolbarCustomized ? '取消自定义动作' : '使用自定义动作'}>
                            <Button
                                style={toolbarCustomized ? { backgroundColor: 'var(--color-main)', color: 'var(--color-white)' } : {}}
                                radius={50}
                                onClick={ev => {
                                    ev.stopPropagation()
                                    setValue('actions.toolbar.customized', !toolbarCustomized)
                                }}
                                type="tag"
                                size="sm"
                                icon={<IconFont type="Lightning" />}
                            >
                                自定义
                            </Button>
                        </Tooltip>
                    }
                >
                    {toolbarCustomized ? (
                        <div style={{ padding: '0 8px' }}>
                            <ButtonSettingList
                                allPages={allPages}
                                prefixName="actions.toolbar.actionGroup."
                                hideConfigs={{ fillWay: true }}
                            />
                        </div>
                    ) : (
                        <CreateRecordV2
                            viewId={id}
                            value={{ canCreateRecord, creatingConfig }}
                            dataSource={dataSource}
                            onChange={val => {
                                const { canCreateRecord, creatingConfig } = val
                                setValue('canCreateRecord', canCreateRecord)
                                setValue('creatingConfig', creatingConfig)
                            }}
                        />
                    )}
                </Group4ByecodeUi>
            )}

            <Divider color="var(--color-gray-200)" />

            {/* 2023-09-10 07:39:42 确定日历视图无行操作 */}
            {/* <Group4ByecodeUi label="行操作">
                <EditRecord
                    viewId={id}
                    value={{ canViewEdit, editingConfig }}
                    isCreatePage
                    dsId={dsId}
                    onChange={val => {
                        const { canViewEdit, editingConfig } = val
                        setValue('canViewEdit', canViewEdit)
                        setValue('editingConfig', editingConfig)
                    }}
                />
                <Controller
                    control={control}
                    name="canDeleteRecord"
                    render={({ field }) => (
                        <SwitchItem
                            disabled={!dataSource || dataSource.type === 2}
                            icon="Trash"
                            label="删除"
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </Group4ByecodeUi>

            <Divider color="var(--color-gray-200)" /> */}

            <Group4ByecodeUi
                label="点击日程后"
                extra={
                    <Tooltip placement="top" title={recordClickedCustomized ? '取消自定义动作' : '使用自定义动作'}>
                        <Button
                            style={recordClickedCustomized ? { backgroundColor: 'var(--color-main)', color: 'var(--color-white)' } : {}}
                            radius={50}
                            onClick={ev => {
                                ev.stopPropagation()
                                setValue('actions.recordClicked.customized', !recordClickedCustomized)
                                if (!recordClickedCustomized) {
                                    setValue('actions.recordClicked.action', {
                                        type: 'none',
                                        trigger: 'click',
                                        data: {
                                            none: {}
                                        }
                                    })
                                }
                            }}
                            type="tag"
                            size="sm"
                            icon={<IconFont type="Lightning" />}
                        >
                            自定义
                        </Button>
                    </Tooltip>
                }
            >
                {recordClickedCustomized ? (
                    <div style={{ padding: '0 8px' }}>
                        <ActionAdderProvider dsId={dsId} viewType={viewType}>
                            <ActionAdder
                                showLabel={false}
                                title="点击日程后"
                                allPages={allPages}
                                action={recordClickedAction ?? DEFAULT_ACTION}
                                prefixName="actions.recordClicked."
                                events={{
                                    triggerEvent: 'click',
                                    params: [],
                                    handleEvent: 'none'
                                }}
                            />
                        </ActionAdderProvider>
                    </div>
                ) : (
                    <RecordViewable id={id} />
                )}
            </Group4ByecodeUi>

            <Divider color="var(--color-gray-200)" />

            <Group4ByecodeUi label="其它操作">
                {!isJoinDataSource && (
                    <AllowImportSetting
                        type="UploadSimple"
                        title="批量导入"
                        columns={columns}
                        value={{ canImport, userImportSetting, triggerWorkflow }}
                        dataSource={dataSource}
                        dataSourceList={dataSourceList}
                        onChange={val => {
                            const { canImport, userImportSetting, triggerWorkflow } = val
                            setValue('canImport', canImport)
                            setValue('userImportSetting', userImportSetting)
                            setValue('triggerWorkflow', triggerWorkflow)
                        }}
                    />
                )}

                <Controller
                    control={control}
                    name="canSearch"
                    render={({ field }) => <SwitchItem icon="SearchLine" label="搜索数据" value={field.value} onChange={field.onChange} />}
                />

                <QuickFilterSetting schema={mergedSchema} dataSourceList={dataSourceList} columns={columns} />
                <Controller
                    control={control}
                    name="canGroup"
                    render={({ field }) => <SwitchItem icon="GroupBy" label="设置分组" value={field.value} onChange={field.onChange} />}
                />
            </Group4ByecodeUi>
        </>
    )
}
