import { Flex, Group, IconFont, Input, Select, Switch, Text, Tooltip } from '@byecode/ui'
import type { FieldInputConfigProtocol, SubFormBlockConfig } from '@lighthouse/core'
import { ListItem4ByecodeUi, PURE_TEXT_INPUT } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { AppSmsCard } from '@/components/AppSmsCard'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { SwitchControl } from '../../SwitchControl'
import { CommonOpera, Required } from '../../UserOperate'
import { Repeat } from '../../UserOperate/Repeat'
import { DATE_RANGE_OPTIONS } from '../constants'
import type { BaseFieldInputSetting, FieldOptions } from '../types'

interface UserProps extends Omit<BaseFieldInputSetting, 'blockId'> {}

const SCxContainer = styled.div`
    padding: 0 8px;
`

export const User: React.FunctionComponent<UserProps> = ({ mode, pointer, prefix }) => {
    const { watch, control, setValue, register } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [inputType, fieldPointer = '', minFontNumber = '', maxFontNumber = '', isUsedAreaCode, dateRange, isRegular] = watch([
        `${prefix}inputType`,
        `${prefix}fieldPointer`,
        `${prefix}text.minFontNumber`,
        `${prefix}text.maxFontNumber`,
        `${prefix}phoneNumber.isUsedAreaCode`,
        `${prefix}date.range`,
        `${prefix}text.isRegular`
    ])

    const appId = useCurrentAppID()

    const envId = useCurrentEnvId()

    const dataSource = useDataSource(appId, envId, pointer)

    const field = dataSource?.schema?.[fieldPointer]

    const isShowRequired = field?.type !== 'formula'

    return (
        <Group
            styles={{
                root: {
                    padding: '12px 0!important'
                },
                collapse: {
                    padding: '0!important'
                }
            }}
            label="校验"
        >
            <SCxContainer>
                {isShowRequired && (
                    <Controller
                        name={`${prefix}required`}
                        render={({ field }) => (
                            <Required
                                label={inputType === 'checkbox' ? '必须勾选' : '必填'}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                )}
                {inputType === 'text' && (
                    <>
                        <ListItem4ByecodeUi enablePadding compact justifyContent="space-between" alignItems="center">
                            <Text>字数约束</Text>
                            <Flex justifyContent="space-between" alignItems="center" style={{ width: 180 }}>
                                <Controller
                                    name={`${prefix}text.minFontNumber`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            style={{ width: 80 }}
                                            type="number"
                                            max={1000}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={e => {
                                                const newValue = e.target.value
                                                if (newValue === '') {
                                                    return
                                                }
                                                if (Number(newValue) < 1) {
                                                    field.onChange(1)
                                                }
                                                if (maxFontNumber === '') {
                                                    return
                                                }
                                                if (Number(newValue) > Number(maxFontNumber)) {
                                                    setValue('text.maxFontNumber', Number(newValue) + 1)
                                                }
                                            }}
                                        />
                                    )}
                                />
                                -
                                <Controller
                                    name={`${prefix}text.maxFontNumber`}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            style={{ width: 80 }}
                                            type="number"
                                            min={1}
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={e => {
                                                const newValue = e.target.value
                                                if (Number(newValue) < 1 && newValue !== '') {
                                                    field.onChange(1)
                                                }
                                                if (minFontNumber === '' || newValue === '') {
                                                    return
                                                }

                                                if (Number(minFontNumber) > Number(newValue)) {
                                                    field.onChange(Number(minFontNumber) + 1)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Flex>
                        </ListItem4ByecodeUi>
                        <Controller
                            control={control}
                            name={`${prefix}text.isRegular`}
                            render={({ field: { value, onChange } }) => (
                                <div style={{ padding: '0 8px' }}>
                                    <SwitchControl
                                        checked={value}
                                        label="使用正则校验"
                                        onChange={e => {
                                            onChange?.(e.currentTarget.checked)
                                        }}
                                    />
                                </div>
                            )}
                        />
                        {
                            isRegular && <>
                             <PopoverQuestion
                            type="regular"
                            renderTarget={iconEle => (
                                <ListItem4ByecodeUi enablePadding compact justifyContent="space-between" alignItems="center">
                                    <Flex gap={4}>
                                        <Text>自定义正则</Text>
                                        {iconEle}
                                    </Flex>
                                    <Input style={{ width: 180 }} {...register(`${prefix}text.regular`)} />
                                </ListItem4ByecodeUi>
                            )}
                        />

                        <ListItem4ByecodeUi enablePadding compact justifyContent="space-between" alignItems="center">
                            <Text>错误时提示</Text>
                            <Input style={{ width: 180 }} {...register(`${prefix}text.regularErrorLabel`)} />
                        </ListItem4ByecodeUi>
                            </>
                        }

                    </>
                )}
                {inputType === 'number' && (
                    <>
                        <ListItem4ByecodeUi enablePadding compact={false} justifyContent="space-between" alignItems="center">
                            <Text>最小值</Text>
                            <Input style={{ width: 180 }} type="number" {...register(`${prefix}number.min`)} />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding compact={false} justifyContent="space-between" alignItems="center">
                            <Text>最大值</Text>
                            <Input style={{ width: 180 }} type="number" {...register(`${prefix}number.max`)} />
                        </ListItem4ByecodeUi>
                    </>
                )}
                {inputType === 'date' && (
                    <>
                        <Controller
                            name={`${prefix}date.range`}
                            render={({ field }) => (
                                <ListItem4ByecodeUi enablePadding compact justifyContent="space-between" alignItems="center">
                                    <Text>可选日期</Text>
                                    <Select
                                        value={field.value}
                                        style={{ width: 180 }}
                                        options={DATE_RANGE_OPTIONS}
                                        onChange={field.onChange}
                                    />
                                </ListItem4ByecodeUi>
                            )}
                        />

                        {dateRange === 'AFTER_CUSTOM' && (
                            <ListItem4ByecodeUi enablePadding compact justifyContent="space-between" alignItems="center">
                                <Text>多少天</Text>
                                <Input style={{ width: 180 }} type="number" min={0} {...register(`${prefix}date.customDays`)} />
                            </ListItem4ByecodeUi>
                        )}
                    </>
                )}
                {PURE_TEXT_INPUT.includes(inputType) && (
                    <Controller
                        name={`${prefix}noRepeat`}
                        render={({ field: { value, onChange } }) => <Repeat value={value} compact onChange={onChange} />}
                    />
                )}
                {inputType === 'phoneNumber' && mode === 'form' && (
                    <>
                        <PopoverQuestion
                            type="smsVerification"
                            renderTarget={node => (
                                <ListItem4ByecodeUi enableHover enablePadding justifyContent="space-between" alignItems="center">
                                    <Flex gap={4} alignItems="center">
                                        <Text>短信验证</Text>
                                        {node}
                                    </Flex>
                                    <Controller
                                        control={control}
                                        name={`${prefix}phoneNumber.isOpened`}
                                        render={({ field }) => (
                                            <Switch
                                                checked={field.value}
                                                disabled={isUsedAreaCode}
                                                onChange={e => {
                                                    field.onChange?.(e.currentTarget.checked)
                                                }}
                                            />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                            )}
                        />
                    </>
                )}
            </SCxContainer>
        </Group>
    )
}
