import * as React from 'react'
import styled from 'styled-components'

interface TempleLabelsProps {
    labels?: string
    style?: React.CSSProperties
}

const SCxLabelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 10px;
`

const SCxLabel = styled.span`
    padding: 4px 8px;
    border-radius: 16px;
    color: var(--color-gray-400);
    background-color: #fff;
    font-size: var(--font-size-normal);
    border: 1px solid var(--color-gray-200);
`
const testLabels = '张琪,合一,科霖,超长长长长长长长长长长长长长长的一个的文本'

export const TempleLabels: React.FunctionComponent<TempleLabelsProps> = ({ labels, style }) => {
    if (!labels) {
        return null
    }
    return (
        <SCxLabelContainer style={style}>
            {labels.split(',').map(text => (
                <SCxLabel key={text}>{text}</SCxLabel>
            ))}
        </SCxLabelContainer>
    )
}
