import { ByecodeUIThemeProvider, Modal, Select } from '@byecode/ui'
import { IconFont } from '@byecode/ui/components/IconFont'
import { MessageContainer } from '@byecode/ui/components/Message'
import { createTheme } from '@byecode/ui/theme/stitches.config'
import type { ButtonEvents, NavigationBaseItem, VariableADTvalue } from '@lighthouse/core'
import { ApplicationSettingJumpType, RecordOpenType } from '@lighthouse/core'
import type { PageOverlayState } from '@lighthouse/shared'
import {
    ApplicationContainer,
    ApplicationPreviewEnum,
    AppThemeContextProvider,
    combineBackgroundStyle,
    commonPages,
    getBackgroundStyle,
    getJumpUrl,
    getVeinsStyle,
    PAGE_CONTAINER_HOST,
    PAGE_CONTAINER_HOST_ID,
    PAGE_LAYOUT_HOST,
    PAGE_MODAL,
    PageLogo,
    PageNoAccess,
    PageOverlay,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { useAtom } from 'jotai'
import { find } from 'rambda'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { setAppRoleAtom } from '@/atoms/application/action'
import { developerRole } from '@/atoms/application/constants'
import {
    applicationSettingAtom,
    applicationThemeColorAtom,
    languageAtom,
    logoAnimationEndAtom,
    websiteApplicationSettingAtom
} from '@/atoms/application/state'
import { closeAllPageLayerAtom, closeCurrentPageLayerStackAtom, openPageStackAtom } from '@/atoms/page/action'
import {
    createNodeLoadingAtom,
    lastPageOfStackAtom,
    pageListAtom,
    pageStackAtom,
    pageStackAtomFamily,
    pageStackLayersAtom
} from '@/atoms/page/state'
import type { PageMetaData } from '@/atoms/page/types'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import DragLoading from '@/components/DragLoading'
import { NestedPageWrapper } from '@/components/NestedPageWrapper'
import { PageTool } from '@/components/PageTool'
import { PageToolMobile } from '@/components/PageTool/mobile'
import { RootPageProvider } from '@/contexts/PageContext'
import { useApplication, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector, usePageList } from '@/hooks/usePage'
import { usePageURI } from '@/hooks/usePageURI'
import { useToggleLang } from '@/hooks/useToggleLang'
import { useUserPageList } from '@/hooks/useUserPageList'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

import { PageContainer } from './PageContainer'
import { PageFooter } from './PageFooter'
import { SettingAside } from './SettingAside'

const BackButton = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
`

// const SCxPageContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     flex: 1;
// `

const SCxPageBody = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: stretch;
    height: 100%;
    overflow: hidden;
`

const SCxPageContent = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: var(--color-gray-50);
`

const SCxPageWrapper = styled.div<{ isMobile?: boolean }>`
    margin: ${({ isMobile }) => (isMobile ? '46px 24px 38px 24px' : '16px 16px 56px 16px')};
    display: flex;
    flex-direction: column;
    height: ${({ isMobile }) => (isMobile ? 'calc(100% - 84px)' : 'calc(100% - 72px)')};
    border-radius: 12px;
    background-color: ${({ isMobile }) => (isMobile ? 'transparent' : 'var(--color-white)')};
    box-shadow: ${({ isMobile }) => (isMobile ? 'none' : '0px 4px 12px 0px rgba(0, 0, 0, 0.12)')};
`
// const SCxPageWrapper = styled.div<{ isMobile?: boolean }>`
//     margin: ${({ isMobile }) => (isMobile ? '44px 24px 0 24px' : '16px 24px')};
//     display: flex;
//     flex-direction: column;
//     height: ${({ isMobile }) => (isMobile ? 'calc(100% - 44px)' : 'calc(100% - 32px)')};
//     border-radius: 12px;
//     background-color: ${({ isMobile }) => (isMobile ? 'transparent' : 'var(--color-white)')};
//     box-shadow: ${({ isMobile }) => (isMobile ? 'none' : '0px 4px 12px 0px rgba(0, 0, 0, 0.12)')};
// `

export const SCxContainer = styled.div`
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

interface PageLayoutProps {
    pageId: string
    stackId: string
    loading?: boolean
}

export const PageLayout: FC<PageLayoutProps> = props => {
    // const appElement = document.querySelector('#dataViewer')
    const { pageId, stackId } = props
    const rootPageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.rootPageId ?? '', [])
    )
    const isDragLoading = useAtomData(createNodeLoadingAtom)
    const app = useApplication()
    const {
        id: appId,
        config: { theme }
    } = app
    const { host } = usePageURI()
    const envId = useCurrentEnvId()
    const [defaultEnd, setDefaultEnd] = useAtom(logoAnimationEndAtom)
    const { run: setAppRole } = useAtomAction(setAppRoleAtom)

    const previewType = usePreviewType()
    const language = useAtomData(languageAtom)
    const isMobile = previewType === ApplicationPreviewEnum.mobile

    const { hash, pathname } = useLocation()
    const isPage = pathname.includes('page')
    const { visitPageList, allRolePageList, user, developerRoleUserId } = useUserPageList()
    const allPageList = usePageList()
    const visitPage = useMemo(() => find(item => item.id === pageId, visitPageList), [pageId, visitPageList])
    const isVisiblePage = Boolean(visitPage)

    const pageInnerStack = useAtomData(pageStackLayersAtom)
    const lastInnerStack = useMemo(() => pageInnerStack[pageInnerStack.length - 1] as PageMetaData | undefined, [pageInnerStack])
    const innerPageName = useAtomData(
        pageListAtom,
        useCallback(s => lastInnerStack && s.find(item => item.id === lastInnerStack.pageId)?.name, [lastInnerStack])
    )
    const { domainSetting, isHideLogo, isPublishApp, appTitle, appLogo, appDescription } = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(
            s => ({
                domainSetting: s?.domain,
                isHideLogo: s?.advertising?.isHideLogo,
                isPublishApp: s?.webApp?.isPublishApp,
                appTitle: s?.webTag?.title || '未命名应用',
                appLogo: s?.webTag?.logo,
                appDescription: host
            }),
            [host]
        )
    )

    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: closeCurrentPageLayerStack } = useAtomAction(closeCurrentPageLayerStackAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    /**
     * 关闭所有页面弹窗，并推入历史最近的页面形式栈
     */
    const { run: closeAllPageLayers } = useAtomAction(closeAllPageLayerAtom)

    const isFormExistValue = useMemo(
        () =>
            Object.entries(lastInnerStack?.blockRuntimeState.formContainer ?? {}).some(([blockId, item]) => {
                return item.changed
            }),
        [lastInnerStack?.blockRuntimeState.formContainer]
    )

    const handleModalClose = useCallback(() => {
        if (!isFormExistValue) {
            closeAllPageLayers()
            return
        }
        Modal.confirm({
            title: '表单中的数据未提交',
            content: `关闭页面后数据不会保存`,
            okText: '关闭页面',
            target: PAGE_CONTAINER_HOST
        })
            .then(isConfirm => {
                isConfirm && closeAllPageLayers()
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [closeAllPageLayers, isFormExistValue])

    const handleModalBack = useCallback(() => {
        if (!isFormExistValue) {
            closeCurrentPageLayerStack()
            return
        }
        Modal.confirm({
            title: '表单中的数据未提交',
            content: `关闭页面后数据不会保存`,
            okText: '关闭页面',
            target: PAGE_CONTAINER_HOST
        })
            .then(isConfirm => {
                isConfirm && closeCurrentPageLayerStack()
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [closeCurrentPageLayerStack, isFormExistValue])

    const layer = useMemo(() => {
        if (!lastInnerStack) {
            return
        }

        const { pageId, appId, dsId, recordId, stackId, stackDisplayType } = lastInnerStack
        if (!stackId) {
            return
        }

        switch (stackDisplayType) {
            case RecordOpenType.modal:
            case RecordOpenType.drawer: {
                return {
                    type: stackDisplayType,
                    props: {
                        target: PAGE_CONTAINER_HOST,
                        title: innerPageName,
                        classNames: {
                            modal: PAGE_MODAL
                        },
                        onClose: handleModalClose,
                        leftSlot: pageInnerStack.length > 1 && (
                            <BackButton onClick={handleModalBack}>
                                <IconFont type="ArrowLeftSmall" color="var(--color-gray-400)" size={16} />
                            </BackButton>
                        ),
                        children: (
                            <NestedPageWrapper
                                pageId={pageId}
                                appId={appId}
                                envId={envId}
                                dsId={dsId}
                                recordId={recordId}
                                stackId={stackId}
                            />
                        )
                    }
                } as PageOverlayState
            }

            default: {
                break
            }
        }
    }, [lastInnerStack, innerPageName, handleModalClose, pageInnerStack.length, handleModalBack, envId])

    const asideType = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.state?.asideType, [])
    )

    const onSelectNavBar = useCallback(() => {
        setPageStack(draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.state.asideType = AsideType.NAVBAR
                stack.state.selectedNode = undefined
            }
        })
    }, [rootPageId, setPageStack, stackId])

    // 导航栏点击回调
    const handleCommonCallBack = useCallback(
        (buttonEvents: ButtonEvents) => {
            const { params, handleEvent } = buttonEvents
            const isHaveNoneParam = !params.some(Boolean)
            if (isHaveNoneParam) {
                return
            }
            switch (handleEvent) {
                case 'openPage': {
                    const pageId = params[0].replace('/page/', '')
                    const isExitPage = allPageList.find(page => page.id === pageId)
                    if (commonPages.includes(pageId)) {
                        break
                    }
                    if (!isExitPage) {
                        break
                    }
                    openPageStack(
                        stackFactory({
                            appId,
                            pageId
                        })
                    )
                    break
                }
                case 'openLink': {
                    const linkUrl = params[0]
                    window.open(linkUrl)
                    break
                }
                default: {
                    break
                }
            }
        },
        [allPageList, appId, openPageStack]
    )

    const handleLogout = useCallback(() => {
        setAppRole(developerRole.userId)
    }, [setAppRole])

    const handleToLink = useCallback(
        (navigation?: NavigationBaseItem) => {
            if (!navigation) {
                return
            }
            const { value: linkUrl, jumpType } = navigation
            if (!linkUrl) {
                return
            }
            switch (jumpType) {
                case ApplicationSettingJumpType.linkUrl: {
                    return window.open(getJumpUrl(linkUrl))
                }
                case ApplicationSettingJumpType.page: {
                    if (commonPages.includes(linkUrl)) {
                        return
                    }
                    const pageId = linkUrl.replace('/page/', '')
                    openPageStack(stackFactory({ appId, pageId }))
                    break
                }
                default: {
                    break
                }
            }
        },
        [appId, openPageStack]
    )
    // 切换语言
    const { handleToggleLang } = useToggleLang()

    /** 检测是否有锚点信息，并滚动 */
    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                const el = document.querySelector(hash)
                el?.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }, 300)
        }
    }, [hash])

    // console.log('pageId --- rootPageId', pageId, rootPageId, visitPage)

    // useEffect(() => {
    //     if (rootPageId === pageId && (visitPage?.type === 'edit' || visitPage?.type === 'document')) {
    //         console.log('rootPageId === pageId', rootPageId, pageId)
    //     }
    // }, [pageId, rootPageId, visitPage?.type])

    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId,
        rootPageId
    })

    const renderLabel = useVariableValueRender(prev.recordId, curr.recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true }),
        [renderLabel]
    )

    const palettes = useAtomData(
        applicationSettingAtom,
        useCallback(s => {
            return s?.theme.palettes ?? []
        }, [])
    )
    const backgroundStyle = useMemo(() => {
        const styles = {
            ...combineBackgroundStyle([
                getBackgroundStyle(visitPage?.design?.background, parseBackgroundVariableImage, palettes),
                getVeinsStyle(visitPage?.design?.veins, palettes)
            ])
        }

        return {
            ...styles,
            backgroundColor: styles.backgroundColor || '#fff'
        }
    }, [palettes, parseBackgroundVariableImage, visitPage?.design?.background, visitPage?.design?.veins])

    const appThemeColor = useAtomData(applicationThemeColorAtom)
    const customBuiTheme = createTheme({ colors: { colorPrimary: appThemeColor, colorPrimaryHover: appThemeColor } })

    return (
        <RootPageProvider value={rootPageId}>
            {/* <SCxPageContainer> */}
            <SCxPageBody key={`${pageId}-${curr?.recordId}`}>
                <SCxPageContent className={customBuiTheme}>
                    {isMobile && <PageToolMobile />}
                    <ByecodeUIThemeProvider colorPrimary={appThemeColor} colorPrimaryHover={appThemeColor}>
                        <SCxPageWrapper isMobile={isMobile}>
                            <AppThemeContextProvider theme={theme}>
                                <ApplicationContainer
                                    id={PAGE_CONTAINER_HOST_ID}
                                    application={app}
                                    pageList={allRolePageList}
                                    topNavbar={!isMobile && <PageTool style={{ borderRadius: '12px 12px 0 0' }} />}
                                    selected={AsideType.NAVBAR === asideType}
                                    previewMode={isMobile ? 'simulationMobile' : 'simulationWebsite'}
                                    activePage={pageId}
                                    language={language}
                                    userData={user}
                                    styles={{
                                        layout: backgroundStyle,
                                        mobileHeader: {
                                            cursor: 'pointer'
                                        }
                                    }}
                                    key={developerRoleUserId}
                                    onCommonCallBack={handleCommonCallBack}
                                    onSelect={onSelectNavBar}
                                    onLogout={handleLogout}
                                    onToLink={handleToLink}
                                    onChangeLanguage={handleToggleLang}
                                    extraNode={
                                        <PageLogo
                                            isMobile={isMobile}
                                            isHideLogo={isHideLogo}
                                            isPublishApp={isPublishApp}
                                            appTitle={appTitle}
                                            appLogo={appLogo}
                                            appDescription={appDescription}
                                            defaultEnd={defaultEnd}
                                            onEnd={() => setDefaultEnd(true)}
                                        />
                                    }
                                >
                                    <SCxContainer id={PAGE_LAYOUT_HOST} style={{}}>
                                        <div style={{ backgroundColor: 'pink', height: 0 }}>
                                            <MessageContainer isMobile={isMobile} containerId={PAGE_CONTAINER_HOST_ID} />
                                        </div>
                                        {isVisiblePage && developerRoleUserId ? <PageContainer {...props} /> : <PageNoAccess />}
                                        <PageOverlay layerProps={layer} />
                                        {isDragLoading && <DragLoading />}
                                    </SCxContainer>
                                </ApplicationContainer>
                            </AppThemeContextProvider>
                        </SCxPageWrapper>
                    </ByecodeUIThemeProvider>
                    {/* 数据抽屉 */}
                    <PageFooter />
                </SCxPageContent>

                {/* 右侧设置栏 */}
                <SettingAside />
            </SCxPageBody>
        </RootPageProvider>
    )
}
// appContainer
