import { useAtomAction } from '@lighthouse/shared'
import React, { useEffect } from 'react'

import { setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { RolePanel } from '@/components/UserSetting'

export const Role: React.FC = () => {
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    useEffect(() => {
        setCurrentDataSourceId({ dsId: 'role' })
    }, [setCurrentDataSourceId])

    return <RolePanel />
}
