import { Checkbox, Empty } from '@byecode/ui'
import type { AppDepartment, AppDepartmentWidthChild } from '@lighthouse/shared'
import React from 'react'

import { departmentGroupsTitle } from '../constant'
import DepartmentGroupItem from '../DepartmentItem'
import * as CM from '../styles'

interface DepartmentListProps {
    departmentTree?: AppDepartmentWidthChild[]
    departments?: AppDepartment[]
    selectDepartmentIds: string[]
    onSelectDepartment?: (departments: string[]) => void
    onAddDepartment?: (id: string) => void
    onEditDepartment?: (departmentId: string) => void
    onDeleteDepartment?: (departmentId: string[]) => void
}

const DepartmentList: React.FunctionComponent<DepartmentListProps> = ({
    departmentTree = [],
    selectDepartmentIds,
    departments = [],
    onSelectDepartment,
    onAddDepartment,
    onDeleteDepartment,
    onEditDepartment
}) => {
    return (
        <CM.TableContainer>
            <CM.TableHeader>
                {departmentGroupsTitle.map(title => (
                    <CM.Item key={title.id} span={title.xs} textAlign={title.textAlign} justifyContent={title.justifyContent}>
                        {title.id === 'checkbox' ? (
                            <Checkbox
                                size="xs"
                                checked={selectDepartmentIds.length === departments.length && selectDepartmentIds.length > 0}
                                onChange={e =>
                                    onSelectDepartment?.(e.target.checked ? departments.map(draft => draft.id) : [])
                                }
                            />
                        ) : (
                            <CM.TableColumnName>{title.name}</CM.TableColumnName>
                        )}
                    </CM.Item>
                ))}
            </CM.TableHeader>
            <CM.TableContent>
                {departmentTree.length === 0 && <Empty icon="SpaceTeam" styles={{ root: { height: 300 } }}  description="没有部门" />}
                {departmentTree.map(department => (
                    <DepartmentGroupItem
                        department={department}
                        selectDepartmentIds={selectDepartmentIds}
                        key={department.id}
                        level={0}
                        onDeleteDepartment={onDeleteDepartment}
                        onEditDepartment={onEditDepartment}
                        onAddDepartment={onAddDepartment}
                        onSelectDepartment={onSelectDepartment}
                    />
                ))}
            </CM.TableContent>
        </CM.TableContainer>
    )
}

export default DepartmentList
