import {
    AdvancedTableBlock,
    CalendarBlock,
    CustomViewBlock,
    GalleryBlock,
    KanbanBoardBlock,
    ListBlock,
    TableBlock
} from '@lighthouse/block'
import type {
    AiFieldStatus,
    AppUser,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    KanbanColumnsSort,
    RecordLikeProtocol,
    RecordOpenType,
    RichTextContentProtocol,
    SelectedMode,
    TableColumns,
    TableColumnWidth,
    ViewBlockAbstract
} from '@lighthouse/core'
import { type ApplicationPreviewEnum, type FlowLayoutNode, useAppContainerContext, useAtomData } from '@lighthouse/shared'
import type { BreakPointSize } from '@lighthouse/tools'
import type { atomWithImmer } from 'jotai-immer'
import React, { useCallback, useMemo } from 'react'

import { pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { uploadInDataSourceManagerParams, uploadVideoInDataSourceManagerParams } from '@/utils/auth'

interface ViewBlockRenderProps {
    envId: string
    // common
    dataSource: DataSourceAbstract
    blockData: ViewBlockAbstract
    // 视图
    dataSourceList: DataSourceAbstract[]
    records?: RecordLikeProtocol[]
    previewType: ApplicationPreviewEnum
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords: string[]
    blockWidth: number
    breakPoint: BreakPointSize
    tablePropsCache: TableColumns
    tableColumnCache?: TableColumnWidth
    cachedKanbanSort?: KanbanColumnsSort
    // 视图
    onSelectedRecords: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordAdd: (initialRecordValue?: Record<string, string | number>) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    // 高级视图
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    // 日历视图
    onUpdateRecord: (recordId: string, content: RecordLikeProtocol['content']) => Promise<RecordLikeProtocol>

    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>

    onSelectModeChange?: (mode?: SelectedMode) => void

    onTableColumnWidthChange: (val: TableColumnWidth) => void

    onChangeCachedKanbanSort: (val: KanbanColumnsSort | undefined) => void

    pageTarget?: string
    // 自定义视图
    node: FlowLayoutNode
}

export const ViewBlockRender: React.FC<ViewBlockRenderProps> = props => {
    const { envId, blockData, dataSource } = props
    const { config, id } = blockData
    const { viewType } = config
    const { scale } = useAppContainerContext()

    const getVideoUploadOptions = useCallback(
        (fieldId: string, recordId: string) => ({
            info: { id: '', label: dataSource.name, groupId: dataSource.id },
            options: uploadVideoInDataSourceManagerParams({ envId, dsId: dataSource.id, fieldId, recordId })
        }),
        [dataSource.id, dataSource.name, envId]
    )

    const getUploadOptions = useCallback(
        (fieldId: string, recordId: string) => ({
            info: { id: '', label: dataSource.name, groupId: dataSource.id },
            options: uploadInDataSourceManagerParams({ envId, dsId: dataSource.id, fieldId, recordId })
        }),
        [dataSource.id, dataSource.name, envId]
    )

    const getRichTextUploadOptions = useCallback(
        (fieldId: string, recordId: string) => uploadInDataSourceManagerParams({ envId, dsId: dataSource.id, fieldId, recordId }),
        [dataSource.id, envId]
    )

    const uploadData = useMemo(
        () => ({
            getUploadOptions,
            getRichTextUploadOptions,
            getVideoUploadOptions
        }),
        [getRichTextUploadOptions, getUploadOptions, getVideoUploadOptions]
    )

    const stackId = useCurrentStackIdContext()
    const rootPageId = useRootPageContext()
    const [selectedNode, displayType]: [string, RecordOpenType] = useAtomData(
        pageStackAtomFamily({ stackId, rootPageId }),
        useCallback(s => [s?.state.selectedNode ?? '', s?.stackDisplayType ?? 'page'], [])
    )

    return useMemo(() => {
        switch (viewType) {
            case 'table': {
                return <TableBlock {...props} />
            }
            case 'advancedTable': {
                return <AdvancedTableBlock {...props} {...uploadData} />
            }
            case 'list': {
                return <ListBlock {...props} />
            }
            case 'gallery': {
                return <GalleryBlock scale={scale} {...props} />
            }
            case 'kanban': {
                return <KanbanBoardBlock {...props} />
            }
            case 'calendar': {
                return <CalendarBlock {...props} />
            }
            case 'custom': {
                return <CustomViewBlock scale={scale} showAction={selectedNode === id} {...props} />
            }
            default: {
                return null
            }
        }
    }, [id, props, scale, selectedNode, uploadData, viewType])
}
