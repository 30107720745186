import { type AppUser, type PageAbstract, PageOpenType } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { find, reduce } from 'rambda'
import { useMemo } from 'react'

import { commonRoleIds } from '@/atoms/application/constants'
import { appRoleAtom } from '@/atoms/application/state'
import { useDataSourceDepartments, useDataSourceUsers } from '@/shared/reusable'

import { usePageList } from './usePage'

export const useUserPageList = function () {
    const developerRoleUserId = useAtomData(appRoleAtom)
    const pageList = usePageList()
    const { data: users = [] } = useDataSourceUsers()
    const departments = useDataSourceDepartments()

    const currentUser = useMemo(() => find(user => user.userId === developerRoleUserId, users), [developerRoleUserId, users])

    const userPageList = useMemo(() => {
        const currentUserRoleId = currentUser?.roleId
        const currentUserDepartmentIds = currentUser?.departmentIds ?? []
        const noNeedPageList = pageList.filter(item => item.open === PageOpenType.all)
        if (developerRoleUserId === 'visitor') {
            return noNeedPageList
        }
        if (developerRoleUserId === 'developer') {
            return pageList
        }
        return reduce<PageAbstract, PageAbstract[]>(
            (preVal, curVal) => {
                if (curVal.open === PageOpenType.part) {
                    const { roleIds = [], departmentIds = [] } = curVal
                    const isUserExitGroup = currentUserRoleId && roleIds.includes(currentUserRoleId)

                    const isUserExitDepartment = departmentIds.some((departmentId) =>
                        currentUserDepartmentIds.includes(departmentId)
                    )

                    if (isUserExitGroup || isUserExitDepartment) {
                        return [...preVal, curVal]
                    }
                    return preVal
                }
                return [...preVal, curVal]
            },
            [],
            pageList
        )
    }, [currentUser?.roleId, currentUser?.departmentIds, pageList, developerRoleUserId])

    const allRolePageList = useMemo(() => {
        const currentUserRoleId = currentUser?.roleId
        if (developerRoleUserId === 'visitor') {
            return pageList.map(page => ({ ...page, isAccess: page.open === PageOpenType.all }))
        }
        if (developerRoleUserId === 'developer') {
            return pageList.map(page => ({ ...page, isAccess: true }))
        }
        return pageList.map(page => {
            if (page.open === PageOpenType.part) {
                const { roleIds = [], departmentIds = [] } = page

                const isUserExitGroup = currentUserRoleId && roleIds.includes(currentUserRoleId)

                const isUserExitDepartment = departmentIds.some(id => currentUser?.departmentIds?.includes(id))

                return { ...page, isAccess: Boolean(isUserExitDepartment || isUserExitGroup) }
            }
            return { ...page, isAccess: true }
        })
    }, [currentUser?.roleId, currentUser?.departmentIds, developerRoleUserId, pageList])

    const user: AppUser | undefined = useMemo(() => {
        if (commonRoleIds.includes(developerRoleUserId)) {
            return
        }
        return currentUser
    }, [currentUser, developerRoleUserId])

    return useMemo(() => ({ visitPageList: userPageList, user, developerRoleUserId, allRolePageList }), [allRolePageList, developerRoleUserId, user, userPageList])
}
