import type { AppDepartment, AppDepartmentWidthChild } from '@lighthouse/shared'
import { current } from 'immer'
import { clone, find } from 'rambda'

export function getDepartmentParentIds(id: string, list: AppDepartment[], arr: string[]): string[] {
    const department = find(item => item.id === id, list)
    return department?.parentId ? getDepartmentParentIds(department.parentId, list, [...arr, department.parentId]) : arr
}

export function getDepartmentChildIds(id: string, list: AppDepartment[], arr: string[]): string[] {
    const department = find(item => item.id === id, list)
    if (!department) {
        return arr
    }
    const departmentChildList = list.filter(d => d.parentId === id)
    for (const departmentChild of departmentChildList ?? []) {
        arr = getDepartmentChildIds(departmentChild.id, list, [...arr, departmentChild.id])
    }
    return arr
}

export function getFlatDepartmentChild(department: AppDepartmentWidthChild, arr: AppDepartmentWidthChild[]): AppDepartment[] {
    const { id, children = [] } = department
    let newArr = arr
    for (const element of children) {
        newArr = getFlatDepartmentChild(element, [...newArr, { ...element, parentId: id }])
    }
    return newArr
}

export function getFlatDepartments(list: AppDepartmentWidthChild[]): AppDepartment[] {
    let newArr: AppDepartment[] = []
    for (const element of list) {
        newArr = getFlatDepartmentChild(element, [...newArr, {...element, children: []}])
    }
    return newArr
}


