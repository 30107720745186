import type { SelectProps } from '@byecode/ui'
import { Flex, IconFont, Menu } from '@byecode/ui'
import { TagIcon } from '@lighthouse/shared'
import { find, map } from 'rambda'
import * as React from 'react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { useDataSourceRoles } from '@/shared/reusable'

type RoleSelectProps = SelectProps

const SCxClear = styled(TagIcon)`
    cursor: pointer;
    &:hover {
        color: var(--color-black);
    }
`

const RoleSelect: React.FC<RoleSelectProps> = ({ value, onChange, ...rest }) => {
    const roles = useDataSourceRoles()
    const [opened, setOpened] = useState(false)

    const roleOptions = useMemo(
        () =>
            map(
                item => ({
                    label: item.name,
                    value: item.id
                    // icon: 'SpaceTeam'
                }),
                roles
            ),
        [roles]
    )

    const currentItem = useMemo(() => find(item => item.value === value, roleOptions), [roleOptions, value])

    return (
        <Menu opened={opened} onChange={setOpened} trigger="click" withinPortal position="bottom-start" closeOnItemClick width={160}>
            <Menu.Target>
                <Flex justifyContent="space-between" gap={6}>
                    {currentItem?.label ?? '未选择'}
                    <Flex gap={4} alignItems="center">
                        {currentItem && (
                            <SCxClear
                                icon="CloseCircle"
                                color="var(--color-gray-400)"
                                hoverBackground="var(--color-black)"
                                size={16}
                                iconSize={16}
                                onClick={e => {
                                    e.stopPropagation()
                                    onChange?.('')
                                }}
                            />
                        )}
                        <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />
                    </Flex>
                </Flex>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Dropdown>
                    {roleOptions.map(item => (
                        <Menu.Item
                            key={item.value}
                            onClick={() => {
                                onChange?.(item.value)
                            }}
                        >
                            {item.label}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu.Dropdown>
        </Menu>
    )
}

export default RoleSelect
