import { ClickAwayListener, IconFont, Popover, Tooltip } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type ApplicationAbstract, type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import type { DataSourceItemAction, MenuItemChildrenItems } from '@lighthouse/shared'
import { FindUseType, getTableIcon, TooltipText } from '@lighthouse/shared'
import cls from 'classnames'
import { useAtomValue } from 'jotai'
import { find, isEmpty } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { dataSourceEnvAtom } from '@/atoms/dataSource/state'
import type { MoveDataSourceAtomPayload } from '@/atoms/dataSource/types'
import { useFindUseContext } from '@/contexts/PageContext'

// import { appAtom } from '@/atoms/application/state'
import { List } from '../List'
import type { MenuItem } from './OperationMenuItem'
import { OperationMenuItem } from './OperationMenuItem'
import * as SC from './styles'

export interface TreeListItem extends DataSourceAbstract {
    children?: DataSourceAbstract[]
}

interface DataSourceItemProps {
    appId: string
    envId: string
    dataSource: TreeListItem
    activeId?: string
    isTail: boolean
    dataSourceList: DataSourceAbstract[]
    applicationList: ApplicationAbstract[]
    level: number
    onMoveDataSource?: (params: MoveDataSourceAtomPayload) => void
    onSelectDataSource: (dsId: string) => void
    onUpdateDataSource?: (dsId: string, value: string) => Promise<boolean>
    onDeleteDataSource?: (dsId: string, name: string) => Promise<boolean>
    onDuplicateDataSource?: (dsId: string) => void
    onSwitchSyncDs?: (envId: string, dsId: string, switchEnvId: string) => void
}

const getCollapseState = (currentDataSource: DataSourceAbstract, activeId: string, dataSourceList: DataSourceAbstract[]) => {
    const activeDs = find(item => item.id === activeId, dataSourceList)

    if (!activeDs) {
        return false
    }
    const { type: activeDsType, viewOptions: activeDsViewOptions } = activeDs
    if (activeDsType !== DataSourceType.joinDataSource) {
        return false
    }
    return activeDsViewOptions.joinConfig?.primaryDsId === currentDataSource.id
}

export const menuOperationItems: MenuItem<DataSourceItemAction>[] = [
    {
        section: '操作',
        items: [
            {
                name: '重命名',
                icon: 'PencilSimple',
                hotkey: '',
                action: 'RENAME'
            },
            {
                name: '切换同步表环境',
                icon: 'TransferData',
                hotkey: '',
                action: 'SWITCH_ENV',
                extra: <IconFont type="ArrowRightSmall" size={16} fill="var(--color-gray-400)" />
            },
            {
                name: '查找使用',
                icon: 'Search',
                hotkey: '',
                action: 'FINDUSE'
            },
            {
                name: '复制',
                icon: 'Duplicate',
                hotkey: '',
                action: 'DUPLICATE'
            },
            {
                name: '删除',
                icon: 'Trash',
                hotkey: '',
                action: 'DELETE'
            }
        ]
    }
]

const Item: React.FunctionComponent<DataSourceItemProps> = ({
    appId,
    envId,
    dataSource,
    activeId,
    isTail,
    dataSourceList,
    applicationList,
    level,
    onMoveDataSource,
    onSelectDataSource,
    onDeleteDataSource,
    onDuplicateDataSource,
    onUpdateDataSource,
    onSwitchSyncDs
}) => {
    const { id, sync, syncAppName, syncDsName, syncEnvName, type, children } = dataSource
    const dsLengthRef = useRef(children?.length ?? 0)
    const { onChange: findUseChange } = useFindUseContext()
    // const application = useMemo(() => find(app => app.id === appId, applicationList || []), [applicationList, appId])
    const dataSourceEnv = useAtomValue(dataSourceEnvAtom)
    const [open, setOpen] = useState(false)
    const [collapse, setCollapse] = useState(getCollapseState(dataSource, activeId || '', dataSourceList))
    const [val, setVal] = useState(dataSource?.name || '')
    const [isEdit, setIsEdit] = useState(false)

    const isNotDuplicateDataSource = useMemo(() => {
        return type === DataSourceType.joinDataSource && sync
    }, [sync, type])

    const isNotSwitchEnvDataSource = useMemo(() => {
        return !sync
    }, [sync])

    const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({
        id,
        data: {
            isTail
        },
        disabled: isEdit
    })

    const isCollapse = useMemo(() => children && children.length > 0, [children])

    useUpdateEffect(() => {
        const newChildrenLength = children?.length ?? 0
        if (newChildrenLength > dsLengthRef.current) {
            dsLengthRef.current = newChildrenLength
            setCollapse(true)
        }
    }, [children?.length])

    // const joinDataSource = useMemo(() => {
    //     if (type !== 5) {
    //         return
    //     }
    //     if (sync) {
    //         return {
    //             name: primaryName
    //         }
    //     }
    //     const { joinConfig } = viewOptions
    //     const joinDsId = joinConfig?.primaryDsId
    //     return find(item => item.id === joinDsId, dataSourceList)
    // }, [dataSourceList, primaryName, sync, type, viewOptions])

    const linkContent = useMemo(() => {
        if (!dataSourceEnv) {
            return null
        }
        const { link, linked } = dataSourceEnv
        if (!link && (!linked || isEmpty(linked))) {
            return null
        }
        let toolTipLabel = null
        if (link) {
            const linkApplication = find(app => app.id === link.appId, applicationList || [])
            if (!linkApplication) {
                return null
            }
            toolTipLabel = <SC.LinkInfo key={id}>已使用 {linkApplication.name} 的用户</SC.LinkInfo>
        }
        if (linked && !isEmpty(linked)) {
            toolTipLabel = (
                <>
                    {linked.map(item => {
                        const linkApplication = find(app => app.id === item.appId, applicationList || [])
                        if (!linkApplication) {
                            return null
                        }
                        return <SC.LinkInfo key={id}>该应用的用户被 {linkApplication.name} 使用</SC.LinkInfo>
                    })}
                </>
            )
        }
        return (
            <SC.RightItem>
                <Tooltip title={toolTipLabel}>
                    <SC.Link>
                        <SC.LinkIcon type="TransferData" size={12} fill="var(--color-gray-400)" />
                    </SC.Link>
                </Tooltip>
            </SC.RightItem>
        )
    }, [applicationList, dataSourceEnv, id])

    const handleUpdateDataSource = useCallback(async () => {
        setIsEdit(false)
        if (dataSource?.name === val) {
            return
        }
        if (!val.trim()) {
            setVal(dataSource?.name || '')
            return
        }
        const isUpdate = await onUpdateDataSource?.(id, val)
        if (!isUpdate) {
            setVal(dataSource?.name || '')
        }
    }, [id, dataSource?.name, onUpdateDataSource, val])

    const handlers = useCallback(
        (dsId: string): Record<DataSourceItemAction, (sender: React.MouseEvent<HTMLDivElement>, params?: { envId: string }) => void> => {
            return {
                DELETE(ev) {
                    ev?.stopPropagation()
                    setOpen(false)
                    onDeleteDataSource?.(dsId, dataSource?.name || '')
                },
                DUPLICATE(ev) {
                    ev?.stopPropagation()
                    setOpen(false)
                    onDuplicateDataSource?.(dsId)
                },
                RENAME(ev) {
                    ev?.stopPropagation()
                    setOpen(false)
                    setIsEdit(true)
                },
                SWITCH_ENV(ev, params) {
                    ev?.stopPropagation()
                    if (!params) {
                        return
                    }
                    const { envId: switchEnvId } = params
                    onSwitchSyncDs?.(envId, dsId, switchEnvId)
                    setOpen(false)
                },
                FINDUSE(ev) {
                    ev?.stopPropagation()
                    setOpen(false)
                    if (findUseChange) {
                        findUseChange({
                            open: true,
                            config: {
                                type: FindUseType.DATASHEET,
                                dataSheet: {
                                    dsId: id
                                }
                            }
                        })
                    }
                }
            }
        },
        [dataSource?.name, envId, findUseChange, id, onDeleteDataSource, onDuplicateDataSource, onSwitchSyncDs]
    )

    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition
        }),
        [transform, transition]
    )

    const handleKeyDown = useCallback(
        (ev: React.KeyboardEvent) => {
            if (id !== 'USER_DATASOURCE' && ev.key === 'Enter') {
                handleUpdateDataSource()
            }
        },
        [handleUpdateDataSource, id]
    )

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setVal(e.target.value)
    }, [])

    const handleChangeEdit = useCallback(() => {
        if (id !== 'USER_DATASOURCE') {
            setIsEdit(true)
        }
    }, [id])

    const tableIcon = getTableIcon(dataSource)

    const syncDataSourceContent = useMemo(() => {
        if (!sync) {
            return null
        }
        return (
            <SC.SyncDataSourceInfo>
                <SC.SyncDataSourceContent>
                    <SC.SyncDataSourceText>表来自应用：{syncAppName || ''}</SC.SyncDataSourceText>
                    <SC.SyncDataSourceText>环境：{syncEnvName || ''}</SC.SyncDataSourceText>
                    <SC.SyncDataSourceText>同步的表：{syncDsName}</SC.SyncDataSourceText>
                </SC.SyncDataSourceContent>
                <SC.SyncDataSourceIcon>
                    <IconFont type="ArrowsClockwise" size={16} fill="var(--color-gray-400)" />
                </SC.SyncDataSourceIcon>
            </SC.SyncDataSourceInfo>
        )
    }, [syncEnvName, sync, syncAppName, syncDsName])

    const menuItems = useMemo(
        () =>
            menuOperationItems.map(item => {
                return {
                    section: item.section,
                    items: item.items.reduce<MenuItemChildrenItems<DataSourceItemAction>[]>((prev, cur) => {
                        if (cur.action === 'DUPLICATE' && isNotDuplicateDataSource) {
                            return prev
                        }
                        if (cur.action === 'SWITCH_ENV' && isNotSwitchEnvDataSource) {
                            return prev
                        }
                        prev.push(cur)
                        return prev
                    }, [])
                }
            }),
        [isNotDuplicateDataSource, isNotSwitchEnvDataSource]
    )

    const extraContent = useMemo(() => {
        if (!dataSource) {
            return null
        }
        if (id === 'USER_DATASOURCE') {
            return linkContent
        }

        return (
            <SC.RightItem>
                <Popover opened={open} onChange={setOpen} width="auto" withinPortal position="bottom-start">
                    <Popover.Target>
                        <SC.RightIcon type="DotsThree" active={open} />
                    </Popover.Target>
                    <Popover.Dropdown>
                        <OperationMenuItem
                            data={dataSource}
                            envId={envId}
                            canSwitchEnv={!isNotSwitchEnvDataSource}
                            menuItems={menuItems}
                            handlers={handlers}
                            extra={syncDataSourceContent}
                        />
                    </Popover.Dropdown>
                </Popover>
            </SC.RightItem>
        )
    }, [dataSource, envId, handlers, id, isNotSwitchEnvDataSource, linkContent, menuItems, open, syncDataSourceContent])

    return (
        <SC.ListContainer>
            <SC.ListItem
                key={id}
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
                className={cls({ operate: open, active: activeId === id })}
            >
                <SC.Prefix width={20 * level} />
                <SC.LeftItem>
                    {isCollapse && <SC.Arrow onClick={() => setCollapse(!collapse)} active={collapse} type="ArrowRightSmall" size={12} />}
                </SC.LeftItem>
                <SC.CenterItem onClick={() => onSelectDataSource(id)}>
                    <SC.DataSourceIcon type={tableIcon} style={{ marginRight: 7, marginTop: 4 }} size={16} />
                    <SC.ListGroup>
                        {isEdit ? (
                            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleUpdateDataSource}>
                                <SC.TitleInput
                                    value={val}
                                    placeholder="请输入名称"
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    autoSelect
                                />
                            </ClickAwayListener>
                        ) : (
                            <TooltipText
                                title={val}
                                render={ref => (
                                    <SC.ListGroupTitle ref={ref} onDoubleClick={handleChangeEdit}>
                                        {val}
                                    </SC.ListGroupTitle>
                                )}
                            />
                        )}
                        {sync && (
                            <SC.Description>
                                {syncAppName}/{syncEnvName}
                            </SC.Description>
                        )}
                    </SC.ListGroup>
                </SC.CenterItem>
                {extraContent}
            </SC.ListItem>
            {!isSorting && collapse && isCollapse && (
                <List
                    appId={appId}
                    envId={envId}
                    allDataSource={dataSourceList}
                    dataSourceList={children || []}
                    applicationList={applicationList}
                    activeId={activeId}
                    level={level + 1}
                    onMoveDataSource={onMoveDataSource}
                    onSelectDataSource={onSelectDataSource}
                    onUpdateDataSource={onUpdateDataSource}
                    onDuplicateDataSource={onDuplicateDataSource}
                    onDeleteDataSource={onDeleteDataSource}
                />
            )}
        </SC.ListContainer>
    )
}

export default Item
