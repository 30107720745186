import { Divider, Flex, Group, Text, Tooltip } from '@byecode/ui'
import { type ApplicationSettingAuthentication, ApplicationSettingPerfectMode } from '@lighthouse/core'
import { spaceVersionEnum, useAtomAction, useAtomData, useLimitRetryAction } from '@lighthouse/shared'
import { ScrollArea } from '@mantine/core'
import Uploady from '@rpldy/uploady'
import equal from 'fast-deep-equal'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { fetchAppSettingAtom, updateAppVersionConfigAtom } from '@/atoms/application/action'
import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { AppSmsCard } from '@/components/AppSmsCard'
import { SwitchControl } from '@/components/BlockSettings/Common/SwitchControl'
import { openSpaceGradeConfirm, SpaceGradeTag } from '@/components/SpaceGrade'
import { TagIcon } from '@/components/TagIcon'
import { useLanguage } from '@/hooks/useApplication'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { useDataSourceRoles, useSpaceQuota } from '@/shared/reusable'
import { uploadManagerInAppParams } from '@/utils/auth'

import SettingBlock from '../SettingBlock'
import * as CM from '../styles'
import { LoginBlock } from './LoginBlock'
import { LoginWeChat } from './LoginWeChat'
import Logo from './Logo'
import Name from './Name'
import PerfectFieldDetail from './PerfectFieldDetail'
import PerfectGroupSetting from './PerfectGroupSetting'
import PerfectWay from './PerfectWay'

export const SCxContainer = styled.form`
    width: 360px;
    /* padding: 20px 12px; */
    flex-shrink: 0;
    height: 100%;
    max-height: 100%;
    background-color: #fff;
    font-size: var(--font-size-normal);
`
const SCxWrapper = styled(ScrollArea)`
    width: 100%;

    .mantine-ScrollArea-viewport {
        padding-bottom: 100px;
        > div {
            display: block !important;
        }
    }
`

interface SettingAuthenticationProps {}

const SettingAuthentication: React.FC<SettingAuthenticationProps> = () => {
    const authentication = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.authentication, [])
    )
    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)
    const { run: fetchAppSetting } = useAtomAction(fetchAppSettingAtom)
    const { data: quota } = useSpaceQuota()

    const methods = useForm<ApplicationSettingAuthentication>({
        mode: 'onChange',
        defaultValues: authentication
    })

    const resetLoadingRef = useRef(false)
    const { reset, watch, control } = methods

    const [perfectMode, roleSetting = [], logo] = watch(['perfect.mode', 'perfect.roleSetting', 'logo'])

    const roles = useDataSourceRoles()

    const language = useLanguage()

     
    const roleIds = useMemo(() => roles.map(role => role.id).sort().join(','), [roles])
     
    const groupSettingIds = useMemo(() => roleSetting?.map(setting => setting.roleId).sort().join(','), [roleSetting])

    // 监听角色删除添加时更新自定义应用注册设置
    const getAppSetting = useCallback(async (isChange?: boolean) => {
        const data = await fetchAppSetting()
         
        const newConfigGroupIds = data.config.authentication.perfect.roleSetting?.map(setting => setting.roleId)?.sort().join(',')
        const isEqualGroupLength = newConfigGroupIds === roleIds
        if (isEqualGroupLength || isChange) {
            resetLoadingRef.current = true
            reset(data.config.authentication)
            setTimeout(() => {
                resetLoadingRef.current = false
            }, 1000)
        }
    }, [fetchAppSetting, roleIds, reset])

    const { retry } = useLimitRetryAction(getAppSetting)

    useEffect(() => {
        if (!equal(groupSettingIds, roleIds)) {
            retry()
        }
    }, [getAppSetting, roleIds, groupSettingIds, retry])

    useUpdateEffect(() => {
        getAppSetting(true)
    }, [language])

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingAuthentication) => {
                const isSuccess = await updateAppVersionConfig({
                    config: {
                        authentication: value
                    }
                })
            }),
        [updateAppVersionConfig]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, oldValue) => {
            if (!resetLoadingRef.current) {
                handleSubmit(clone(value) as ApplicationSettingAuthentication)
            }
        })
        return unsubscribe
    }, [handleSubmit, watch])

    const settingDetailEle = useMemo(() => {
        if (perfectMode === ApplicationSettingPerfectMode.ROLE) {
            return <PerfectGroupSetting />
        }
        return <PerfectFieldDetail prefix="perfect.commonSetting" />
    }, [perfectMode])

    const disabledWithVersion = useIsDisabledWithVersion()

    return (
        <SCxContainer>
            <SCxWrapper h="100%">
                <FormProvider {...methods}>
                    <CM.Title>登录设置</CM.Title>
                    <SettingBlock label="设计">
                        <Controller
                            name="logo"
                            control={control}
                            render={({ field }) => (
                                <Uploady accept="image/*" {...uploadManagerInAppParams()}>
                                    <Logo disabled={disabledWithVersion} {...field} />
                                </Uploady>
                            )}
                        />
                        <Controller name="name" control={control} render={({ field }) => <Name label="标题" {...field} />} />
                        <Controller name="describe" control={control} render={({ field }) => <Name label="描述" {...field} />} />
                    </SettingBlock>
                    <SettingBlock label="登录方式" isMargin>
                        <Flex direction="column" gap={12}>
                            <Controller
                                control={control}
                                name="login.phone.isOpened"
                                render={({ field }) => (
                                    <LoginBlock
                                        label={
                                            <Flex gap={8} alignItems="center">
                                                <Text> 短信验证码登录 </Text>
                                                {/* <SpaceGradeTag value={spaceVersionEnum.BASIC} /> */}
                                            </Flex>
                                        }
                                        checked={field.value}
                                        onChange={v => {
                                            // if (quota && quota?.currentVersionCode < spaceVersionEnum.BASIC) {
                                            //     openSpaceGradeConfirm('https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/rbv29zp2qdk26iz2', 'BASIC')
                                            //     return
                                            // }
                                            field.onChange(v)
                                        }}
                                        icon={
                                            <TagIcon
                                                size={32}
                                                iconSize={20}
                                                radius={100}
                                                iconColor="var(--color-blue-500)"
                                                background="var(--color-blue-50)"
                                                icon="SmartphoneIconLibrary"
                                            />
                                        }
                                    >
                                        <AppSmsCard />
                                    </LoginBlock>
                                )}
                            />

                            <Controller
                                name="login.email.isOpened"
                                control={control}
                                render={({ field }) => (
                                    <LoginBlock
                                        label="邮箱登录"
                                        disableCollapse
                                        checked={field.value}
                                        onChange={field.onChange}
                                        icon={
                                            <TagIcon
                                                size={32}
                                                iconSize={20}
                                                radius={100}
                                                iconColor="var(--color-green-7)"
                                                background="var(--color-green-1)"
                                                icon="LetterIconLibrary"
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="login.weChat"
                                control={control}
                                render={({ field }) => (
                                    <LoginWeChat
                                        value={field.value}
                                        label={
                                            <Flex gap={8} alignItems="center">
                                                <Text> 微信登录 </Text>
                                                <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />
                                            </Flex>
                                        }
                                        onChange={v => {
                                            if (quota && quota?.currentVersionCode < spaceVersionEnum.ADVANCED) {
                                                openSpaceGradeConfirm('ADVANCED')
                                                return
                                            }
                                            field.onChange(v)
                                        }}
                                    />
                                )}
                            />
                            {/* <Controller
                                name="login.google"
                                control={control}
                                render={({ field }) => (
                                    <LoginBlock
                                        label="谷歌登录"
                                        checked={field.value.isOpened}
                                        onChange={v => field.onChange({...field.value, isOpened: v})}
                                        icon={
                                            <TagIcon
                                                size={32}
                                                iconSize={20}
                                                radius={100}
                                                iconColor="var(--color-green-500)"
                                                background="var(--color-green-50)"
                                                icon="LetterIconLibrary"
                                            />
                                        }
                                    >
                                        <Select />
                                    </LoginBlock>
                                )}
                            /> */}
                        </Flex>
                    </SettingBlock>

                    <SettingBlock label="注册设置">
                        {/* <Controller
                            name="register.allowRegister"
                            control={control}
                            render={({ field }) => (
                                <SwitchControl
                                    label="显示注册入口"
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                />
                            )}
                        /> */}
                        <Controller
                            name="register.isReviewed"
                            control={control}
                            render={({ field }) => (
                                <SwitchControl
                                    label={
                                        <Flex gap={4}>
                                            <Text>注册审核</Text>
                                            <Tooltip
                                                title={
                                                    <Text style={{ width: 244 }}>
                                                        开启后，注册的用户需要管理员审核通过。
                                                        <p>
                                                            注意：使用微信登录，如果没有账号则会 自动注册账号，并且不会触发注册审核机 制。
                                                        </p>
                                                    </Text>
                                                }
                                            >
                                                <CM.Icon type="Question" size={16} />
                                            </Tooltip>
                                        </Flex>
                                    }
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                />
                            )}
                        />
                    </SettingBlock>
                    <Divider />
                    <Controller
                        name="perfect.isOpened"
                        control={control}
                        render={({ field }) => (
                            <Group
                                mode="switch"
                                opened={field.value}
                                onCollapseChange={field.onChange}
                                label={
                                    <Flex gap={4}>
                                        <Text>完善用户信息</Text>
                                        <Tooltip title="用户首次登录或注册后，需完善自身的用户信息">
                                            <CM.Icon type="Question" size={16} />
                                        </Tooltip>
                                    </Flex>
                                }
                            >
                                <Controller
                                    name="perfect.title"
                                    control={control}
                                    render={({ field }) => <Name label="完善信息标题" {...field} />}
                                />
                                <Controller
                                    name="perfect.describe"
                                    control={control}
                                    render={({ field }) => <Name label="完善信息描述" {...field} />}
                                />
                                {perfectMode === ApplicationSettingPerfectMode.ROLE && (
                                    <>
                                        <Controller
                                            name="perfect.selectRoleTitle"
                                            control={control}
                                            render={({ field }) => <Name label="选择角色标题" {...field} />}
                                        />
                                        <Controller
                                            name="perfect.selectRoleDescribe"
                                            control={control}
                                            render={({ field }) => <Name label="选择角色描述" {...field} />}
                                        />
                                    </>
                                )}
                                <Controller name="perfect.mode" control={control} render={({ field }) => <PerfectWay {...field} />} />
                                {settingDetailEle}
                            </Group>
                        )}
                    />
                </FormProvider>
            </SCxWrapper>
        </SCxContainer>
    )
}

export default SettingAuthentication
