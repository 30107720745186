import type { VariableADTvalue } from '@lighthouse/core'
import { type AppUser, type Field } from '@lighthouse/core'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import type { JSONContent } from '@tiptap/react'
import { EditorContent, Extension, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useCallback, useMemo } from 'react'
import { useLatest } from 'react-use'
import styled, { css } from 'styled-components'

import { numberFieldTypes } from '../../constants/dataSource'
import { numberReg } from '../../constants/reg'
import { useApplicationContext } from '../../contexts'
import type { VariableTree } from '../'
import { type VariableOptions, getSystemOption, VariableSelect } from '../'
import { isEmptyRichTextValue } from '../RichText'
import { DATE_SYSTEM, VariablePicker } from '../VariablePicker'
import { AddVariableButton } from './AddVariableButton'
import { VariableNode } from './DataQuote/VariableNode'
import { NodeFieldEditorProvider } from './EditorProvider'

const SCxContainer = styled.div<{ highlighting?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 7px;
    /* border: 1px solid var(--color-gray-200); */
    border: 1px solid transparent;
    background-color: var(--color-gray-100);

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
        `}

    &:focus-within {
        background-color: white;
        border: 1px solid var(--color-main);
    }

    & .node-editor {
        display: flex;
        width: 100%;
        padding: 6px 0 6px 8px;
        margin-right: 8px;
        line-height: 1.5;
        height: 100%;
        overflow: hidden;
        word-break: break-all;
    }

    & p {
        font-size: var(--font-size-normal);
        /* white-space: nowrap; */
    }

    & .ProseMirror {
        width: 100%;
        /* flex: 1; */
        /* white-space: nowrap; */
        /* align-self: stretch; */
    }

    & .ProseMirror p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
`

const SCxExtra = styled.div<{ width?: number }>`
    width: 30px;
    margin-top: 6px;
`

const singleVariableFieldTypes = new Set(['select', 'checkbox', 'person', 'date', 'number', 'role', 'department'])

interface NodeFieldPreviewProps {
    style?: React.CSSProperties
    field?: Field
    options?: VariableOptions
    // baseNodesData?: BaseNodesData[]
    value?: JSONContent
    placeholder?: string
    userOption?: VariableTree
    viewOption?: VariableTree
    formOption?: VariableTree
    autoHeight?: boolean
    highlighting?: boolean
    enablePageLink?: boolean
    disabled?: boolean
    onChange?: (value: JSONContent) => void
}

export const NodeFieldPreview: React.FC<NodeFieldPreviewProps> = ({
    style,
    options,
    // dataSources,
    // baseNodesData,
    value,
    field,
    placeholder = '请选择',
    viewOption,
    userOption,
    formOption,
    autoHeight = false,
    enablePageLink,
    highlighting,
    disabled,
    onChange
}) => {
    const fieldType = field?.type || 'text'
    const notEditable = singleVariableFieldTypes.has(fieldType)
    const systemOption = useMemo(() => {
        if (fieldType === 'date') {
            return getSystemOption([...DATE_SYSTEM, 'NOW'])
        }
        return undefined
    }, [fieldType])

    const editorPlaceholder = useMemo(
        () => (notEditable || !value || (value && isEmptyRichTextValue(value)) ? placeholder : ''),
        [notEditable, placeholder, value]
    )

    const selectOptions = useMemo(() => {
        if (field && field.type === 'select') {
            return field.select.options
        }
        return []
    }, [field])

    const latestRef = useLatest({ onChange })

    const extensions = useMemo(
        () => [
            Extension.create({
                addKeyboardShortcuts() {
                    return {
                        Enter: e => {
                            return true
                        }
                    }
                },
                addProseMirrorPlugins() {
                    return [
                        new Plugin({
                            key: new PluginKey('eventHandler'),
                            // this: P, view: EditorView, from: number, to: number, text: string
                            props: {
                                handleTextInput(view, from, to, text) {
                                    if (numberFieldTypes.has(fieldType)) {
                                        return !numberReg.test(text)
                                    }
                                }
                                // … and many, many more.
                                // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
                            }
                        })
                    ]
                }
            }),

            StarterKit.configure({
                // history: false,
                heading: false,
                bulletList: false,
                orderedList: false,
                blockquote: false,
                codeBlock: false,
                horizontalRule: false
            }),
            Placeholder.configure({
                showOnlyWhenEditable: false,
                showOnlyCurrent: false,
                placeholder: editorPlaceholder
            }),
            VariableNode.configure({
                options,
                userOption,
                viewOption,
                formOption,
                field,
                selectOptions
            })
        ],
        [editorPlaceholder, field, fieldType, formOption, options, selectOptions, userOption, viewOption]
    )

    const editor = useEditor(
        {
            // autofocus: false,
            // enableInputRules: true,
            // enablePasteRules: true,
            content: value,
            editable: !(disabled ?? notEditable),
            // injectCSS: false,
            // enableInputRules: [StarterKit, 'number'],
            extensions,
            onUpdate({ editor, transaction }) {
                const json = editor.getJSON()
                latestRef.current.onChange?.(json)
            }
        },
        [latestRef, placeholder, options]
    )

    const handleSelect = useCallback(
        (value: VariableADTvalue) => {
            if (!editor) {
                return
            }
            const content = {
                type: 'variable',
                attrs: { value }
            }

            if (singleVariableFieldTypes.has(fieldType)) {
                editor.chain().clearNodes().setContent(content, true).run()
                return
            }
            editor.chain().focus().insertContent([content]).run()
        },
        [editor, fieldType]
    )

    if (singleVariableFieldTypes.has(fieldType)) {
        return (
            <VariableSelect
                disableInput={field?.innerType === 'TEXT'}
                width="100%"
                field={field}
                disabled={disabled}
                value={value?.content?.[0]?.content?.[0]?.attrs?.value}
                options={options}
                systemOption={systemOption}
                userOption={userOption}
                formOption={formOption}
                viewOption={viewOption}
                enablePageLink={enablePageLink}
                // triggerWrapper={AddVariableButton}
                // onSelect={handleSelect}
                onChange={v => handleSelect(v)}
            />
        )
    }

    return (
        <SCxContainer highlighting={highlighting} style={{ ...style, cursor: disabled ? 'not-allowed' : 'default' }}>
            <NodeFieldEditorProvider editor={editor}>
                <EditorContent
                    disabled={disabled}
                    style={{ height: autoHeight && !disabled ? 'auto' : 30 }}
                    className="node-editor"
                    editor={editor}
                    placeholder={placeholder}
                />
            </NodeFieldEditorProvider>
            {!disabled && (
                <SCxExtra>
                    <VariablePicker
                        field={field}
                        disabled={disabled}
                        options={options}
                        systemOption={systemOption}
                        userOption={userOption}
                        formOption={formOption}
                        viewOption={viewOption}
                        enablePageLink={enablePageLink}
                        triggerWrapper={AddVariableButton}
                        onSelect={handleSelect}
                    />
                </SCxExtra>
            )}
        </SCxContainer>
    )
}
