import type { BlockType, ChartType, FieldInputType, ViewType } from '@lighthouse/core'

import type { NodeTypes } from '../types'
import { FieldInputNameMap } from './dataSource'

type Document = {
    url: string
    describe: string
    title: string
}

// export const SPACE_GRADE_HELP_DOCUMENT = 'https://alidocs.dingtalk.com/i/nodes/KGZLxjv9VG0kZmowIXbeMEvXJ6EDybno'

export const blockGuidanceDocumentMap: Record<BlockType, Document> = {
    // prompt: {
    //     title: '提示',
    //     url: 'https://docs.byecode.com/docs/app/components/text/tooltip.html',
    //     describe: '提示文本组件可以添加图标，并且可以选择对应场景合适的背景颜色。'
    // },
    // quote: {
    //     title: '引述标题',
    //     url: 'https://docs.byecode.com/docs/app/components/text/citation_title.html',
    //     describe: '一种带有特定样式的标题。'
    // },
    view: {
        title: '',
        url: '',
        describe: ''
    },
    relatedView: {
        title: '',
        url: '',
        describe: ''
    },
    image: {
        title: '图片',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pk4fio1ogkx7np40',
        describe: '用于展示本地图片或附件字段中的图片。'
    },
    // notice: {
    //     title: '',
    //     url: '',
    //     describe: ''
    // },
    buttonGroup: {
        title: '按钮组',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hoquaupxllnronrw',
        describe: '按钮可绑定丰富的动作，点击按钮可以执行这些动作。'
    },
    card: {
        title: '图文导航',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eqgzwxxgao2m6fa5',
        describe: '以卡片形式展示自定义的内容。'
    },
    divider: {
        title: '分割线',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/no5dsr9k5kivf0ry',
        describe: '用于分割排版内容。'
    },
    appNav: {
        title: '应用导航',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/aqlazbg60budta2b',
        describe: '打开当前空间内的其他应用。'
    },
    video: {
        title: '视频',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zamwdmrqq6amgep4',
        describe: '可用于上传和播放视频内容。'
    },
    fieldGroup: {
        title: '字段组',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gpka3tug263143tr',
        describe: '展示数据表中的字段。'
    },
    chart: {
        title: '',
        url: '',
        describe: ''
    },
    iframe: {
        title: '',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zdxg2u8m4alawafi',
        describe: ''
    },
    field: {
        title: '',
        url: '',
        describe: ''
    },
    breadcrumb: {
        title: '',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/yhycu8z0cewq3gz1',
        describe: ''
    },
    collapse: {
        title: '',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/xmyacwg2kz95rb1i',
        describe: ''
    },
    file: {
        title: '',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/vmpidsvew623tczq',
        describe: ''
    },
    formContainer: {
        title: '表单容器',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ohbdmuerlw4ha63v',
        describe: '用于构建数据表单的容器，可将组件拖入容器中进行自由排版。'
    },
    container: {
        title: '空白容器',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zkphot9fsluxcr2t',
        describe: '可将组件拖入容器中排版。'
    },
    tabs: {
        title: '标签栏',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/bxp9iy5ii8olyve2',
        describe: '支持通过标签栏来切换不同的内容，可将组件拖入容器中进行自由排版。'
    },
    filter: {
        title: '筛选控制器',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zk5l3kkp53bu6vwk',
        describe: ''
    },
    text: {
        title: '文本',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/sh7kh1ewpg0ag7rb',
        describe: '富文本格式的文本，支持基础的 Markdown 语法。'
    },
    qrBarcode: {
        title: '条码',
        url: '',
        describe: ''
    },
    subForm: {
        title: '子表单',
        url: '',
        describe: ''
    }
}

export const viewGuidanceDocumentMap: Record<ViewType, Document> = {
    table: {
        title: '表格视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/vkshyvt4mp01egeu',
        describe: '以表格的方式来展示和使用数据。'
    },
    list: {
        title: '列表视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/coxqcnttguh03p1n',
        describe: '以列表的方式来展示和使用数据。'
    },
    gallery: {
        title: '画廊视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ooxs8gva53tn7hig',
        describe: '以画廊的方式来展示和使用数据。'
    },
    calendar: {
        title: '日历视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zhz46fz8coiegcc7',
        describe: '以日历的方式来展示和使用数据。'
    },
    advancedTable: {
        title: '高级表格视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/rwhl3a6gds209u3b',
        describe: '以表格的方式来展示和使用数据，与普通表格视图不同的是，高级表格视图可直接在表格上进行数据编辑。'
    },
    kanban: {
        title: '看板视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lzib2wafu4yv7yfv',
        describe: '以看板的方式来展示和使用数据。'
    },
    custom: {
        title: '自定义视图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ia96vey7irgx5iq7',
        describe: ''
    }
}

export const charGuidanceDocumentMap: Record<ChartType, Document> = {
    bar: {
        title: '柱状图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/wn000sso366w8vxx',
        describe:
            '通常用于比较不同类别或组之间的数量或大小关系。柱状图中，每个数据点由一个垂直条形表示，每个条形的高度表示对应数据点的值或大小。条形的宽度可以是固定的，也可以根据需要进行自定义。柱状图通常被用来展示一系列数据之间的对比，其中横轴表示数据的类别或组，纵轴表示数据的数量或大小。柱状图具有易于理解和比较的特点，因此在各种领域都得到了广泛的应用。'
    },
    pie: {
        title: '饼状图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hb47wpxx7kp0lh05',
        describe:
            '饼状图是一种用于展示数据比例关系的图表类型。在饼状图中，一个大圆形表示整体数据，将整体数据分成若干部分，每个部分所占比例就是饼状图的比例数据。饼状图常用于展示各个部分在整体中所占的比例，以便直观地了解数据的构成情况。饼状图也被称为环形图或部分堆叠图。'
    },
    line: {
        title: '折线图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/iiuiait1bohm0vys',
        describe:
            '折线图是一种图表类型，它以水平或垂直的线条表示一个数据系列随时间或其他可变因素的变化。折线图通常用于显示数据的趋势和变化，以及数据点之间的关系。折线图可以用来展示各种数据类型，包括数量、时间、比例等。它通常在商业、金融、科学和工程等领域中使用，以帮助人们理解和分析数据。折线图的制作通常使用数据可视化软件或在线工具来完成。'
    },
    indicator: {
        title: '指标卡',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/socyg0bi2zb5ikmx',
        describe: '适合用于展示和追踪关键业绩指标，以评估目标的实现情况。'
    },
    funnel: {
        title: '漏斗图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lrhofisvutxgn9hw',
        describe:
            '漏斗图也称为流量图，是一种常用于数据分析和可视化的图表。它以流程形式呈现数据，可以直观地反映数据在不同阶段的转化情况，从而帮助人们了解数据流动的方向、转化率以及瓶颈等信息。漏斗图可以用于各种场景，例如市场营销、用户行为分析、销售预测等。'
    },
    composite: {
        title: '复合图表',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eniubqn4zwgelfrh',
        describe: '将多个不同类型的图表组合在一起展示的数据可视化方式，例如柱线复合图，适合用于多维度的数据展示。'
    },
    striation: {
        title: '条形图',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/dk7tw3krrx334klr',
        describe: '条形图'
    }
}

// TODO: 需要补足连接表文档
export const joinDataSourceDocumentMap: Record<'joinDataSource', Document> = {
    joinDataSource: {
        title: '连接表',
        url: '',
        describe: ''
    }
}

export const fieldGuidanceDocumentMap: Record<FieldInputType, Document> = {
    text: {
        title: `${FieldInputNameMap['text']}输入`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/qw2chupigydsd8ai',
        describe: '用于输入文本，需绑定一个文本类型的字段。'
    },
    number: {
        title: `${FieldInputNameMap['number']}输入`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/evmfmt7g25n89fhy',
        describe: '用于输入数值，需绑定一个数值类型的字段。'
    },
    email: {
        title: `${FieldInputNameMap['email']}输入`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ydbfuwq1cgl2vd07',
        describe: '用于输入邮箱，需绑定一个文本类型的可编辑字段。'
    },
    url: {
        title: `${FieldInputNameMap['url']}输入`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hgwdgds3aihmyl09',
        describe: '用于输入链接，需绑定一个文本类型的可编辑字段。'
    },
    file: {
        title: `${FieldInputNameMap['file']}上传`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mkem5nrnq22m9yd3',
        describe: '用于上传附件，需绑定一个附件类型的字段。'
    },
    relativeSelect: {
        title: `${FieldInputNameMap['relativeSelect']}`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hpex7gz9g216u6f5',
        describe: '用于选择数据，可绑定一个选项或文本类型的字段；当绑定的是文本类型的字段，可以将其他表作为选项来选择数据。'
    },
    person: {
        title: `${FieldInputNameMap['person']}选项`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lzzz647lizp6y8f8',
        describe: '用于输入人员，需绑定一个人员类型的字段。'
    },
    phoneNumber: {
        title: `${FieldInputNameMap['phoneNumber']}输入`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zu99ws6690pszyi2',
        describe: '用于输入电话，需绑定一个文本类型的可编辑字段。'
    },
    date: {
        title: `${FieldInputNameMap['date']}选择`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/nmxrh8gos8vy97mq',
        describe: '用于选择日期，需绑定一个日期类型的字段。'
    },
    notes: {
        title: `${FieldInputNameMap['notes']}输入`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mtwrgw6dkr3gl1mx',
        describe: '用于输入笔记，需绑定一个笔记类型的字段。'
    },
    checkbox: {
        title: `${FieldInputNameMap['checkbox']}`,
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/kkr68ke9sfh0m4h8',
        describe: '用于勾选复选框，需绑定一个复选框类型的字段。'
    },
    cascade: {
        title: '',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/stwbq9ketwt2c0np?singleDoc# ',
        describe: ''
    },
    slider: {
        title: '滑动输入条',
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hf4bnhtzdddmlk9a',
        describe: ''
    }
}

export const workflowGuidanceDocumentMap: Record<NodeTypes, Document> = {
    CREATE_RECORD_TRIGGER: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zm2kz9trafmtuibw',
        title: '当记录被创建时',
        describe: '当指定数据表中的记录被创建时触发'
    },
    UPDATE_RECORD_TRIGGER: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hqv6bdla95sro1au',
        title: '当记录被更新时',
        describe: '当指定数据表中的记录被更新时触发'
    },
    MATCH_CONDITION_TRIGGER: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/aaicgc0lndvfpe7k',
        title: '当记录满足指定条件时',
        describe: '当数据表中的记录满足指定条件时触发'
    },
    SCHEDULED_TRIGGER: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/se3gegosr1ah2imu',
        title: '定时触发',
        describe: '当满足定时器设定的时间时触发'
    },
    CLICK_TRIGGER: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ftud6hde40petyud',
        title: '当点击时',
        describe: '当点击按钮时，会触发流程'
    },
    MANUALLY_TRIGGER: {
        url: '',
        title: '',
        describe: ''
    },
    INITIATE_APPROVE_TRIGGER: {
        url: '',
        title: '提交审批申请',
        describe: '提交审批申请'
    },
    ARG_TRIGGER: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eidnccuxt88nbokb',
        title: '子流程',
        describe: '子流程'
    },
    WX_ROBOT_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/do2xpo7mug8txs4o',
        title: '企业微信-群机器人',
        describe: '通过企业微信群机器人发送指定内容的消息'
    },
    DINGTALK_ROBOT_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/on0gohlgo2q5geu1',
        title: '钉钉-自定义群机器人',
        describe: '通过钉钉自定义群机器人发送指定内容的消息'
    },
    SEND_EMAIL_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/drqrgvg10iuf8o90',
        title: '发送邮件',
        describe: '发送邮件到指定的邮箱'
    },
    STATION_MESSAGE_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hsvlu7tz0zk33uvp',
        title: '站内信',
        describe: '通过站内信节点发送消息给指定的用户'
    },
    OPEN_PAGE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/slqw3x75p2iig6fe',
        title: '打开页面',
        describe: '打开应用内指定的一个页面'
    },
    OPEN_LINK: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/poaao8xlkiatgwnl',
        title: '打开链接',
        describe: '打开一个指定的网址链接或链接字段中的链接'
    },
    OPEN_FORM_PAGE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gx7lksgfvhg31whh',
        title: '打开数据表单页面',
        describe: '打开数据表单页面'
    },
    OPEN_RECORD_PAGE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/valymfnei6uglwmb',
        title: '打开记录详情页面',
        describe: '打开记录详情页面'
    },
    OPEN_RECORD_EDIT_PAGE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zrhw8sf74rg4z348',
        title: '打开记录编辑页面',
        describe: '打开指定的记录编辑页'
    },
    GO_BACK: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hiub0nd0drmv0nuy',
        title: '返回上一步',
        describe: '跳转到上一个操作的页面'
    },
    NOTIFICATION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ayvh2vr6iqwb5pgn',
        title: '显示提示',
        describe: '弹出系统提示，提示内容为用户配置的内容'
    },
    COPY: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lokmy2o1qkrczguy',
        title: '复制到剪切板',
        describe: '将指定内容复制到剪切板'
    },
    DELETE_RECORD: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/nwskxgtac537d3ae',
        title: '删除当前打开的记录',
        describe: '删除当前记录'
    },
    SHARE_TO_WECHAT: {
        url: '',
        title: '分享到微信',
        describe: '分享到微信'
    },
    DOWNLOAD_FILE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ad3aien7bq9yqg3n',
        title: '下载',
        describe: '下载'
    },
    APPROVE_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mxewiif9bd40xg7w',
        title: '审批',
        describe: '当涉及到对某个任务、请求或决策进行审批和批准时，适合用审批节点'
    },
    CARBON_COPY_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/wt6z0g2iuucmwlfu',
        title: '抄送',
        describe: '给指定人发送抄送的消息通知'
    },
    CREATE_SINGLE_RECORD_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/cga29ytv9tyqebvu',
        title: '创建单条记录',
        describe: '在指定的数据表中创建一条记录'
    },
    CREATE_RECORD_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ic4tpw3t9khcr5zf',
        title: '创建多条记录',
        describe: '在指定的数据表中创建多条记录'
    },
    UPDATE_RECORD_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/xpyey6nx3rq1z0wx',
        title: '更新记录',
        describe: '更新指定数据表中的一条或多条记录'
    },
    FIND_SINGLE_RECORD_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ho2bf8gbs6ck0fdq',
        title: '查询单条记录',
        describe: '根据查找条件查找指定数据表中的一条记录'
    },
    FIND_RECORD_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lrgb7wsprf765ge5',
        title: '查询多条记录',
        describe: '根据条件查找数据表中所有符合条件的记录'
    },
    INITIATE_APPROVE_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/igsetq00rdyxdwe2',
        title: '发起审批流程',
        describe: '发起一个审批子流程'
    },
    CONDITION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/nalgm8lnms1plk8k',
        title: '条件分支',
        describe: '根据指定的条件判断流程分支的走向'
    },
    END: {
        url: '',
        title: '结束节点',
        describe: '结束节点'
    },
    I_CLICK_CONTACT_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mcs1fov94h6k4wlu',
        title: 'iclick',
        describe: 'iclick'
    },
    ALIYUN_MESSAGE_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lcrvagggak6etzvr',
        title: '阿里云短信',
        describe: '阿里云短信'
    },
    CALL: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lgs0cmr8o98gn5i5',
        title: '拨打电话',
        describe: '拨打电话'
    },
    WECHAT_PAY: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mkccast0odlbvmux',
        title: '微信支付',
        describe: '微信支付'
    },
    WECHAT_TEMPLATE_MSG_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/sh28sm0z7ypsyps7?singleDoc#',
        title: '微信消息模版',
        describe: '微信消息模版'
    },
    STRIPE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lyr9sxw7nbigb0ds',
        title: 'Stripe',
        describe: 'Stripe'
    },
    REFRESH_PAGE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eidnccuxt88nbokb',
        title: '刷新页面',
        describe: '刷新页面'
    },
    LOOP_ACTION: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eidnccuxt88nbokb',
        title: '循环',
        describe: '循环'
    }
}

export const appSettingDocumentMap = {
    navbar: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pvqay2eh48gmzxsd',
        title: '',
        describe: ''
    }
}

export const otherPlatformDocumentMap: Record<string, Document> = {
    WE_COM_ROBOT: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mmbovuywoerpd0nl',
        title: '',
        describe: ''
    },
    DING_TALK_ROBOT: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/fchtzfq6sss7kbfv',
        title: '',
        describe: ''
    },
    WECHAT_PAY: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pxnbuk2ax3qqbe25',
        title: '',
        describe: ''
    },
    STRIPE: {
        url: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lyr9sxw7nbigb0ds',
        title: '',
        describe: ''
    }
}

export const subnodeGuidanceDocumentMap = { ...viewGuidanceDocumentMap, ...charGuidanceDocumentMap, ...fieldGuidanceDocumentMap }

export const allGuidanceDocumentUrlMap = {
    ...workflowGuidanceDocumentMap,
    ...blockGuidanceDocumentMap,
    ...joinDataSourceDocumentMap,
    ...appSettingDocumentMap
}
