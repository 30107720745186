import { Toast } from '@byecode/ui'
import type { FieldADTValue, FieldBlockAbstract } from '@lighthouse/core'
import { isEmptyCellValue, pageStackPubSub, useAtomAction } from '@lighthouse/shared'
import { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import useSWRMutation from 'swr/mutation'

import { addDataSourceAtom, addRecordAtom, updateCellAtom } from '@/atoms/dataSource/action'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'

export interface IUsePageCurrentDsFirstRecord {
    rootPageId: string
    pageId: string
    stackId: string
    appId: string
    envId: string
    pointer: string
    fieldPointer: string
    disabled?: boolean
}

export interface IUsePageCurrentDsAndRecord {
    blockData: FieldBlockAbstract
    pointer: string
    disabled?: boolean
}

export const usePageCurrentDsFirstRecord = ({ pageId, stackId, appId, envId, pointer, disabled }: IUsePageCurrentDsFirstRecord) => {
    const { run: addRecord } = useAtomAction(addRecordAtom)
    const { run: addDataSource } = useAtomAction(addDataSourceAtom)

    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const pageDepsDs = curr.datasource ?? prev.datasource
    const pageDepsRecordId = curr.recordId ?? prev.recordId

    const currentPageRecord = useRecord(appId, envId, pageDepsDs?.id || '', pageDepsRecordId ?? '')

    const [firstRecordId, setFirstRecordId] = useState<string>()

    const currentBlockRecord = useRecord(appId, envId, pointer, pageDepsRecordId ?? firstRecordId ?? '')

    const [initRecord, setInitRecord] = useState(currentPageRecord)

    const isNotConfigure = !pointer

    // 如果不存在当前记录并且不存在匹配当前用户的记录，则需要请求获取数据源第一行
    const { trigger, data } = useSWRMutation(`extraData-${appId}-${pointer}`, async (_, { arg }) => {
        const { appId, pointer } = arg ?? {}
        if (!appId || !pointer) {
            return
        }
        const data = await srv.getDs({ appId, envId, dsId: pointer, pagination: { currentPage: 1, pageSize: 1 } })
        if (!data) {
            return
        }
        const { datasource, records } = data
        setInitRecord(records[0])
        addRecord({ records })
        addDataSource({ dataSource: datasource })
        setFirstRecordId(records[0]?.id)
        return { dataSource: datasource, record: records[0] }
    })

    useUpdateEffect(() => {
        if (!initRecord) {
            setInitRecord(currentPageRecord)
        }
    }, [currentPageRecord])
    // useEffect(() => {
    //     if (pageDepsDsId && pageDepsDsId !== pointer && !isNotConfigure && !isMatchUser && !disabled) {
    //         trigger({ appId, pointer, fieldPointer })
    //     }
    // }, [pageDepsDsId, isNotConfigure, pointer, trigger, appId, fieldPointer, isMatchUser, disabled])

    return {
        dataSource: data?.dataSource ?? pageDepsDs,
        record: currentBlockRecord,
        pageRecord: currentPageRecord,
        initRecord
    }
}

export const usePageCurrentDsAndRecordByBlock = ({ blockData, pointer, disabled }: IUsePageCurrentDsAndRecord) => {
    const { config } = blockData
    const { fieldPointer = '', required } = config
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    // const appId = useCurrentAppID()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const {
        dataSource: currentDataSource,
        record: currentDataRecord,
        pageRecord,
        initRecord: currentInitRecord
    } = usePageCurrentDsFirstRecord({ rootPageId, pageId, stackId, appId, envId, pointer, fieldPointer, disabled })

    const currentPageRecordId = currentDataRecord?.id
    const currentPageDataSourceId = currentDataSource?.id
    const currentPageRecord = useRecord(appId, envId, pointer, currentPageRecordId ?? '')

    const isNotConfigure = !pointer || !fieldPointer

    const fieldContent = useMemo(() => {
        if (isNotConfigure) {
            return
        }
        if (pointer !== currentPageDataSourceId) {
            return currentDataRecord?.content?.[fieldPointer]
        }
        // 如果匹配当前用户这一逻辑不生效，则尝试匹配视图记录页跳转携带过来的数据源和行数据
        if (!currentPageRecord) {
            return
        }
        return currentPageRecord.content?.[fieldPointer]?.value
    }, [currentDataRecord?.content, currentPageDataSourceId, currentPageRecord, fieldPointer, isNotConfigure, pointer])

    const handleValueChange = useCallback(
        async (fieldValue: FieldADTValue) => {
            const { value } = fieldValue
            // 如果是匹配当前用户，这里直接 return
            if (!fieldPointer || !currentPageRecordId) {
                return
            }

            const isUpdateWillNotPass = required && isEmptyCellValue(fieldValue)

            // 如果为空，且是必填项，进行错误提示
            if (isUpdateWillNotPass) {
                Toast.error('必填项不能为空~')
            }

            // 如果匹配当前用户，那么当前数据表和行数据都是匹配到的，直接更新即可
            const recordId = currentDataRecord?.id ?? ''
            const dsId = currentDataSource?.id ?? ''

            await updateCell(
                {
                    recordId,
                    dsId,
                    envId,
                    fieldId: fieldPointer,
                    value: { value }
                },
                { local: isUpdateWillNotPass }
            )
            // 创建行以后，需要通知对应订阅更新数据
            pageStackPubSub.emit(`${dsId}-ADD`)
        },
        [currentDataRecord?.id, currentDataSource?.id, currentPageRecordId, envId, fieldPointer, required, updateCell]
    )

    return {
        currentPageDataSource: currentDataSource,
        currentBlockRecord: currentDataRecord,
        currentPageRecord: pageRecord,
        fieldContent,
        initRecord: currentInitRecord,

        handleValueChange
    }
}
