import { Flex, Group, IconFont, Input, SegmentedControl, Select, Text, Toast, Tooltip } from '@byecode/ui'
import { useCustomViewBlockContext } from '@lighthouse/block'
import { type Field, type FieldType, type VideoBlockConfig, VariableType } from '@lighthouse/core'
import {
    avatarMaxFileSize,
    fileMaxUploadSize,
    fileSuffixRegex,
    getAllOptions,
    getFileSizeToMB,
    getFileTypeByUrl,
    getIsFindUseInVariable,
    getPageDataOptions,
    getUserDatasourceOptions,
    getVariableToFieldValue,
    getVideoSource,
    InnerTypeMapByFieldType,
    isTextValue,
    ListItemPaddingByecodeUi,
    useAtomData,
    useFindUseObjectContext,
    USER_DATASOURCE,
    VariableSelect,
    VIDEO_RATIO_OPTIONS
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import { use } from 'echarts/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useRecord } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { uploadManagerInAppParams, uploadVideoInDataSourceManagerParams, uploadVideoInPageManagerParams } from '@/utils/auth'

import { SwitchControl } from '../Common/SwitchControl'
import { VisibilityFilter } from '../Common/VisibilityFilter'
import { VIDEO_FIT_OPTIONS } from '../constants'

interface VideoBlockSettingProps {
    id: string
}

const SCxStyleContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
`
const saveFields = new Set<FieldType>(['video', 'file'])

const fileField: Field = { type: 'video', id: 'video', dsId: '', name: '视频', innerType: 'ARRAY' }

export const VideoBlockSetting: React.FunctionComponent<VideoBlockSettingProps> = ({ id: blockId }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const { control, register, watch, setValue } = useFormContext<VideoBlockConfig>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const findUseObject = useFindUseObjectContext()

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )

    const [videoSource, isAuto] = watch(['videoSource', 'isAuto'])

    const { dataSourceList, curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')

    const uploadInfo = useMemo(() => ({ id: '', label: pageName, groupId: pageId }), [pageId, pageName])
    const { record, pointer } = useCustomViewBlockContext()

    const userRecord = useUserRecord()

    const viewDataSource = useDataSource(appId, envId, pointer)
    const videoSourceType = useMemo(() => {
        const DEFAULT_SOURCE = ''
        if (!videoSource) {
            return 'byecode'
        }
        const cellValue = getVariableToFieldValue({
            idVariable: videoSource,
            currentRecord: {
                datasource: curr.datasource,
                record: currentRecord
            },
            prevRecord: {
                datasource: prev.datasource,
                record: prevRecord
            },
            viewRecord: {
                record,
                datasource: viewDataSource
            },
            userRecord
        })
        if (!cellValue) {
            return 'byecode'
        }
        const videoUrl = cellValue.type === 'video' ? cellValue.value?.[0] || DEFAULT_SOURCE : DEFAULT_SOURCE
        return getVideoSource(videoUrl)
    }, [curr.datasource, currentRecord, prev.datasource, prevRecord, record, userRecord, videoSource, viewDataSource])

    const { customViewDataSource } = useVariableCustomViewOption(field => field.type === 'video')
    const {
        userOption: videoUserOption,
        pageOption: videoPageOption,
        viewOption: videoViewOption
    } = useMemo(() => {
        const filter = (field: Field) => saveFields.has(field.type) || field.innerType === 'TEXT'
        return getAllOptions({
            user: true,
            page: {
                prevDsId: prev.datasource?.id,
                curDsId: curr.datasource?.id
            },
            view: {
                viewType: 'custom',
                dsId: customViewDataSource?.id
            },
            dataSourceList,
            validateField: filter
        })
    }, [curr.datasource?.id, customViewDataSource?.id, dataSourceList, prev.datasource?.id])

    const {
        userOption: fileUserOption,
        pageOption: filePageOption,
        viewOption: fileViewOption
    } = useMemo(() => {
        const filter = (field: Field) => field.type === 'file'
        return getAllOptions({
            user: true,
            page: {
                prevDsId: prev.datasource?.id,
                curDsId: curr.datasource?.id
            },
            view: {
                viewType: 'custom',
                dsId: customViewDataSource?.id
            },
            dataSourceList,
            validateField: filter
        })
    }, [curr.datasource?.id, customViewDataSource?.id, dataSourceList, prev.datasource?.id])

    const isUploadVideoSource = useMemo(() => {
        const { type } = videoSource ?? {}
        switch (type) {
            case VariableType.VALUE: {
                const value = videoSource?.type === VariableType.VALUE ? videoSource?.valueVariable?.value : ''
                return isTextValue(value) ? !value.includes('http') : false
            }
            case VariableType.USER: {
                 const value = videoSource?.type === VariableType.USER ? videoSource?.userVariable?.fieldId : ''
                return videoUserOption?.children.find(v => v.id === value)?.fieldType === 'video'
            }
            case VariableType.VIEW: {
                    const value = videoSource?.type === VariableType.VIEW ? videoSource?.viewVariable?.fieldId : ''
                return videoViewOption?.children.find(v => v.id === value)?.fieldType === 'video'
            }
            case VariableType.PAGE: {
                const value = videoSource?.type === VariableType.PAGE ? videoSource?.pageVariable?.fieldId : ''
                return videoPageOption?.flatMap(v => v.list).flatMap(v => v.children)?.find(v => v.id === value)?.fieldType === 'video'
            }
            default: {
                return false
            }
        }
    }, [videoPageOption, videoSource, videoUserOption?.children, videoViewOption?.children])

    return (
        <>
            <Group label="基础">
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>视频来源</Text>
                    <Controller
                        name="videoSource"
                        control={control}
                        render={({ field }) => {
                            const isHighLight = getIsFindUseInVariable({
                                variable: field.value,
                                findUseObject,
                                currentDsId: curr.datasource?.id
                            })
                            return (
                                <VariableSelect
                                    field={fileField}
                                    options={videoPageOption}
                                    value={field.value}
                                    highlighting={isHighLight}
                                    withinPortal
                                    disabledPexels
                                    disabled={disabledWithVersion}
                                    placeholder="粘贴视频链接"
                                    uploadProps={{
                                        multiple: false,
                                        accept: 'video/*',
                                        uploadOptions: {
                                            info: uploadInfo,
                                            options: {
                                                ...uploadVideoInPageManagerParams({
                                                    pageId,
                                                    blockId
                                                }),
                                                fileFilter: (file: File | string, index: number) => {
                                                    if (file instanceof File) {
                                                        if (file.size > fileMaxUploadSize) {
                                                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                                                            return false
                                                        }
                                                        const extension = fileSuffixRegex
                                                            .exec(file.name.toLocaleLowerCase())?.[1]
                                                            ?.toLocaleLowerCase()
                                                        if (extension !== 'mp4') {
                                                            Toast.error('视频格式错误，请上传 MP4 格式的视频')
                                                            return false
                                                        }

                                                        return true
                                                    }
                                                    return true
                                                }
                                            }
                                        }
                                    }}
                                    userOption={videoUserOption}
                                    viewOption={videoViewOption}
                                    onChange={field.onChange}
                                />
                            )
                        }}
                    />
                </ListItemPaddingByecodeUi>
                {!isUploadVideoSource && (
                    <div style={{ margin: '4px 0 8px 0' }}>
                        <Text
                            lineHeight="22px"
                            whiteSpace="pre-wrap"
                            style={{ padding: '8px 12px', backgroundColor: '#f3f3f3', color: 'var(--color-gray-500)', borderRadius: 6 }}
                        >
                            支持优酷、哔哩哔哩、YouTube 以及通用视频资源地址。
                        </Text>
                    </div>
                )}
                {/* {sourceType === 'default' ? <VideoUpload /> : <FieldSelect />} */}
                {videoSourceType === 'other' && (
                    <Controller
                        name="isMute"
                        control={control}
                        render={({ field }) => (
                            <SwitchControl label="静音" checked={field.value} onChange={e => field.onChange?.(e.currentTarget.checked)} />
                        )}
                    />
                )}
                {!['youku', 'bilibili'].includes(videoSourceType) && (
                    <Controller
                        name="isUsedVideoControl"
                        control={control}
                        render={({ field }) => (
                            <SwitchControl
                                label="视频控件"
                                checked={field.value}
                                onChange={e => field.onChange?.(e.currentTarget.checked)}
                            />
                        )}
                    />
                )}
                {videoSourceType !== 'youku' && (
                    <Controller
                        name="isAuto"
                        control={control}
                        render={({ field }) => (
                            <SwitchControl
                                label="自动播放"
                                checked={field.value}
                                onChange={e => field.onChange?.(e.currentTarget.checked)}
                            />
                        )}
                    />
                )}
                {isAuto && (
                    <Text
                        lineHeight="22px"
                        style={{ padding: '8px 12px', backgroundColor: '#f3f3f3', color: 'var(--color-gray-500)', borderRadius: 6 }}
                    >
                        由于微信的限制，微信浏览器中无法自动播放。
                    </Text>
                )}
                {!['bilibili', 'youku'].includes(videoSourceType) && (
                    <Controller
                        name="isLoop"
                        control={control}
                        render={({ field }) => (
                            <SwitchControl
                                label="循环播放"
                                checked={field.value}
                                onChange={e => field.onChange?.(e.currentTarget.checked)}
                            />
                        )}
                    />
                )}
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group label="设计">
                <SCxStyleContainer>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Flex gap={4}>
                            <Text>封面</Text>
                            <Tooltip
                                title={
                                    <Flex style={{ width: 165, fontSize: 12, lineHeight: '20px' }}>
                                        由于外部播放器的限制，可能会无法显示封面，建议在上传视频或数据表中的字段中使用封面。
                                    </Flex>
                                }
                            >
                                <IconFont size={16} color="var(--color-gray-400)" type="Question" />
                            </Tooltip>
                        </Flex>
                        <Controller
                            name="cover"
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const isHighLight = getIsFindUseInVariable({
                                    variable: value,
                                    findUseObject,
                                    currentDsId: curr.datasource?.id
                                })
                                return (
                                    <VariableSelect
                                        field={{ type: 'file', id: '', dsId: '', name: '', innerType: InnerTypeMapByFieldType['file'] }}
                                        disabled={disabledWithVersion}
                                        disabledPexels
                                        highlighting={isHighLight}
                                        uploadProps={{
                                            multiple: false,
                                            uploadOptions: {
                                                info: uploadInfo,
                                                options: {
                                                    ...uploadManagerInAppParams({ pageId, blockId }),
                                                    fileFilter: (file: File | string, index: number) => {
                                                        if (file instanceof File) {
                                                            if (file.size > avatarMaxFileSize) {
                                                                Toast.error(`不能上传大于 ${getFileSizeToMB(avatarMaxFileSize)}mb 的文件`)
                                                                return false
                                                            }
                                                            return true
                                                        }
                                                        return true
                                                    }
                                                }
                                            }
                                        }}
                                        options={filePageOption}
                                        value={value}
                                        userOption={fileUserOption}
                                        viewOption={fileViewOption}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>纵横比</Text>
                        <Controller
                            name="ratio"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    style={{
                                        width: 180
                                    }}
                                    searchable={false}
                                    options={VIDEO_RATIO_OPTIONS}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                    {videoSourceType === 'byecode' && (
                        <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>布局</Text>
                            <div style={{ width: 180 }}>
                                <Controller
                                    name="fitType"
                                    control={control}
                                    render={({ field }) => (
                                        <SegmentedControl
                                            data={VIDEO_FIT_OPTIONS}
                                            fullWidth
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                            </div>
                        </ListItemPaddingByecodeUi>
                    )}
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>圆角</Text>
                        <Input
                            {...register('radius')}
                            style={{
                                width: 178
                            }}
                            type="number"
                            min={0}
                            placeholder="请输入圆角"
                        />
                    </ListItemPaddingByecodeUi>
                </SCxStyleContainer>
            </Group>
            <Divider color="var(--color-gray-200)" />
            <VisibilityFilter />
        </>
    )
}
