import { Checkbox } from '@byecode/ui'
import type { AppRole } from '@lighthouse/shared'
import * as React from 'react'

import { rolesTitle } from '../constant'
import RoleItem from '../RoleItem'
import * as CM from '../styles'

interface RoleListProps {
    disabled?: boolean
    roles?: AppRole[]
    selectRoleIds: string[]
    onSelectGroup?: (roleIds: string[]) => void
    onChangeName?: (name: string, id: string) => void
}

const RoleList: React.FC<RoleListProps> = ({ disabled, roles = [], selectRoleIds, onSelectGroup, onChangeName }) => {
    return (
        <CM.TableContainer>
            <CM.TableHeader>
                {rolesTitle.map(title => (
                    <CM.Item key={title.id} {...title}>
                        {title.id === 'checkbox' ? (
                            <Checkbox
                                disabled={disabled}
                                size="xs"
                                checked={
                                    selectRoleIds.length === roles.length &&
                                    roles.length > 0
                                }
                                onChange={e => onSelectGroup?.(e.target.checked ? roles.map(draft => draft.id) : [])}
                            />
                        ) : (
                            <CM.TableColumnName>{title.name}</CM.TableColumnName>
                        )}
                    </CM.Item>
                ))}
            </CM.TableHeader>
            <CM.TableContent>
                {roles.map(role => (
                    <RoleItem
                        disabled={disabled}
                        role={role}
                        checked={selectRoleIds.includes(role.id)}
                        key={role.id}
                        onChangeName={onChangeName}
                        onSelectGroup={roleId =>
                            onSelectGroup?.(roleId ? [...selectRoleIds, roleId] : selectRoleIds.filter(draft => draft !== role.id))
                        }
                    />
                ))}
            </CM.TableContent>
        </CM.TableContainer>
    )
}

export default RoleList
