import { Divider, Popover } from '@byecode/ui'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { useAtom } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { createNodeLoadingAtom, lastPageOfStackAtom, outsideDraggingNode, pageAtomFamily } from '@/atoms/page/state'
import type { BlockTreeItem } from '@/constants/Block/type'
import { useApplicationList, useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { flowLayoutBeginAdding } from '@/utils/flowLayoutEventBus'

import { BLOCK_LIST } from '../constant'
import { getBlockOrNode } from '../helper'
import { KindBlock } from '../KindBlock'
import KindDropDown from '../KindBlock/DropDown'
import SearchCardList from '../KindBlock/SearchCardList'
import { Search } from '../Search'
import * as CM from '../style'
import * as SC from './styles'
import { useAreaHover } from './useAreaHover'

interface BlockListProps {
    onBack?: () => void
}
interface State {
    tab: string
    opened: boolean
    searchWord: string
    activeId: string
}

export const BlockList: React.FC<BlockListProps> = ({ onBack }) => {
    const [dragNode, setDragNode] = useAtom(outsideDraggingNode)
    const { run: setCreateNodeLoading } = useAtomAction(createNodeLoadingAtom)

    const [{ searchWord, tab, opened, activeId }, setState] = useSetState<State>({
        tab: 'component',
        opened: false,
        activeId: '',
        searchWord: ''
    })

    const { blockKindRef, showId, isInArea, setShowId } = useAreaHover(activeId)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const appList = useApplicationList()
    const pageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId ?? '', [])
    )
    const pageDsId = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.dsId, [])
    )
    const dataSource4Page = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: pageDsId || '' }))

    useEffect(() => {
        // 抬起清除拖拽数据
        const mouseupListener = () => {
            if (dragNode) {
                setDragNode(null)
            }
        }
        window.addEventListener('mouseup', mouseupListener)
        return () => {
            window.removeEventListener('mouseup', mouseupListener)
        }
    }, [dragNode, setCreateNodeLoading, setDragNode, setState])

    const searchBlockList = useMemo(() => {
        const allKind = BLOCK_LIST.flatMap(item => item.children)
        const allBlock = allKind.flatMap(item => item.items)
        return allBlock.filter(block => block.name.includes(searchWord))
    }, [searchWord])

    const handleCreate = useCallback(
        (ev: React.MouseEvent<HTMLDivElement>, v: BlockTreeItem) => {
            const res = getBlockOrNode({ v, appId, dataSource: dataSource4Page, appList })
            if (!res) {
                return
            }
            const [blocks, node] = res
            const dragNodeWithData = { ...v, data: blocks }

            setDragNode(dragNodeWithData)
            flowLayoutBeginAdding({
                node,
                coordinate: { x: ev.clientX, y: ev.clientY },
                rect: (ev.currentTarget as HTMLElement).getBoundingClientRect()
            })
        },
        [appId, dataSource4Page, appList, setDragNode]
    )
    const currentItems = useMemo(() => {
        const flatBlock = BLOCK_LIST.flatMap(item => item.children)
        return find(item => item.id === showId, flatBlock)?.items ?? []
    }, [showId])

    return (
        <Popover
            opened={opened}
            position="right"
            disabled={showId === ''}
            offsetOptions={{
                mainAxis: 0
            }}
            withinPortal
            onChange={v => {
                setShowId(d => (v ? d : ''))
                setState({ opened: v, activeId: v ? showId : '' })
            }}
            trigger="hover"
            width="auto"
        >
            <Popover.Target>
                <SC.Container>
                    <CM.Header>
                        <Search value={searchWord} onChange={val => setState({ searchWord: val })} onBack={onBack} />
                    </CM.Header>
                    <SC.ChunkContainer hidden={tab !== 'component'} key='BLOCK_LIST' ref={blockKindRef}>
                        {searchWord === '' ? (
                            BLOCK_LIST.map((option, index) => (
                                <>
                                    <KindBlock
                                        option={option}
                                        key={option.id}
                                        activeId={showId}
                                        onOpen={v => {
                                            setState({ opened: true, activeId: v })
                                        }}
                                    />
                                    {index + 1 !== BLOCK_LIST.length && <Divider style={{ margin: '12px 14px' }} />}
                                </>
                            ))
                        ) : (
                            <SearchCardList options={searchBlockList} onCreate={handleCreate} />
                        )}
                    </SC.ChunkContainer>
                </SC.Container>
            </Popover.Target>
            <Popover.Dropdown
                styles={{
                    dropdown: {
                        boxShadow: 'none',
                        height: 'calc(100% - 56px)',
                        borderRadius: 0,
                        borderWidth: '0 1px'
                    }
                }}
                compact
            >
                <KindDropDown activeId={showId} items={currentItems} onCreate={handleCreate} />
            </Popover.Dropdown>
        </Popover>
    )
}
