import { Empty, IconFont, Input, Menu, singleTextEllipsis } from '@byecode/ui'
import { Text } from '@mantine/core'
import { find, reduce } from 'rambda'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import type { AppDepartment } from '../../types'
import { DepartmentSelectItem } from './DepartmentItem'
import { getDepartmentTree } from './help'
import * as SC from './styles'

interface DepartmentSelectProps {
    options?: AppDepartment[]
    value?: string[]
    disabledId?: string
    onChange?: (value: string[]) => void
}

const SCxDropDownHeader = styled.div`
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
`

const SCxTag = styled.div<{ isOmit?: boolean }>`
    line-height: 16px;
    padding: 4px 6px;
    height: 24px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const DepartmentSelect: React.FunctionComponent<DepartmentSelectProps> = ({
    disabledId,
    options: data = [],
    value = [],
    onChange
}) => {
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState('')
    const [options, setOptions] = useState(data)

    const optionsTree = useMemo(() => getDepartmentTree(options), [options])

    const tagList = useMemo(() => {
        return reduce<string, AppDepartment[]>(
            (preVal, departmentId) => {
                const option = find(item => item.id === departmentId, options)
                return option ? [...preVal, option] : preVal
            },
            [],
            value
        )
    }, [options, value])

    const handleChange = useCallback(
        (ids: string[]) => {
            onChange?.(ids)
            setOpened(false)
        },
        [onChange]
    )

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const search = ev.target.value
            const searchList = search === '' ? data : options.filter(item => item.name?.includes(search))
            setSearch(ev.target.value)
            setOptions(searchList)
        },
        [options, data]
    )

    return (
        <Menu opened={opened} onChange={setOpened} withinPortal>
            <Menu.Target>
                <SC.Trigger style={{ padding: 8 }}>
                    <SC.TagWrapper>
                        {tagList.map(tag => (
                            <SCxTag key={tag.id} isOmit>
                                {tag.name}
                            </SCxTag>
                        ))}
                        {tagList.length === 0 && <Text color="var(--color-gray-400)">请选择部门</Text>}
                    </SC.TagWrapper>
                    <SC.IconWrapper>
                        {tagList.length > 0 ? (
                            <IconFont
                                type="CloseCircle"
                                onClick={e => {
                                    e.stopPropagation()
                                    handleChange?.([])
                                }}
                                size={16}
                                color="var(--color-gray-400)"
                                style={{ cursor: 'pointer' }}
                            />
                        ) : (
                            <IconFont type="ArrowDownSmall" size={16} color="var(--color-gray-400)" />
                        )}
                    </SC.IconWrapper>
                </SC.Trigger>
            </Menu.Target>
            <Menu.Dropdown>
                <SC.DropDown>
                    <SCxDropDownHeader>
                        <Input
                            styles={{
                                wrapper: {
                                    flex: 1
                                }
                            }}
                            prefix={<SC.Icon type="Search" />}
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="部门名称"
                        />
                    </SCxDropDownHeader>
                    <SC.DropDownList>
                        {optionsTree.map(option => (
                            <DepartmentSelectItem
                                key={option.id}
                                data={option}
                                value={value}
                                disabledIds={disabledId ? [disabledId] : []}
                                level={0}
                                onChange={handleChange}
                            />
                        ))}
                        {options.length === 0 && <Empty icon="SpaceTeam" styles={{ root: { height: 100 } }} description="没有部门" />}
                    </SC.DropDownList>
                </SC.DropDown>
            </Menu.Dropdown>
        </Menu>
    )
}
