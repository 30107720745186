import { getAssetUrl } from '@lighthouse/assets'
import { TextBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { BlockAbstract, TextBlockAbstract } from '@lighthouse/core'
import { type RichTextEditorProps, TEXT_FONT_NORMAL, useApplicationContext, useAtomData } from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import produce from 'immer'
import type { AnyObject } from 'immer/dist/internal'
import React, { memo, useCallback, useEffect, useMemo } from 'react'

import { applicationSettingAtom } from '@/atoms/application/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

interface TextBlockControllerProps {
    blockData: TextBlockAbstract
    onBlockChange?: (values: AnyObject, origin: BlockAbstract) => Promise<void> | void
}

const TextBlockController: React.FC<TextBlockControllerProps> = ({ blockData }) => {
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const { record } = useCustomViewBlockContext()
    const renderLabel = useVariableValueRender(parentRecordId, recordId)

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({ heading: true, variable: { renderLabel: v => renderLabel(v.attrs.value, { personOptions, roleOptions, departmentOptions, viewRecord: record }) } }),
        [departmentOptions, personOptions, record, renderLabel, roleOptions]
    )

    const textPresets = useAtomData(
        applicationSettingAtom,
        useCallback(s => s.theme.textPresetList, [])
    )

    const mergedBlockData = useMemo(() => {
        if (blockData.config.font.advance) {
            const advanceOption = textPresets.find(item => item.id === blockData.config.font.advance)
            if (advanceOption) {
                const { fontFamily, fontSize, letterSpacing, lineClamp, lineHeight } = advanceOption
                return produce(blockData, draft => {
                    draft.config.font.fontFamily = fontFamily
                    draft.config.font.fontSize = fontSize
                    draft.config.font.letterSpacing = letterSpacing
                    draft.config.font.lineClamp = lineClamp
                    draft.config.font.lineHeight = lineHeight
                })
            }
        }

        return blockData
    }, [blockData, textPresets])

    useEffect(() => {
        const ff = mergedBlockData.config.font.fontFamily
        if (!ff || ff === TEXT_FONT_NORMAL) {
            return
        }
        document.fonts.ready
            .then(async ffs => {
                const fontFaceList = [...ffs.values()]
                const isLoaded = fontFaceList.some(item => item.family === ff)
                if (!isLoaded) {
                    const fontUrl = `url(${getAssetUrl('font', `${ff}.woff2`)})`
                    const newFont = new FontFace(ff, fontUrl)
                    await newFont.load()
                    ffs.add(newFont)
                }
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [mergedBlockData.config.font.fontFamily])

    return <TextBlock blockData={mergedBlockData} config={config} />
}

export default memo(TextBlockController, equal)
