import { Button, Input } from '@byecode/ui'
import {
    type AppDepartment,
    DepartmentSelect,
    ErrorMessage,
    getDepartmentTree,
    PersonSelect,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import type { ModalProps } from '@mantine/core'
import { Modal } from '@mantine/core'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { fetchDataSourceDepartmentAtom, fetchDataSourceUserAtom } from '@/atoms/dataSource/action'
import { dataSourceUserListAtom } from '@/atoms/dataSource/state'
import * as srv from '@/services'
import { useDataSourceDepartments, useDataSourceRoles } from '@/shared/reusable'

import { getFlatDepartments } from '../help'
import * as SC from './styles'

export type FormState = AppDepartment

interface DepartmentModalProps extends ModalProps {
    envId: string
    data?: FormState
    mode: 'edit' | 'add'
}

const DepartmentModal: React.FunctionComponent<DepartmentModalProps> = ({ envId, data, mode, onClose, ...rest }) => {
    const departmentList = useDataSourceDepartments()

    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    // const { update: usersUpdate } = useDataSourceUsers()
    const personOptions = useAtomData(dataSourceUserListAtom)
    const roleOptions = useDataSourceRoles()
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<FormState>({
        mode: 'onSubmit',
        defaultValues: data
    })

    useUpdateEffect(() => {
        reset(data)
    }, [data])

    const handleAdd = useCallback(
        async (params: FormState) => {
            setLoading(true)
            const res = await srv.createDepartment(envId, params)
            setLoading(false)
            if (res) {
                fetchDataSourceDepartment()
                onClose()
                params.managerUserId && fetchDataSourceUser()
            }
        },
        [fetchDataSourceDepartment, envId, onClose, fetchDataSourceUser]
    )
    const handleUpdate = useCallback(
        async (params: FormState) => {
            const res = await srv.updateDepartment(envId, params)
            if (res) {
                fetchDataSourceDepartment()
                onClose()
                fetchDataSourceUser()
            }
        },
        [fetchDataSourceDepartment, envId, onClose, fetchDataSourceUser]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <Modal
            {...rest}
            onClose={() => {
                setLoading(false)
                onClose?.()
            }}
            padding={0}
            centered
            styles={{
                header: {
                    height: 44,
                    margin: 0
                },
                close: {
                    marginTop: 12,
                    marginRight: 12
                }
            }}
        >
            <SC.Container onSubmit={handleInviteSubmit}>
                <SC.Content>
                    <SC.Title>{mode === 'add' ? '新建' : '编辑'}部门</SC.Title>
                    <SC.Item>
                        <SC.Text>
                            部门名称
                            <SC.Text color="red" style={{ paddingLeft: 4 }}>
                                *
                            </SC.Text>
                        </SC.Text>
                        <ErrorMessage name="name" errors={errors}>
                            <Input placeholder="请输入" size="lg" {...register('name', { required: '不能为空' })} />
                        </ErrorMessage>
                    </SC.Item>
                    <SC.Item>
                        <SC.Text>上级部门</SC.Text>
                        <ErrorMessage name="parentId" errors={errors}>
                            <Controller
                                control={control}
                                name="parentId"
                                render={({ field }) => {
                                    return (
                                        <DepartmentSelect
                                            disabledId={data?.id}
                                            value={field.value ? [field.value] : []}
                                            onChange={val => {
                                                field.onChange(val[0] ?? '')
                                            }}
                                            options={departmentList}
                                        />
                                    )
                                }}
                            />
                        </ErrorMessage>
                    </SC.Item>
                    <SC.Item>
                        <SC.Text>部门主管</SC.Text>
                        <ErrorMessage name="managerUserId" errors={errors}>
                            <Controller
                                control={control}
                                name="managerUserId"
                                render={({ field }) => (
                                    <PersonSelect
                                        enableCheckBox={false}
                                        width="376px"
                                        value={field.value ? [field.value] : []}
                                        roleOptions={roleOptions}
                                        styles={{
                                            container: {
                                                height: 36,
                                                backgroundColor: 'var(--color-gray-100)',
                                                borderStyle: 'none',
                                                borderRadius: 7
                                            }
                                        }}
                                        focusOutLine="var(--color-main)"
                                        isMultiple={false}
                                        placeholder="请选择部门主管"
                                        onChange={val => field.onChange(val[0])}
                                        options={personOptions}
                                    />
                                )}
                            />
                        </ErrorMessage>
                    </SC.Item>
                </SC.Content>
                <SC.Footer>
                    <Button
                        size="lg"
                        style={{ width: 104 }}
                        onClick={() => {
                            setLoading(false)
                            onClose?.()
                        }}
                    >
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.Footer>
            </SC.Container>
        </Modal>
    )
}

export default DepartmentModal
