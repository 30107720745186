import { Modal, Toast } from '@byecode/ui'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import type { AppendParams, ImportParams, SynchronizeParams } from '@lighthouse/shared'
import { useAtomAction, useDataSourceContext } from '@lighthouse/shared'
import { getDefaultStore } from 'jotai'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useUpdateEffect } from 'react-use'

import {
    createAggregationDataSourceAtom,
    createAsyncDataSourceAtom,
    createDataSourceAtom,
    deleteDataSourceAtom,
    duplicateDataSourceAtom,
    fetchDataSourceAtom,
    fetchDataSourceListAtom,
    importDataSourceAtom,
    moveDataSourceAtom,
    updateDataSourceAtom
} from '@/atoms/dataSource/action'
import { filterCacheAtomFamily, sortsCacheAtomFamily } from '@/atoms/storage/state'
import { useApplicationList, useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import DataSourceAdder from '../DataSourceAdder'
import { Env } from './Env'
import { List } from './List'
import * as SC from './styles'
import { UserCenter } from './UserCenter'

export type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface DataSourceListProps {
    activeId?: string
    // onUpdateDataSource?: (dsId: string, data: Partial<DataSourceAbstract>) => Promise<boolean>
    onSelectDataSource?: (id: string) => Promise<void> | void
    onNavigateToEr?: () => void
    onMoveDataSource?: (dragId: string) => Promise<void> | void
    onDataAppended?: (params: AppendParams) => Promise<void>
}

const getDatasourceGroup = function (dataSourceList: DataSourceAbstract[]) {
    return dataSourceList.reduce<[DataSourceAbstract[], DataSourceAbstract[], DataSourceAbstract[], DataSourceAbstract[]]>(
        (prev, item) => {
            if (item.type === DataSourceType.joinDataSource) {
                prev[0].push(item)
                return prev
            }
            if (item.type === DataSourceType.aggregateDataSource) {
                prev[1].push(item)
                return prev
            }
            if (item.sync) {
                prev[2].push(item)
                return prev
            }
            prev[3].push(item)
            return prev
            // item.type === 5 ? [[...prev[0], item], prev[1]] : [prev[0], [...prev[1], item]]
        },
        [[], [], [], []]
    )
}

const getChildrenDataSource = (ordinaryDataSource: DataSourceAbstract, joinDataSource: DataSourceAbstract[]) => {
    const { id } = ordinaryDataSource
    return joinDataSource.reduce<DataSourceAbstract[]>((prev, cur) => {
        if (cur.viewOptions.joinConfig?.primaryDsId === id) {
            prev.push(cur)
        }
        return prev
    }, [])
}

const getDataSourceList = (dataSourceList: DataSourceAbstract[]) => {
    const [joinDataSources, aggregateDataSources, syncDataSources, ordinaryDataSources] = getDatasourceGroup(dataSourceList)
    return [
        ordinaryDataSources.map(item => ({
            ...item,
            children: getChildrenDataSource(item, joinDataSources)
        })),
        aggregateDataSources,
        syncDataSources.map(item => ({
            ...item,
            children: getChildrenDataSource(item, joinDataSources)
        }))
    ]
}
// type DataSourceDataList = Pick<DataSourceAbstract, 'id' | 'name' | 'type'>[]

const DataSourceList: React.FC<DataSourceListProps> = ({ activeId, onNavigateToEr, onSelectDataSource, onDataAppended }) => {
    const store = getDefaultStore()
    const applicationList = useApplicationList()
    const navigate = useNavigate()
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dsList = useDataSourceList(appId, envId)
    const { onVisibleJoinConfigureChange } = useDataSourceContext()
    const [searchVal, setSearch] = useState('')
    const { run: fetchDataSource } = useAtomAction(fetchDataSourceAtom)
    const { run: createDataSource } = useAtomAction(createDataSourceAtom)
    const { run: duplicateDataSource } = useAtomAction(duplicateDataSourceAtom)
    const { run: createAsyncDataSource } = useAtomAction(createAsyncDataSourceAtom)
    const { run: createAggregationDataSource } = useAtomAction(createAggregationDataSourceAtom)
    const { run: importDataSource } = useAtomAction(importDataSourceAtom)
    const { run: fetchDataSourceList } = useAtomAction(fetchDataSourceListAtom)
    const { run: deleteDataSource } = useAtomAction(deleteDataSourceAtom)
    const { run: moveDataSource } = useAtomAction(moveDataSourceAtom)
    const { run: updateDataSource } = useAtomAction(updateDataSourceAtom)
    const listRef = useRef<HTMLDivElement>(null)
    // const application = useMemo(() => find(item => item.id === appId, applicationList), [appId, applicationList])
    const dataSourceList = useMemo(() => {
        return dsList.filter(item => item.name.includes(searchVal))
    }, [dsList, searchVal])

    // const [joinDataSource, ordinaryDataSources] = useMemo(() => getDatasourceGroup(dataSourceList), [dataSourceList])

    const [listData, aggregateDataSources, syncDataSources] = getDataSourceList(dataSourceList)

    useUpdateEffect(() => {
        fetchDataSourceList({ appId, envId })
    }, [envId])

    const handleSelectDataSource = useCallback(
        (id: string) => {
            onSelectDataSource?.(id)
            onVisibleJoinConfigureChange?.(false)
        },
        [onSelectDataSource, onVisibleJoinConfigureChange]
    )

    const handleSelectUserCenter = useCallback(
        (id: string) => {
            onSelectDataSource?.(id)
            onVisibleJoinConfigureChange?.(false)
        },
        [onSelectDataSource, onVisibleJoinConfigureChange]
    )

    const handleUpdateDataSource = useCallback(
        (id: string, value: string) =>
            updateDataSource({
                envId,
                dsId: id,
                params: { name: value }
            }),
        [envId, updateDataSource]
    )

    const handleDeleteDataSource = useCallback(
        async (dsId: string, name: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除数据源「${name ?? '未命名数据源'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteDataSource({
                    envId,
                    dsId
                })
                const newDataSourceList = await fetchDataSourceList({ appId, envId })

                // closePageOverlay(modalId)

                if (isDelete && dsId === activeId) {
                    const newList = newDataSourceList.filter(item => item.id !== dsId)
                    // const [systemDataSource, ordinaryDataSources] = getDatasourceGroup(newList)
                    if (newList.length > 0) {
                        const ds = newList[0]
                        handleSelectDataSource?.(ds.id)
                        return isDelete
                    }
                    handleSelectDataSource?.('')
                }
                return !!isDelete
            }

            return false
        },
        [activeId, appId, deleteDataSource, envId, fetchDataSourceList, handleSelectDataSource]
    )

    const handleSwitchSyncDs = useCallback(
        async (envId: string, dsId: string, switchEnvId: string) => {
            const isSwitch = await srv.switchSyncDs({
                envId,
                dsId,
                switchEnvId
            })
            if (isSwitch) {
                const filter = store.get(filterCacheAtomFamily({ appId, envId, id: dsId }))
                const sorts = store.get(sortsCacheAtomFamily({ appId, envId, id: dsId }))
                fetchDataSource({
                    envId,
                    dsId,
                    pagination: { currentPage: 1, pageSize: 100 },
                    search: searchVal,
                    filter,
                    sorts
                })
            }
        },
        [appId, fetchDataSource, searchVal, store]
    )

    const handleCreateDataSource = useCallback(
        async (type: number) => {
            const { dsId } = await createDataSource({
                appId,
                envId,
                type
            })
            onVisibleJoinConfigureChange?.(false)
            if (dsId) {
                onSelectDataSource?.(dsId)
            }
            return !!dsId
        },
        [createDataSource, appId, envId, onVisibleJoinConfigureChange, onSelectDataSource]
    )

    const handleDuplicateDataSource = useCallback(
        async (dsId: string) => {
            const newDataSourceId = await duplicateDataSource({
                envId,
                dsId
            })
            if (newDataSourceId) {
                onSelectDataSource?.(newDataSourceId)
            }
            return !!newDataSourceId
        },
        [duplicateDataSource, envId, onSelectDataSource]
    )

    const handleAsyncSynchronize = useCallback(
        async (params: SynchronizeParams) => {
            const { appId: syncAppId, envId: syncEnvId, dsId: syncDsId } = params
            // const newDataSourceId = nanoid(32)
            const data = await createAsyncDataSource({
                appId,
                envId,
                syncEnvId,
                syncAppId,
                syncDsId
            })
            const { dsId: newDataSourceId } = data
            onVisibleJoinConfigureChange?.(false)
            if (data) {
                onSelectDataSource?.(newDataSourceId)
            }
            return !!newDataSourceId
        },
        [appId, createAsyncDataSource, envId, onSelectDataSource, onVisibleJoinConfigureChange]
    )

    const handleCreateAggregationDataSource = useCallback(async () => {
        const data = await createAggregationDataSource({
            envId
        })
        onVisibleJoinConfigureChange?.(false)
        if (data) {
            onSelectDataSource?.(data.dsId)
        }
        navigate({ pathname: `/${appId}/aggregate/${data.dsId}` })

        return !!data
    }, [appId, createAggregationDataSource, envId, navigate, onSelectDataSource, onVisibleJoinConfigureChange])

    const handleImportDataSource = useCallback(
        async (params: ImportParams) => {
            const dsIds = await importDataSource(params)
            if (dsIds?.length) {
                Toast.success('导入成功')
                const [dsId] = dsIds
                onSelectDataSource?.(dsId)
            }
            return !!dsIds?.length
        },
        [importDataSource, onSelectDataSource]
    )

    const handleAppendDataSource = useCallback((params: AppendParams) => onDataAppended?.(params), [onDataAppended])

    return (
        <SC.Container>
            <SC.Content>
                <SC.SearchWrapper>
                    <SC.Search
                        prefix={<SC.SearchIcon type="Search" color="var(--color-gray-400)" />}
                        value={searchVal}
                        placeholder="搜索"
                        size="md"
                        onChange={e => setSearch(e.currentTarget.value)}
                        styles={{
                            wrapper: {
                                borderRadius: 5
                            }
                        }}
                    />
                    <DataSourceAdder
                        onCreate={handleCreateDataSource}
                        onImport={handleImportDataSource}
                        onAppend={handleAppendDataSource}
                        onAsyncSynchronize={handleAsyncSynchronize}
                        onCreateAggregationDataSource={handleCreateAggregationDataSource}
                    />
                </SC.SearchWrapper>
                <SC.ListWrapper ref={listRef}>
                    <SC.Header>
                        <SC.GroupName>数据表</SC.GroupName>
                    </SC.Header>
                    <List
                        appId={appId}
                        envId={envId}
                        allDataSource={dataSourceList}
                        dataSourceList={listData}
                        applicationList={applicationList}
                        activeId={activeId}
                        onMoveDataSource={moveDataSource}
                        onSelectDataSource={handleSelectDataSource}
                        onUpdateDataSource={handleUpdateDataSource}
                        onDuplicateDataSource={handleDuplicateDataSource}
                        onDeleteDataSource={handleDeleteDataSource}
                    />
                    {aggregateDataSources.length > 0 && (
                        <>
                            <SC.Header>
                                <SC.GroupName>聚合表</SC.GroupName>
                            </SC.Header>
                            <List
                                appId={appId}
                                envId={envId}
                                allDataSource={dataSourceList}
                                dataSourceList={aggregateDataSources}
                                applicationList={applicationList}
                                activeId={activeId}
                                onMoveDataSource={moveDataSource}
                                onSelectDataSource={handleSelectDataSource}
                                onUpdateDataSource={handleUpdateDataSource}
                                onDuplicateDataSource={handleDuplicateDataSource}
                                onDeleteDataSource={handleDeleteDataSource}
                            />
                        </>
                    )}
                    {syncDataSources.length > 0 && (
                        <>
                            <SC.Header>
                                <SC.GroupName>从其他应用同步的表</SC.GroupName>
                            </SC.Header>
                            <List
                                appId={appId}
                                envId={envId}
                                allDataSource={dataSourceList}
                                dataSourceList={syncDataSources}
                                applicationList={applicationList}
                                activeId={activeId}
                                onMoveDataSource={moveDataSource}
                                onSelectDataSource={handleSelectDataSource}
                                onUpdateDataSource={handleUpdateDataSource}
                                onDuplicateDataSource={handleDuplicateDataSource}
                                onDeleteDataSource={handleDeleteDataSource}
                                onSwitchSyncDs={handleSwitchSyncDs}
                            />
                        </>
                    )}

                    <SC.Header>
                        <SC.GroupName>用户中心 </SC.GroupName>
                    </SC.Header>
                    <UserCenter activeId={activeId} onSelect={handleSelectUserCenter} />
                    <SC.Space />
                </SC.ListWrapper>
            </SC.Content>
            <Env />
            {/* 隐藏数据模型入口 */}
            {/* <SC.ErItemWrapper active={activeId === ''} onClick={onNavigateToEr}>
                <SC.ErItemIcon type="DataModal" />
                <SC.ErItemText>数据模型</SC.ErItemText>
            </SC.ErItemWrapper> */}
            {/* <AIAssistant onFinish={handleAiAssistantFinish} /> */}
        </SC.Container>
    )
}

export default DataSourceList

// <Divider style={{ margin: '8px 0' }} />
// <Box>
//     <Text style={{ padding: '0 16px', lineHeight: '32px' }} size={12} color="var(--color-gray-500)">
//         用户中心
//     </Text>
//     {userCenterOptions.map(item => (
//         <SelectItem key={item.value} data={item} onSelect={handleUserCenterSelect} />
//     ))}
// </Box>
