import { Button, Divider, Empty, IconFont, Menu, Modal, Tag, Text, Tooltip } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import { APPLICATION_ENV_PROD, getEnvColor, spaceVersionEnum, useAtomAction, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { createAppEnvAtom, deleteAppEnvAtom, updateAppEnvAtom } from '@/atoms/application/action'
import { appEnvListAtom } from '@/atoms/application/state'
import {
    fetchDataSourceDepartmentAtom,
    fetchDataSourceRoleAtom,
    fetchDataSourceUserAtom,
    fetchEnvAtom,
    setDataSourceEnvIdAtom
} from '@/atoms/dataSource/action'
import { openSpaceGradeConfirm, SpaceGradeTag } from '@/components/SpaceGrade'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { useSpaceQuota } from '@/shared/reusable'

import { DeleteEnv } from './DeleteEnv'
import { EnvItem } from './EnvItem'
import { type EnvModalMode, EnvModal } from './EnvModal'
import { EnvTag } from './EnvTag'
import { MergeWrapper } from './MergeWrapper'

const SCxContainer = styled.div`
    height: 42px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-200);
    border-top: 1px solid var(--color-gray-200);
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxEnv = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    /* border-radius: 6px; */
    cursor: pointer;
`

const SCxIcon = styled(IconFont)``

const SCxLabel = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`

const SCxTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
`

interface EnvironmentProps {}

interface EnvironmentState {
    open: boolean
    mode: EnvModalMode
    data: Environment | undefined
}

interface DeleteState {
    deleteEnv: Environment | undefined
    open: boolean
}

export const Env: React.FC<EnvironmentProps> = () => {
    const envList = useAtomData(appEnvListAtom)
    const appId = useCurrentAppID()
    const dataSourceEnvId = useDataSourceEnvId()
    const [menuOpen, setMenuOpen] = useState(false)
    const [deleteState, setDeleteState] = useImmer<DeleteState>({
        deleteEnv: undefined,
        open: false
    })
    const { run: setDataSourceEnvId } = useAtomAction(setDataSourceEnvIdAtom)
    const { run: createAppEnv } = useAtomAction(createAppEnvAtom)
    const { run: updateAppEnv } = useAtomAction(updateAppEnvAtom)
    const { run: deleteAppEnv } = useAtomAction(deleteAppEnvAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceRole } = useAtomAction(fetchDataSourceRoleAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)

    const { deleteEnv, open: deleteOpen } = deleteState

    const { data: spaceQuota } = useSpaceQuota()

    const isLessThanAdvancedVersion = useMemo(
        () => (spaceQuota ? spaceQuota?.currentVersionCode < spaceVersionEnum.ADVANCED : true),
        [spaceQuota]
    )

    const currentEnv = useMemo(() => find(item => item.envId === dataSourceEnvId, envList), [dataSourceEnvId, envList])

    const [mergeOpen, setMergeOpen] = useState<boolean>(false)
    const [state, setState] = useImmer<EnvironmentState>({
        open: false,
        mode: 'create',
        data: undefined
    })

    const { open, mode, data } = state

    useEffect(() => {
        fetchEnv()
        fetchDataSourceUser()
        fetchDataSourceRole()
        fetchDataSourceDepartment()
    }, [dataSourceEnvId, fetchDataSourceDepartment, fetchDataSourceUser, fetchDataSourceRole, fetchEnv])

    const handleSelect = useCallback(
        async (id: string) => {
            if (id === dataSourceEnvId) {
                return
            }
            const selectEnv = find(item => item.envId === id, envList)
            if (!selectEnv) {
                return
            }
            const isConfirm = await Modal.confirm({
                title: `确认将数据环境切换为“${selectEnv.envName}”环境？`,
                content: `切换后，当前编辑的版本中所使用的数据环境，都将切换为“${selectEnv.envName}”环境。`,
                okText: '确认切换',
                okStatus: 'primary'
            })
            if (isConfirm) {
                const isSuccess = await srv.switchAppEnv(id)
                setDataSourceEnvId(id)
                setMenuOpen(false)
                if (!isSuccess) {
                    setDataSourceEnvId(dataSourceEnvId)
                }
            }
        },
        [dataSourceEnvId, envList, setDataSourceEnvId]
    )

    const handleOpenCreate = useCallback(() => {
        if (isLessThanAdvancedVersion) {
            openSpaceGradeConfirm('ADVANCED')
            return
        }
        setState({
            open: true,
            mode: 'create',
            data: undefined
        })
    }, [isLessThanAdvancedVersion, setState])

    const handleMergeOpen = useCallback(() => {
        if (isLessThanAdvancedVersion) {
            openSpaceGradeConfirm('ADVANCED')
            return
        }
        setMergeOpen(true)
    }, [isLessThanAdvancedVersion])

    const handleOpenEdit = useCallback(
        (val: Environment) => {
            setState({
                open: true,
                mode: 'update',
                data: val
            })
        },
        [setState]
    )

    const handleOpenDelete = useCallback(
        (id: string) => {
            const deleteEnv = find(env => env.envId === id, envList)
            setDeleteState({
                deleteEnv,
                open: true
            })
        },
        [envList, setDeleteState]
    )

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.open = false
        })
    }, [setState])

    const handleMergeClose = useCallback(() => {
        setMergeOpen(false)
    }, [])

    const handleCreate = useCallback(
        async (val: Omit<Environment, 'envId'>) => {
            const params = {
                ...val,
                baseEnvId: APPLICATION_ENV_PROD
            }
            const newEnvId = await createAppEnv(params)
            if (newEnvId) {
                handleClose()
                const isSuccess = await srv.switchAppEnv(newEnvId)
                if (isSuccess) {
                    setDataSourceEnvId(newEnvId)
                }
                // setDataSourceEnvId(newEnvId)
            }
        },
        [createAppEnv, handleClose, setDataSourceEnvId]
    )

    const handleUpdate = useCallback(
        async (val: Environment) => {
            const isUpdate = await updateAppEnv(val)
            if (isUpdate) {
                handleClose()
            }
        },
        [handleClose, updateAppEnv]
    )
    const handleConfirmClose = useCallback(() => {
        setDeleteState({
            deleteEnv: undefined,
            open: false
        })
    }, [setDeleteState])

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false)
    }, [])

    const handleDeleteConfirm = useCallback(async () => {
        if (!deleteEnv?.envId) {
            return
        }
        const isDelete = await deleteAppEnv(deleteEnv.envId)
        handleConfirmClose()
        if (isDelete && deleteEnv.envId === dataSourceEnvId) {
            const firstEnv = find(item => item.envId !== deleteEnv.envId, envList)
            if (firstEnv) {
                setDataSourceEnvId(firstEnv.envId)
            }
        }
    }, [dataSourceEnvId, deleteAppEnv, deleteEnv?.envId, envList, handleConfirmClose, setDataSourceEnvId])

    // const handleLinkHelp = useCallback(() => {
    //     window.open('https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/xzvd2rim4k57yp8y?singleDoc#', '_blank')
    // }, [])

    const list = useMemo(() => {
        if (envList.length === 0) {
            return <Empty description="暂无环境" />
        }
        // const isLastEnv = envList.length <= 2
        return envList.map(item => (
            <EnvItem
                key={item.envId}
                data={item}
                // disableDelete={isLastEnv}
                isActive={item.envId === currentEnv?.envId}
                onClick={() => handleSelect(item.envId)}
                onEdit={handleOpenEdit}
                onMenuClose={handleMenuClose}
                onDelete={handleOpenDelete}
            />
        ))
    }, [currentEnv?.envId, envList, handleMenuClose, handleOpenDelete, handleOpenEdit, handleSelect])

    return (
        <SCxContainer>
            <Menu width={204} opened={menuOpen} onChange={setMenuOpen}>
                <Menu.Target>
                    <SCxEnv>
                        <SCxLabel>
                            <Text color="var(--color-black)" size={14}>
                                数据环境
                            </Text>
                            <Tooltip
                                title="正式环境会影响线上应用，您可以新建数据环境用于应用测试。切换后，当前编辑的应用所使用的数据环境都将切换。"
                                placement="top"
                                offset={18}
                                width={217}
                            >
                                <IconFont type="Question" size={16} color="var(--color-gray-400)" />
                            </Tooltip>
                        </SCxLabel>
                        <SCxTitle>
                            <EnvTag data={currentEnv} />
                            <SCxIcon size={16} type="ArrowOpen" fill="var(--color-gray-400)" />
                        </SCxTitle>
                    </SCxEnv>
                </Menu.Target>
                <Menu.Dropdown>
                    {list}
                    <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                    <Menu.Item
                        icon={<SCxIcon size={16} type="Add" color="var(--color-gray-400)" />}
                        rightSection={isLessThanAdvancedVersion && <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />}
                        onClick={handleOpenCreate}
                    >
                        新建环境
                    </Menu.Item>
                    <Menu.Item
                        icon={<SCxIcon size={16} type="SettingsStylesA" color="var(--color-gray-400)" />}
                        rightSection={isLessThanAdvancedVersion && <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />}
                        onClick={handleMergeOpen}
                    >
                        合并环境
                    </Menu.Item>
                    {/* <Flex gap={4} justifyContent="flex-end" style={{ padding: '0 16px' }}>
                        <Button
                            type="text"
                            icon={<IconFont color="var(--color-gray-400)" type="HelpManual" size={16} />}
                            onClick={handleLinkHelp}
                        >
                            了解更多
                        </Button>
                    </Flex> */}
                </Menu.Dropdown>
            </Menu>
            {deleteOpen && !!deleteEnv && <DeleteEnv env={deleteEnv} onConfirm={handleDeleteConfirm} onClose={handleConfirmClose} />}
            <EnvModal
                open={open}
                data={data}
                envList={envList}
                mode={mode}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                onClose={handleClose}
            />
            {mergeOpen && currentEnv && (
                <MergeWrapper open={mergeOpen} currentEnv={currentEnv} envList={envList} onClose={handleMergeClose} />
            )}
        </SCxContainer>
    )
}
