import { IconFont } from '@byecode/ui/components/IconFont'
import type { QuotaProtocols } from '@lighthouse/core'
import { Flex } from '@mantine/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface SpaceQuotaSliderProps {
    data: QuotaProtocols & { label: React.ReactNode; icon: string; primaryColor?: string }
}

const SCxSlider = styled.div`
    position: relative;
    width: 100%;
    height: 5px;
    background-color: var(--color-gray-200);
    border-radius: 100px;
    overflow: hidden;
`
const SCxSliderBar = styled.div`
    position: absolute;
    inset: 0;
    height: 100%;
    border-radius: inherit;
    z-index: 1;
`

const SCxItem = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
`

const SCxIconWrapper = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-gray-100);
`

export const SpaceQuotaSlider: React.FunctionComponent<SpaceQuotaSliderProps> = ({ data }) => {
    const backgroundColor = useMemo(() => {
        if (data.usage >= data.quota * 0.75) {
            return 'var(--color-red-500)'
        }

        if (data.usage >= data.quota * 0.5) {
            return 'var(--color-yellow-500)'
        }

        return 'var(--color-gray-400)'
    }, [data.quota, data.usage])

    return (
        <SCxItem>
            <SCxIconWrapper>
                <IconFont type={data.icon} size={28} fill="var(--color-gray-400)" />
            </SCxIconWrapper>
            <Flex direction="column" justify="space-between" gap={8} style={{ height: '100%', flex: 1 }}>
                {data.label}
                <SCxSlider>
                    <SCxSliderBar style={{ width: `${(data.usage * 100) / data.quota}%`, backgroundColor }} />
                </SCxSlider>
            </Flex>
        </SCxItem>
    )
}
