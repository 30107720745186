import { DataSourceType } from '@lighthouse/core'
import { getFieldOptions, getTableIcon, useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo } from 'react'

import { currentEnvIdAtom } from '@/atoms/application/state'
import {
    currentDataSourceIdAtom,
    dataSourceAtomFamily,
    // dataSourceEnvIdAtom,
    dataSourcePoolAtom,
    fieldBlockDataSourceListAtom,
    recordAtomFamily,
    recordPoolAtom
} from '@/atoms/dataSource/state'


export const useDataSourcePool = () => {
    return useAtomValue(dataSourcePoolAtom)
}

export const useDataSourceEnvId = () => {
    // return useAtomValue(dataSourceEnvIdAtom)
    return useAtomValue(currentEnvIdAtom)
}

export const useDataSource = (appId: string, envId: string, dsId: string) => {
    return useAtomValue(dataSourceAtomFamily({ appId, envId, dsId }))
}

export const useRecord = (appId: string, envId: string, dsId: string, recordId: string) => {
    return useAtomValue(recordAtomFamily({ appId, envId, dsId, recordId }))
}

export const useRecordList = () => {
    return useAtomValue(recordPoolAtom)
}

export const useCurrentDataSourceId = () => {
    return useAtomValue(currentDataSourceIdAtom)
}

export const useDataSourceRecordList = (appId: string, dsId: string) => {
    return useAtomData(
        recordPoolAtom,
        useCallback(draft => draft.filter(item => item.appId === appId && item.dsId === dsId), [appId, dsId])
    )
}

export const useDataSourceList = (appId: string, envId: string) => {
    return useAtomData(
        useMemo(() => selectAtom(dataSourcePoolAtom, draft => draft.filter(dataSource => dataSource.appId === appId && dataSource.envId === envId)), [appId, envId])
    )
}

export const useNotAggregateDataSourceList = (appId: string, envId: string) => {
    return useAtomData(
        useMemo(
            () =>
                selectAtom(dataSourcePoolAtom, draft =>
                    draft.filter(dataSource => dataSource.appId === appId && dataSource.envId === envId && dataSource.type !== DataSourceType.aggregateDataSource)
                ),
            [appId, envId]
        )
    )
}

export const useDsListAndFieldOptions = (appId: string, envId: string, dsId: string) => {
    const dataSourceList = useDataSourceList(appId, envId)
    const dataSource = useDataSource(appId, envId, dsId)

    const fieldOptions = useMemo(() => getFieldOptions(dataSource), [dataSource])

    const dataSourceOptions = useMemo(() => {
        return dataSourceList.map(item => {
            const tableIcon = getTableIcon(item)
            return {
                label: item.name,
                value: item.id,
                icon: tableIcon,
                groupValue: item.appId,
                groupLabel: item.appName
            }
        })
    }, [dataSourceList])

    return { dataSourceOptions, fieldOptions, dataSource, dataSourceList }
}


export const useFieldBlockDatSourceList = () => {
    return useAtomValue(fieldBlockDataSourceListAtom)
}
