import { Switch } from '@byecode/ui'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { Collapse } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useDataSourceRoles } from '@/shared/reusable'

import { getGroup } from '../../help'
import * as CM from '../../styles'
import PerfectFieldDetail from '../PerfectFieldDetail'
import * as SC from '../styles'

interface PerfectFieldCollapseProps {
    roleId: string
    prefix: `perfect.roleSetting.${number}`
}

const PerfectFieldCollapse: React.FunctionComponent<PerfectFieldCollapseProps> = ({ roleId, prefix }) => {
    const { control, watch } = useFormContext<ApplicationSettingAuthentication>()
    const roles = useDataSourceRoles()
    const [opened, { close, toggle }] = useDisclosure(true)

    const isShown = watch(`${prefix}.isShown`)

    return (
        <SC.Container key={roleId} style={{ marginBottom: 12 }}>
            <SC.Header>
                <SC.LeftContain>
                    {/* <CM.Icon type="SpaceTeam" /> */}
                    <CM.Text>{getGroup(roleId ?? '', roles ?? [])}</CM.Text>
                </SC.LeftContain>
                <SC.RightContain>
                    <CM.Text color="var(--color-gray-400)">{isShown ? '需' : '无需'}完善</CM.Text>
                    <Controller
                        name={`${prefix}.isShown`}
                        control={control}
                        render={({ field }) => <Switch checked={field.value} onChange={field.onChange} />}
                    />
                    <CM.Icon onClick={toggle} type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'} />
                </SC.RightContain>
            </SC.Header>
            <Collapse
                in={opened}
                style={{
                    padding: '6px 16px 12px 16px'
                }}
            >
                <PerfectFieldDetail prefix={prefix} id={roleId} PerfectWayColumn="row" />
            </Collapse>
        </SC.Container>
    )
}

export default PerfectFieldCollapse
