import { IconFont, Input, itemBackgroundTransitionOnClick, pointer, singleTextEllipsis } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const ListContainer = styled.div``

export const Prefix = styled.div<{ width: number }>`
    width: ${({ width }) => width && `${width}px`};
`

export const LeftItem = styled.div`
    width: 12px;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Arrow = styled(IconFont)<{ active?: boolean }>`
    transition: all 0.1s ease-in-out;
    ${({ active }) =>
        active &&
        css`
            transform: rotate(90deg);
        `}
`

export const CenterItem = styled.div`
    flex: 1;
    padding: 6px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const RightItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
`

export const RightIcon = styled(IconFont)<{ active: boolean }>`
    padding: 3px;
    border-radius: 5px;
    color: ${({ active }) => (active ? 'var(--color-black)' : 'var(--color-gray-400)')};
    background-color: ${({ active }) => (active ? 'rgba(38, 65, 90, 0.06)' : 'none')};
    &:hover {
        color: var(--color-black);
    }
`

export const DataSourceIcon = styled(IconFont)``

export const ListItem = styled.div`
    /* width: 200px; */
    margin: 0 8px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    border-radius: 6px;
    color: var(--color-gray-500);
    ${pointer}
    ${itemBackgroundTransitionOnClick}

    ${RightItem} ${RightIcon} {
        visibility: hidden;
    }

    &:hover ${DataSourceIcon} {
        color: var(--color-gray-500);
    }
    /* 'var(--color-main)' : 'var(--color-gray-400)' */

    &:hover ${RightItem} ${RightIcon} {
        visibility: visible;
    }

    &.operate {
        color: var(--color-black);
        background-color: var(--color-gray-100);
    }

    &.active {
        color: var(--color-main);
        background-color: var(--color-main-tint);

        ${DataSourceIcon} {
            color: var(--color-main);
        }

        ${RightItem} ${RightIcon} {
            visibility: visible;
        }
    }
`

export const ListGroup = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const ListGroupTitle = styled.div<{ color?: string }>`
    flex: 1;
    line-height: 24px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const Description = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

export const TitleInput = styled(Input)`
    flex: 1;
    background: transparent;
    border: none;
    border-radius: 6px;
    font-size: var(--font-size-normal);
    display: flex;
    align-items: center;

    input {
        margin: 0;
        ::placeholder {
            color: var(--color-gray-300);
        }
    }
`

export const Link = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: rgba(56, 56, 56, 0.05);
`

export const LinkIcon = styled(IconFont)``

export const LinkInfo = styled.div`
    color: var(--color-white);
    font-size: var(--font-size-sm);
    text-align: center;
`

export const SyncDataSourceContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`

export const SyncDataSourceInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin-top: 8px;
    background-color: var(--color-gray-100);
    margin: 4px 4px 0 4px;

`

export const SyncDataSourceIcon = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 30px;
`

export const SyncDataSourceText = styled.div`
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
    ${singleTextEllipsis}
`

// export const Tag = styled.div<{ color: string, background: string }>`
//     color: ${({ color }) => color};
//     background: ${({ background }) => background};
//     padding: 0 5px;
//     margin-left: 4px;
//     margin-top: 4px;
//     border-radius: 10px;
//     height: 16px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 10px;
// `
