import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import SettingBlock from '../../SettingBlock'
import PerfectFieldCollapse from '../PerfectFieldCollapse'

interface PerfectGroupSettingProps {}

const PerfectGroupSetting: React.FunctionComponent<PerfectGroupSettingProps> = props => {
    const { control } = useFormContext<ApplicationSettingAuthentication>()

    const { fields: roleSetting, update } = useFieldArray({
        control,
        name: 'perfect.roleSetting'
    })

    return (
        <>
            {roleSetting.map(({ roleId }, index) => (
                <PerfectFieldCollapse prefix={`perfect.roleSetting.${index}`} key={roleId} roleId={roleId ?? ''} />
            ))}
        </>
    )
}

export default PerfectGroupSetting
