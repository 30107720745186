import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AliyunMessageActionConfigure } from './Actions/AliyunMessageActionConfigure'
import { ApproveActionConfigure } from './Actions/ApproveActionConfigure'
import { CallActionConfigure } from './Actions/CallActionConfigure'
import { CarbonCopyActionConfigure } from './Actions/CarbonCopyActionConfigure'
import { CopyActionConfigure } from './Actions/CopyActionConfigure'
import { CreateRecordActionConfigure } from './Actions/CreateRecordActionConfigure'
import { CreateSingleRecordActionConfigure } from './Actions/CreateSingleRecordActionConfigure'
import { DeleteRecordActionConfigure } from './Actions/DeleteRecordActionConfigure'
import { DingTalkRobotActionConfigure } from './Actions/DingTalkRobotActionConfigure'
import { DownloadFileActionConfigure } from './Actions/DownloadFileActionConfigure'
import { FindRecordActionConfigure } from './Actions/FindRecordActionConfigure'
import { FindSingleRecordActionConfigure } from './Actions/FindSingleRecordActionConfigure'
import { GoBackActionConfigure } from './Actions/GoBackActionConfigure'
import { IclickContactActionConfigure } from './Actions/IclickContactActionConfigure'
import { LoopNodeActionConfigure } from './Actions/LoopNodeActionConfigure'
import { NotificationActionConfigure } from './Actions/NotificationActionConfigure'
import { OpenFormPageActionConfigure } from './Actions/OpenFormPageActionConfigure'
import { OpenLinkActionConfigure } from './Actions/OpenLinkActionConfigure'
import { OpenPageActionConfigure } from './Actions/OpenPageActionConfigure'
import { OpenRecordEditPageActionConfigure } from './Actions/OpenRecordEditPageActionConfigure'
import { OpenRecordPageActionConfigure } from './Actions/OpenRecordPageActionConfigure'
import { RefreshPageActionConfigureConfigure } from './Actions/RefreshPageActionConfigure'
import { SendEmailActionConfigure } from './Actions/SendEmailActionConfigure'
import { ShareToWechatActionConfigure } from './Actions/ShareToWechatActionConfigure'
import { StationMessageActionConfigure } from './Actions/StationMessageActionConfigure'
import { StripeActionConfigure } from './Actions/StripeActionConfigure'
import { UpdateRecordActionConfigure } from './Actions/UpdateRecordActionConfigure'
import { WeChatPayActionConfigure } from './Actions/WeChatPayActionConfigure'
import { WxRobotActionConfigure } from './Actions/WxRobotActionConfigure'
import { WxTemplateActionConfigure } from './Actions/WxTemplateActionConfigure'
import { ConditionConfigure } from './ConditionConfigure'
import { InitiateApproveActionConfigure } from './InitiateApproveActionConfigure'
import { ArgTriggerConfigure } from './Triggers/ArgTriggerConfigure'
import { ClickTriggerConfigure } from './Triggers/ClickTriggerConfigure'
import { CreateRecordTriggerConfigure } from './Triggers/CreateRecordTriggerConfigure'
import { InitiateApproveTriggerConfigure } from './Triggers/InitiateApproveTriggerConfigure'
import { MatchConditionTriggerConfigure } from './Triggers/MatchConditionTriggerConfigure'
import { ScheduledTriggerConfigure } from './Triggers/ScheduledTriggerConfigure'
import { UpdateRecordTriggerConfigure } from './Triggers/UpdateRecordTriggerConfigure'

const SCxContainer = styled.div`
    padding-bottom: 100px;
`
interface NodeSettingProps {
    allParentNodes: FlowNode[]
    nodeType?: NodeTypes
}

export const NodeSetting: React.FC<NodeSettingProps> = ({ allParentNodes, nodeType }) => {
    const nodeSettingContent = useMemo(() => {
        if (!nodeType) {
            return null
        }
        switch (nodeType) {
            /** 自动化触发 */
            case 'CREATE_RECORD_TRIGGER': {
                return <CreateRecordTriggerConfigure />
            }
            case 'UPDATE_RECORD_TRIGGER': {
                return <UpdateRecordTriggerConfigure />
            }
            case 'MATCH_CONDITION_TRIGGER': {
                return <MatchConditionTriggerConfigure allParentNodes={allParentNodes} />
            }
            case 'SCHEDULED_TRIGGER': {
                return <ScheduledTriggerConfigure />
            }
            /** 审批流触发 */
            case 'INITIATE_APPROVE_TRIGGER': {
                return <InitiateApproveTriggerConfigure />
            }
            /** 动作流触发 */
            case 'CLICK_TRIGGER': {
                return <ClickTriggerConfigure />
            }
            /** 子流程触发 */
            case 'ARG_TRIGGER': {
                return <ArgTriggerConfigure />
            }
            /** 行为节点 */
            case 'CREATE_SINGLE_RECORD_ACTION': {
                return <CreateSingleRecordActionConfigure allParentNodes={allParentNodes} />
            }
            case 'CREATE_RECORD_ACTION': {
                return <CreateRecordActionConfigure allParentNodes={allParentNodes} />
            }
            case 'UPDATE_RECORD_ACTION': {
                return <UpdateRecordActionConfigure allParentNodes={allParentNodes} />
            }
            case 'FIND_SINGLE_RECORD_ACTION': {
                return <FindSingleRecordActionConfigure allParentNodes={allParentNodes} />
            }
            case 'FIND_RECORD_ACTION': {
                return <FindRecordActionConfigure allParentNodes={allParentNodes} />
            }
            case 'APPROVE_ACTION': {
                return <ApproveActionConfigure allParentNodes={allParentNodes} />
            }
            case 'INITIATE_APPROVE_ACTION': {
                return <InitiateApproveActionConfigure allParentNodes={allParentNodes} />
            }
            case 'WX_ROBOT_ACTION': {
                return <WxRobotActionConfigure allParentNodes={allParentNodes} />
            }
            case 'DINGTALK_ROBOT_ACTION': {
                return <DingTalkRobotActionConfigure allParentNodes={allParentNodes} />
            }
            case 'CONDITION': {
                return <ConditionConfigure allParentNodes={allParentNodes} />
            }
            case 'CARBON_COPY_ACTION': {
                return <CarbonCopyActionConfigure />
            }
            case 'LOOP_ACTION': {
                return <LoopNodeActionConfigure allParentNodes={allParentNodes} />
            }
            case 'MANUALLY_TRIGGER': {
                break
            }
            /** 动作流独有动作 */
            case 'OPEN_PAGE': {
                return <OpenPageActionConfigure />
            }
            case 'OPEN_FORM_PAGE': {
                return <OpenFormPageActionConfigure />
            }
            case 'OPEN_RECORD_PAGE': {
                return <OpenRecordPageActionConfigure allParentNodes={allParentNodes} />
            }
            case 'OPEN_RECORD_EDIT_PAGE': {
                return <OpenRecordEditPageActionConfigure allParentNodes={allParentNodes} />
            }
            case 'OPEN_LINK': {
                return <OpenLinkActionConfigure allParentNodes={allParentNodes} />
            }
            case 'GO_BACK': {
                return <GoBackActionConfigure />
            }
            case 'NOTIFICATION': {
                return <NotificationActionConfigure allParentNodes={allParentNodes} />
            }
            case 'COPY': {
                return <CopyActionConfigure allParentNodes={allParentNodes} />
            }
            case 'CALL': {
                return <CallActionConfigure allParentNodes={allParentNodes} />
            }
            case 'DELETE_RECORD': {
                return <DeleteRecordActionConfigure />
            }
            case 'SEND_EMAIL_ACTION': {
                return <SendEmailActionConfigure allParentNodes={allParentNodes} />
            }
            case 'STATION_MESSAGE_ACTION': {
                return <StationMessageActionConfigure allParentNodes={allParentNodes} />
            }
            case 'SHARE_TO_WECHAT': {
                return <ShareToWechatActionConfigure allParentNodes={allParentNodes} />
            }
            case 'DOWNLOAD_FILE': {
                return <DownloadFileActionConfigure allParentNodes={allParentNodes} />
            }
            case 'I_CLICK_CONTACT_ACTION': {
                return <IclickContactActionConfigure allParentNodes={allParentNodes} />
            }
            case 'ALIYUN_MESSAGE_ACTION': {
                return <AliyunMessageActionConfigure allParentNodes={allParentNodes} />
            }
            case 'WECHAT_PAY': {
                return <WeChatPayActionConfigure allParentNodes={allParentNodes} />
            }
            case 'WECHAT_TEMPLATE_MSG_ACTION': {
                return <WxTemplateActionConfigure allParentNodes={allParentNodes} />
            }
            case 'STRIPE': {
                return <StripeActionConfigure allParentNodes={allParentNodes} />
            }
            case 'REFRESH_PAGE': {
                return <RefreshPageActionConfigureConfigure />
            }
            default: {
                return null
            }
        }
    }, [allParentNodes, nodeType])

    return <SCxContainer>{nodeSettingContent}</SCxContainer>
}
