import { Box, Breadcrumbs, Flex, Group, IconFont } from '@byecode/ui'
import type { ButtonAction, ButtonEvents, PageAbstract, RecordLikeProtocol } from '@lighthouse/core'
import type { FlowNode } from '@lighthouse/shared'
import {
    actionTypeInfos,
    ANONYMOUS,
    buttonActions,
    commonPageList,
    commonPages as commonPathPages,
    commonPagesOptions,
    dummyTriggerNodeId,
    getActionDummyTriggerNodeData,
    isEmptyRichTextValue,
    ListItem4ByecodeUi,
    SYSTEM_ROLE,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import type { AnyObject } from 'immer/dist/internal'
import { useAtomCallback } from 'jotai/utils'
import { map } from 'rambda'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'

import { appRoleAtom, currentAppIdAtom, currentEnvIdAtom } from '@/atoms/application/state'
import { recordAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageAtomFamily, pageListAtom } from '@/atoms/page/state'
import { updateWorkflowListAtom } from '@/atoms/workflow/action'
import { actionFlowListAtom, automationFlowListAtom } from '@/atoms/workflow/state'
import { AliyunMessageActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/AliyunMessageActionConfigure'
import { CallActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/CallActionConfigure'
import { ControlActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/ControlActionConfigure'
import { CopyActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/CopyActionConfigure'
import { CreateSingleRecordActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/CreateSingleRecordActionConfigure'
import { DownloadFileActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/DownloadFileActionConfigure'
import { IclickContactActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/IclickContactActionConfigure'
import { NotificationActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/NotificationActionConfigure'
import { OpenFormPageActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/OpenFormPageActionConfigure'
import { OpenLinkActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/OpenLinkActionConfigure'
import { OpenRecordEditPageActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/OpenRecordEditPageActionConfigure'
import { OpenRecordPageActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/OpenRecordPageActionConfigure'
import { ScrollToActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/ScrollToActionConfigure'
import { ShareToWechatActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/ShareToWechatActionConfigure'
import { StripeActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/StripeActionConfigure'
import { UpdateRecordActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/UpdateRecordActionConfigure'
import { WeChatPayActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/WeChatPayActionConfigure'
import { WxTemplateActionConfigure } from '@/containers/FlowDetail/components/NodeSetting/Actions/WxTemplateActionConfigure'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentEnvId } from '@/hooks/useApplication'

import { ConfigItem } from '../BlockSettings/ButtonGroup/ConfigItem'
import { InnerDrawer } from '../BlockSettings/InnerDrawer'
import { ActionTypeSelector } from './ActionTypeSelector'
import { StyledActionLabel, StyledIconWrapper, StyledItemTitle } from './styles'

interface ActionAdderProps {
    index?: string
    allPages?: PageAbstract[]
    events?: ButtonEvents
    action: ButtonAction
    title: string
    prefixName?: string
    listKeyName?: string
    showLabel?: boolean
    enableViewVariable?: boolean
}

const checkParams = (action: ButtonAction, extraParams?: AnyObject) => {
    const { type, data } = action
    if (type === 'flow' || type === 'automation') {
        return false
    }

    if (type === 'copy') {
        return !isEmptyRichTextValue(data.copy.copyText)
    }

    if (type === 'openLink') {
        return !isEmptyRichTextValue(data.openLink.link)
    }

    if (type === 'openPage') {
        return !!data.openPage.openPageUrl
    }

    if (type === 'wechatPay') {
        const { refundConfig, payConfig, operationType } = data.wechatPay
        if (!operationType) {
            return false
        }
        const isEmptyRefund = Object.entries(refundConfig).some((key, v) => {
            return typeof v === 'string' ? v === '' : false
        })
        const isEmptyPay = Object.entries(payConfig).some((key, v) => {
            return typeof v === 'string' ? v === '' : false
        })
        if (operationType === 'PAY') {
            return !isEmptyPay
        }
        return !isEmptyRefund
    }

    const buttonAction = buttonActions[type]

    // @ts-expect-error fix data type error
    return buttonAction?.every(({ name }) => data[type][name])
}

export const ActionAdder: FC<ActionAdderProps> = ({
    index,
    allPages,
    action,
    title,
    prefixName,
    listKeyName = 'list',
    showLabel = true,
    enableViewVariable
}) => {
    const { type, data } = action
    const { dsId } = useActionAdderDepParams()

    const comingFlowId = type === 'flow' ? data?.flow?.flowId : type === 'automation' ? data?.automation?.flowId : ''
    const envId = useCurrentEnvId()
    const lastPageMeta = useAtomData(lastPageOfStackAtom)
    const actionWorkflows = useAtomData(actionFlowListAtom)
    const automationWorkflows = useAtomData(automationFlowListAtom)
    const workflows = useMemo(() => [...actionWorkflows, ...automationWorkflows], [actionWorkflows, automationWorkflows])
    const { run: updateWorkflowList } = useAtomAction(updateWorkflowListAtom)
    const pageList = useAtomData(pageListAtom)

    const record = useAtomData(
        recordAtomFamily({
            appId: lastPageMeta?.appId ?? '',
            envId,
            dsId: lastPageMeta?.dsId ?? '',
            recordId: lastPageMeta?.recordId ?? ''
        })
    )

    const currentWorkFlow = useMemo(
        () => workflows.find(w => (type === 'flow' || type === 'automation') && comingFlowId === w.id),
        [comingFlowId, type, workflows]
    )

    const getClickTriggerNodeParams = useAtomCallback(
        useCallback(
            (get, set, params?: { record?: RecordLikeProtocol; appId: string; dsId: string }) => {
                const currentAppId = get(currentAppIdAtom)
                const currentEnvId = get(currentEnvIdAtom)
                const record = params?.record
                // 实现当前页面所在记录，也就是触发节点逻辑的记录
                const currentPageDep = get(lastPageOfStackAtom)

                const appId = currentPageDep?.appId ?? params?.appId ?? ''
                const dsId = currentPageDep?.dsId ?? params?.dsId ?? ''
                const recordId = currentPageDep?.recordId ?? ''

                const currentRecord = record ?? get(recordAtomFamily({ appId, envId, dsId, recordId }))

                // 当前用户
                const appRole = get(appRoleAtom) || ANONYMOUS
                const currentUserId = SYSTEM_ROLE.has(appRole) ? ANONYMOUS : appRole

                return {
                    clickTriggerNodeParams: {
                        record: currentRecord,
                        currentAppId,
                        currentEnvId,
                        currentUserId
                    }
                }
            },
            [envId]
        )
    )

    const getVariableExtraParams = useCallback(() => {
        return {
            clickTriggerNodeParams: getClickTriggerNodeParams(record),
            [dummyTriggerNodeId]: {
                record
            }
        }
    }, [getClickTriggerNodeParams, record])

    const isParamsConfigured = currentWorkFlow ? currentWorkFlow.enabled : checkParams(action, getVariableExtraParams())

    const dynamicActionTypeInfos = useMemo(() => {
        for (const actionTypeInfo of actionTypeInfos) {
            if (actionTypeInfo.flowType === 'automation') {
                actionTypeInfo.children = automationWorkflows
                    .filter(w => w.content?.nodes?.[0]?.data?.nodeType === 'CLICK_TRIGGER')
                    .map(({ id, type, name }) => {
                        return {
                            type,
                            label: name,
                            value: id,
                            icon: 'FlowArrow'
                        }
                    })
            }
            if (actionTypeInfo.flowType === 'action') {
                actionTypeInfo.children = actionWorkflows
                    .filter(w => {
                        const nodeData = w.content?.nodes?.[0]?.data
                        const nodeDataConfig = nodeData?.config
                        return !!(nodeDataConfig && 'dataSourceId' in nodeDataConfig && nodeDataConfig.dataSourceId === dsId)
                    })
                    .map(({ id, type, name }) => ({
                        type,
                        label: name,
                        value: id,
                        icon: 'MouseClick'
                    }))
            }
        }

        return [...actionTypeInfos]
    }, [actionWorkflows, automationWorkflows, dsId])

    const currentAction = useMemo(() => {
        if (type === 'none') {
            return null
        }
        const flattenDynamicActionTypeInfos = dynamicActionTypeInfos.flatMap(item => {
            if (item.children) {
                return item.children
            }
            return item
        })
        if (type === 'flow' || type === 'automation') {
            return flattenDynamicActionTypeInfos.find(item => item.value === comingFlowId)
        }
        return flattenDynamicActionTypeInfos.find(item => item.value === type)
    }, [comingFlowId, dynamicActionTypeInfos, type])

    const [open, toggle] = useToggle(false)

    const currentActionLabel = useMemo(() => {
        if (currentWorkFlow) {
            return currentWorkFlow.enabled ? '已启用' : '未启用'
        }

        switch (type) {
            case 'openPage': {
                const path = data.openPage.openPageUrl
                return (
                    (commonPagesOptions.find(p => p.value === path)?.label ||
                        allPages?.find(p => {
                            return p.id === path
                        })?.name) ??
                    '请选择页面'
                )
            }
            case 'openFormPage': {
                const id = data.openFormPage.formPageId
                return (
                    pageList?.find(p => {
                        return p.id === id
                    })?.name ?? '请选择页面'
                )
            }
            case 'openRecordPage': {
                const id = data.openRecordPage.recordPageId
                return (
                    pageList?.find(p => {
                        return p.id === id
                    })?.name ?? '请选择页面'
                )
            }
            case 'openRecordEditPage': {
                const id = data.openRecordEditPage.recordEditPageId
                return (
                    pageList?.find(p => {
                        return p.id === id
                    })?.name ?? '请选择页面'
                )
            }
            case 'copy': {
                const isEmpty = isEmptyRichTextValue(data.copy.copyText)
                // const text = generateVariableValue({ jsonContent: data.copy.copyText, extraParams: getVariableExtraParams() })
                return isEmpty ? '请配置参数' : ''
            }
            case 'openLink': {
                const isEmpty = isEmptyRichTextValue(data.openLink.link)
                // const link = generateVariableValue({ jsonContent: data.openLink.link, extraParams: getVariableExtraParams() })
                return isEmpty ? '请配置参数' : ''
            }
            case 'notification': {
                const isEmpty = isEmptyRichTextValue(data.notification.notificationContent)
                // const text = generateVariableValue({
                //     jsonContent: data.notification.notificationContent,
                //     extraParams: getVariableExtraParams()
                // })
                return isEmpty ? '请配置参数' : ''
            }
            case 'wechatPay': {
                const { operationType } = data.wechatPay
                if (!operationType) {
                    return '请配置参数'
                }

                if (operationType === 'PAY') {
                    return checkParams(action) ? '发起支付' : '请配置参数'
                }
                return checkParams(action) ? '申请退款' : '请配置参数'
            }
            default: {
                break
            }
        }

        const buttonAction = type === 'flow' || type === 'automation' ? [] : buttonActions[type]

        return isParamsConfigured
            ? buttonAction
                  // @ts-expect-error fix data type error
                  .map(({ name }) => data[type][name])
                  .filter(Boolean)
                  .join(',')
            : '请配置参数'
    }, [currentWorkFlow, type, isParamsConfigured, data, allPages, pageList, action])

    return (
        <>
            {showLabel && <StyledItemTitle style={{ padding: 8 }}>动作</StyledItemTitle>}
            <ListItem4ByecodeUi
                styles={{
                    root: {
                        '&:hover': {
                            [`${StyledIconWrapper}`]: {
                                background: 'var(--color-white)'
                            }
                        }
                    }
                }}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <Flex gap="4px" alignItems="center">
                        {currentAction ? (
                            <>
                                <StyledIconWrapper style={{ width: 36, height: 36 }}>
                                    <IconFont type={currentAction.icon} size={18} fill="var(--color-gray-500)" />
                                </StyledIconWrapper>
                                <Box style={{ marginLeft: 4 }}>
                                    <Flex>{currentAction?.label}</Flex>
                                    <StyledActionLabel
                                        style={{
                                            color: isParamsConfigured ? 'var(--color-gray-500)' : 'var(--color-red-500)'
                                        }}
                                    >
                                        {currentActionLabel}
                                    </StyledActionLabel>
                                </Box>
                            </>
                        ) : (
                            <>
                                <StyledIconWrapper style={{ width: 36, height: 36 }}>
                                    <IconFont type="Add" size={18} fill="var(--color-gray-500)" />
                                </StyledIconWrapper>
                                <StyledActionLabel style={{ marginLeft: 4, fontSize: 'var(--font-size-normal)' }}>
                                    添加动作
                                </StyledActionLabel>
                            </>
                        )}
                    </Flex>
                </Flex>
                <Flex gap="4px" alignItems="center">
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>
            {open && (
                <ActionAdderConfigure
                    title={title}
                    actionTypeInfos={dynamicActionTypeInfos}
                    index={index}
                    prefixName={prefixName}
                    listKeyName={listKeyName}
                    action={action}
                    allPages={allPages}
                    enableViewVariable={enableViewVariable}
                    onGoBack={() => toggle(false)}
                    onUpdate={() => updateWorkflowList()}
                />
            )}
        </>
    )
}

interface RecordConfigureProps {
    index?: string
    action: ButtonAction
    allPages?: PageAbstract[]
    actionTypeInfos: typeof actionTypeInfos
    title?: string
    prefixName?: string
    listKeyName?: string
    enableViewVariable?: boolean
    onGoBack: () => void
    onUpdate: () => void
}

const ActionAdderConfigure = ({
    index,
    action,
    onGoBack,
    onUpdate,
    actionTypeInfos,
    allPages,
    title,
    prefixName,
    enableViewVariable,
    listKeyName = 'list'
}: RecordConfigureProps) => {
    const actionInfoPrefixName = index === undefined ? prefixName : `${prefixName}${listKeyName}.${index}.`
    const { control, watch } = useFormContext()
    const { type, data } = action
    const pageSelectList = useMemo(
        () => [...commonPageList, ...map(({ id, name }) => ({ label: name || '无标题', value: id, group: 'default' }), allPages ?? [])],
        [allPages]
    )

    const buttonAction = useMemo(() => {
        if (type === 'flow' || type === 'automation') {
            return []
        }
        return buttonActions[type] ?? []
    }, [type])

    const renderConfig = useCallback(
        () =>
            buttonAction.map(item => {
                let selectData = item.data ?? []
                if (item.name === 'openPageUrl') {
                    selectData = pageSelectList
                }
                if (item.name === 'notificationStatus') {
                    selectData = [
                        { label: '中立', value: 'INFO' },
                        { label: '成功', value: 'SUCCESS' },
                        { label: '失败', value: 'ERROR' }
                    ]
                }

                if (
                    type === 'openPage' &&
                    item.name === 'pageOpenType' &&
                    (!data.openPage.openPageUrl || commonPathPages.includes(data.openPage.openPageUrl))
                ) {
                    return null
                }
                return (
                    <Controller
                        key={item.name as string}
                        name={`${actionInfoPrefixName}action.data.${type}.${item.name as string}`}
                        control={control}
                        render={({ field }) => <ConfigItem {...item} value={field.value} data={selectData} onChange={field.onChange} />}
                    />
                )
            }),
        [actionInfoPrefixName, buttonAction, control, data, pageSelectList, type]
    )

    const lastPageMeta = useAtomData(lastPageOfStackAtom)
    const pageDsId = useAtomData(
        pageAtomFamily(lastPageMeta?.pageId ?? ''),
        useCallback(s => s?.dsId, [])
    )

    const [pointer, viewType] = watch(['pointer', 'viewType'])

    const depDsId = useMemo(() => {
        return pointer || pageDsId
    }, [pageDsId, pointer])

    // const allParentNodes = useMemo<FlowNode[]>(
    //     () => (depDsId ? [getActionDummyTriggerNodeData(pointer || lastPageMeta?.dsId)] : []),
    //     [depDsId, lastPageMeta?.dsId, pointer]
    // )
    const allParentNodes = useMemo<FlowNode[]>(
        () => [getActionDummyTriggerNodeData(pointer || lastPageMeta?.dsId)],
        [lastPageMeta?.dsId, pointer]
    )
    const actionTypeSwitcher = useMemo(
        () => (
            <Controller
                name={`${actionInfoPrefixName}action`}
                control={control}
                render={({ field }) => {
                    return (
                        <ActionTypeSelector
                            value={field.value}
                            actionTypeInfos={actionTypeInfos}
                            onUpdate={onUpdate}
                            onChange={field.onChange}
                            viewType={enableViewVariable ? viewType : undefined}
                        />
                    )
                }}
            />
        ),
        [actionInfoPrefixName, control, actionTypeInfos, onUpdate, enableViewVariable, viewType]
    )

    // const paramsPrefixName = `${actionInfoPrefixName}action.params`
    const paramsPrefixName = `${actionInfoPrefixName}action.data.${type}`

    const actionNodeSettingContent = useMemo(() => {
        if (!type) {
            return null
        }
        const commonProps = {
            allParentNodes,
            actionTypeSwitcher,
            isAction: true,
            prefixName: paramsPrefixName
        }
        switch (type) {
            /** 行为节点 */
            case 'createRecord': {
                return <CreateSingleRecordActionConfigure {...commonProps} />
            }
            case 'updateRecord': {
                return <UpdateRecordActionConfigure {...commonProps} />
            }
            case 'scrollTo': {
                return <ScrollToActionConfigure {...commonProps} />
            }
            case 'control': {
                return <ControlActionConfigure {...commonProps} />
            }
            /** 动作流独有动作 */
            case 'openFormPage': {
                return <OpenFormPageActionConfigure {...commonProps} />
            }
            case 'openRecordPage': {
                return <OpenRecordPageActionConfigure {...commonProps} />
            }
            case 'openRecordEditPage': {
                return <OpenRecordEditPageActionConfigure {...commonProps} />
            }
            case 'copy': {
                return <CopyActionConfigure {...commonProps} />
            }
            case 'call': {
                return <CallActionConfigure {...commonProps} />
            }
            case 'openLink': {
                return <OpenLinkActionConfigure {...commonProps} />
            }
            case 'notification': {
                return <NotificationActionConfigure {...commonProps} />
            }
            case 'shareToWechat': {
                return <ShareToWechatActionConfigure {...commonProps} />
            }
            case 'downloadFile': {
                return <DownloadFileActionConfigure {...commonProps} />
            }
            case 'iClick': {
                return <IclickContactActionConfigure {...commonProps} />
            }
            case 'aliyunMessage': {
                return <AliyunMessageActionConfigure {...commonProps} />
            }
            case 'wechatPay': {
                return <WeChatPayActionConfigure {...commonProps} />
            }
            case 'wechatTemplateMsg': {
                return <WxTemplateActionConfigure {...commonProps} />
            }
            case 'stripe': {
                return <StripeActionConfigure {...commonProps} />
            }
            // goBack
            // notification
            // none
            // openPage
            // flow
            // automation
            default: {
                return (
                    <Group label="动作配置" styles={{ root: { paddingTop: 2 } }}>
                        <ListItem4ByecodeUi alignItems="center" justifyContent="space-between">
                            <div style={{ marginRight: 12 }}>类型</div>
                            {actionTypeSwitcher}
                        </ListItem4ByecodeUi>
                        {renderConfig()}
                        {/* 默认加 dsId */}
                        <Controller
                            defaultValue={depDsId}
                            name={`${actionInfoPrefixName}action.data.${type}.dataSourceId`}
                            control={control}
                            render={({ field }) => <div />}
                        />
                    </Group>
                )
            }
        }
    }, [actionInfoPrefixName, actionTypeSwitcher, allParentNodes, control, depDsId, paramsPrefixName, renderConfig, type])

    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: `${title}的动作`, onClick: onGoBack }]} />

            <Divider color="var(--color-gray-200)" />

            <div key={paramsPrefixName}>{actionNodeSettingContent}</div>
        </InnerDrawer>
    )
}
