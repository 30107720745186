import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import { ApplicationPreviewEnum, useAtomAction } from '@lighthouse/shared'
import * as React from 'react'
import styled from 'styled-components'

import { setPreviewTypeAtom } from '@/atoms/application/action'
import { usePreviewType } from '@/hooks/useApplication'

import * as SC from './styles'

interface PreviewIconProps {
    hoverColor?: string
}

const SCxPreviewContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 30%;
`

const SCxWrapper = styled.div`
    display: flex;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--color-gray-100);
    padding: 2px;
    gap: 2px;
`

const SCxIconWrapper = styled.div<{ hoverColor?: string }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    cursor: pointer;
    border-radius: 5px;

    &.active {
        background-color: var(--color-white);
    }
    &:hover {
        background-color: ${({ hoverColor }) => hoverColor};
    }
`

const SCxActiveIcon = styled(IconFont)`
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 6.5px;
    font-size: 6px;
    background-color: #fff;
`
export const PreviewIcon: React.FunctionComponent<PreviewIconProps> = ({ hoverColor }) => {
    const previewType = usePreviewType()
    const { run: setAppServerlessState } = useAtomAction(setPreviewTypeAtom)

    const background = previewType === ApplicationPreviewEnum.desktop ? 'var(--color-gray-100)' : 'var(--color-gray-200)'
    return (
        <SCxPreviewContainer>
            <SCxWrapper style={{ background }}>
                <SCxIconWrapper
                    className={previewType === ApplicationPreviewEnum.desktop ? 'active' : undefined}
                    onClick={() => setAppServerlessState(ApplicationPreviewEnum.desktop)}
                    hoverColor={hoverColor}
                >
                    <SC.Icon
                        fill={previewType === ApplicationPreviewEnum.desktop ? 'var(--color-black)' : 'var(--color-gray-500)'}
                        type="Desktop"
                        size={18}
                    />
                    {/* <SCxActiveIcon type="DeviceMobileBase" /> */}
                </SCxIconWrapper>
                <SCxIconWrapper
                    className={previewType === ApplicationPreviewEnum.mobile ? 'active' : undefined}
                    onClick={() => setAppServerlessState(ApplicationPreviewEnum.mobile)}
                    hoverColor={hoverColor}
                >
                    <SC.Icon
                        fill={previewType === ApplicationPreviewEnum.mobile ? 'var(--color-black)' : 'var(--color-gray-500)'}
                        type="DeviceMobile"
                        size={18}
                    />
                    {/* <SCxActiveIcon type="Close" /> */}
                </SCxIconWrapper>
            </SCxWrapper>
        </SCxPreviewContainer>
    )
}
